import Wrapper from "../helpers/Wrapper";
import BarChart from "../ui/charts/BarChart";
import DonutPieChart from "../ui/charts/DonutPieChart";
import LineChart from "../ui/charts/LineChart";
import CategoryCombineBarLineChart from "../ui/charts/new/categoryCombineBarLineChart";
import ErrorBoundary from "../../utils/ErrorBoundary";
import { formatChartData } from "../../utils/Utils";
import ScatterChart from "../ui/charts/ScatterChart";
import NoDataAvailableLoader from "../ui/loaders/NoDataAvailable";

const InsightsChart = ({ chartData }) => {

    let chartPlotting = <Wrapper>Loading...</Wrapper>

    let formattedChartData = chartData.data === undefined || chartData.data === null || Object.keys(chartData.data).length === 0  ? { data: [], 'chart_type': chartData.chart_type } : formatChartData(chartData, chartData.chart_type);

    let chartType = chartData.chart_type === undefined ? "line-chart" : chartData.chart_type;

    console.log('formattedChartData', formattedChartData)

    let noOfData = Object.values(formattedChartData.data).length

    let setColumnWidth = 0;

    if (noOfData < 5) {
        setColumnWidth = 50;
    } else if (noOfData >= 5 && noOfData < 8) {
        setColumnWidth = 30;
    } else {
        setColumnWidth = 10;
    }

    if (chartData.data === undefined || chartData.data === null || Object.keys(chartData.data).length === 0) {
        chartPlotting = <NoDataAvailableLoader chartType={'lineChartType'} />
    } else {
        if (chartType === "line-chart") {
            chartPlotting =
                <Wrapper>
                    <ErrorBoundary>
                        <div className="single-chart-wrapper">
                            <LineChart
                                chartId={`insights_${chartType}_${Math.random()}`}
                                chartClass="chart"
                                chartData={formattedChartData.data}
                                chartLabels={formattedChartData.labels}
                                showLegend={true}
                                showLabels={true}
                                showBullets={true}
                                minimumGridDistance={20}
                                axisColor={0X000000}
                            />
                        </div>
                    </ErrorBoundary>
                </Wrapper>
        }
        else if (chartType === "bar-chart" || chartType === "group-bar-chart") {

            chartPlotting =
                <Wrapper>
                    <ErrorBoundary>
                        <div className="single-chart-wrapper">
                            <BarChart
                                chartId={`insights_${chartType}_${Math.random()}`}
                                chartClass="chart"
                                chartData={formattedChartData.data}
                                chartLabels={formattedChartData.labels}
                                showLegend={true}
                                showLabels={true}
                                columnWidth={setColumnWidth}
                                minimumGridDistance={20}
                                axisColor={0X000000}
                            />
                        </div>
                    </ErrorBoundary>
                </Wrapper>
        }
        else if (chartType === "bar-line-chart") {
            chartPlotting =
                <Wrapper>
                    <ErrorBoundary>
                        <div className="single-chart-wrapper">
                            <CategoryCombineBarLineChart
                                chartId={`insights_${chartType}_${Math.random()}`}
                                chartClass="chart"
                                chartData={formattedChartData.data}
                                chartLabels={formattedChartData.labels}
                                columnWidth={setColumnWidth}
                                axisColor={0X000000}
                            />
                        </div>
                    </ErrorBoundary>
                </Wrapper>
        }
        else if (chartType === "pie-chart") {
            chartPlotting =
                <Wrapper>
                    <ErrorBoundary>
                        <div className="single-chart-wrapper">
                            <DonutPieChart
                                chartId={`insights_${chartType}_${Math.random()}`}
                                chartClass="chart"
                                chartData={formattedChartData.data}
                            />
                        </div>
                    </ErrorBoundary>
                </Wrapper>
        }
        else if (chartType === "scatter-chart") {
            chartPlotting =
                <Wrapper>
                    <ErrorBoundary>
                        <div className="single-chart-wrapper">
                            <ScatterChart
                                chartId={`insights_${chartType}_${Math.random()}`}
                                chartClass="chart"
                                chartData={formattedChartData.data}
                                chartLabels={formattedChartData.labels}
                                chartLinePlotValues={formattedChartData.linePlot}
                                axisColor={0X000000}
                            />
                        </div>
                    </ErrorBoundary>
                </Wrapper>
        }
        else {
            chartPlotting =
                <Wrapper>
                    <ErrorBoundary>
                        <div>Unsupported chart type: {chartType}</div>
                    </ErrorBoundary>
                </Wrapper>
        }
    }


    return (
        <Wrapper>
            <div className={`grid grid_cols_1`}>
                {chartPlotting}
            </div>
        </Wrapper>
    );
};

export default InsightsChart;
