import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { BlueColorStart500 } from "../../../constants/constants";
import { CountryCode } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";
import { convertAmchartsColorToHexColor } from "../../../utils/Utils";
am5.addLicense("AM5C258896422");

const CategoryBarChart = ({
  chartId,
  chartClass,
  showLegend,
  chartData,
  chartLabels,
  percentageTooltip,
  axisColor
}) => {

  let axisTextColor = axisColor || 0x757575; // Use axisColor if available, else fallback to 0x757575
  let axisTextColorHex = convertAmchartsColorToHexColor(axisTextColor); // Convert to hex string once

  useLayoutEffect(() => {

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    var colorSet = BlueColorStart500

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    var data = chartData;

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        paddingRight: 0,
        wheelX: "none",
        wheelY: "none"
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    var yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 20,
      stroke: am5.color(axisTextColor),
      strokeWidth: 0.3,
      strokeOpacity: 1,
      location: 0.5,
      multiLocation: 0,
      visible: true
    });

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "label",
        renderer: yRenderer,
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
      maxWidth: 100,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: axisTextColorHex,
      fontWeight: "400",
    });

    var xRenderer = am5xy.AxisRendererX.new(root, {
      stroke: am5.color(axisTextColor),
      strokeWidth: 0.3,
      strokeOpacity: 1,
    });

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        treatZeroAs: 0.000001,
        extraMax: 0.1,
        maxPrecision: 0,
        min: findBiggestNegative(data) + (findBiggestNegative(data) * 0.1),
        extraMax: 0.04,
        strictMinMaxSelection: true,
        renderer: xRenderer
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: axisTextColorHex,
      fontWeight: "400",
    });

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        position: "relative", // options: "absolute" / "relative"
        x: am5.percent(50),
        centerX: am5.percent(50),
        height: chartLabels.length >= 6 ? am5.percent(30) : null,
        marginTop: 15,
        useDefaultMarker: true,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          maxColumns: 10,
          fixedWidthGrid: true,
        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.markers.template.setAll({
      width: 13,
      height: 13,
    });

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      // maxWidth: 150,
      //centerY: 0, // if we want labels to be top-aligned
      oversizedBehavior: "wrap",
    });

    const createSeries = (name, field, childLabel, childValue, childLabel2, childValue2, index) => {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          categoryYField: "label",
          fill: am5.color(colorSet[index]),
          stroke: am5.color(colorSet[index]),
          stroke2: am5.color(colorSet[5]),
        })
      );

      series.columns.template.setAll({
        strokeOpacity: 0,
        cornerRadiusBR: 10,
        cornerRadiusTR: 10,
        fillOpacity: 0.8,
        maxHeight: 10,
      });

      // Set up tooltip appearance
      series.columns.template.set("tooltipText", "{name}: {valueX}");

      // Optionally, set the background color and formatting of the tooltip
      series.columns.template.set("tooltip", am5.Tooltip.new(root, {
        pointerOrientation: "left",
        labelText: "[bold]{categoryY}[/][bold]: {valueX}[/]",
        getFillFromSprite: false,
        autoTextColor: false,
        getStrokeFromSprite: true
      }));

      // Set custom background style for the tooltip
      series.columns.template.get("tooltip").get("background").setAll({
        fill: am5.color(0x213345),
        strokeOpacity: 0,
        cornerRadius: 5,
        oversizedBehavior: "truncate",
      });


      // Set series data and appearance
      series.data.setAll(data);
      yAxis.data.setAll(data);

      series.appear(1000);

      // Add series to legend if showLegend is true
      if (showLegend === true) {
        legend.data.push(series);
      }
    };

    chartLabels.map((label, index) => {
      createSeries(
        label.label,
        label.value,
        label.childLabel,
        label.childValue,
        label.childLabel2,
        label.childValue2,
        index
      );
    });

    chart.zoomOutButton.set("forceHidden", true);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };

  }, [chartData, chartLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

CategoryBarChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  showVerticalLabels: PropTypes.bool,
  showHalfVerticalLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showShortVerticalLabels: PropTypes.bool,
  chartColorSet: PropTypes.array,
  minimumGridDistance: PropTypes.any,
  percentageTooltip: PropTypes.bool,
};

const findBiggestNegative = (array) => {
  let biggestNegative = 0;

  for (const obj of array) {
    for (const [key, value] of Object.entries(obj)) {
      if (Number.isInteger(value) && value < 0) {
        if (value < biggestNegative) {
          biggestNegative = value;
        }
      }
    }
  }

  return biggestNegative;
};

export default CategoryBarChart;
