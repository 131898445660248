import React, { useContext, useEffect, useState } from "react";

import { DateRangePicker, createStaticRanges } from "react-date-range"; // FOR DATEPICKER

import moment from "moment";

import { format, differenceInDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import Wrapper from "../helpers/Wrapper";

import CalendarIcon from "../../assets/icons/CalendarIcon";
import Button from "./Button";

import {
  setStartDate,
  setEndDate,
  setPrevStartDate,
  setPrevEndDate,
} from "../../store/dateSlice";
import { useDispatch, useSelector } from "react-redux";

export function formatDateDisplay(date, defaultText) {
  if (!date) return defaultText;
  return format(date, "yyyy-MM-dd");
}

function DatePicker(props) {
  const dispatch = useDispatch();

  // SELECTED BRANDS FROM DROPDOWN
  const selectedDates = useSelector((state) => state.date);

  // FOR SETTING THE END DATE OF CURRENT
  let current_end_date = new Date();
  current_end_date.setDate(current_end_date.getDate() - 6);

  // FOR SETTING THE START DATE OF PREVIOUS
  let previous_start_date = new Date();
  previous_start_date.setDate(previous_start_date.getDate() - 13);

  // FOR SETTING THE END DATE OF PREVIOUS
  let previous_end_date = new Date();
  previous_end_date.setDate(previous_end_date.getDate() - 7);

  const [state, setState] = useState({
    selection: {
      startDate: selectedDates.startDate === "" ? current_end_date : new Date(selectedDates.startDate),
      endDate: selectedDates.endDate === "" ? new Date() : new Date(selectedDates.endDate),
      key: "selection",
    },
    compare: {
      startDate: selectedDates.prevStartDate === "" ? previous_start_date : new Date(selectedDates.prevStartDate),
      endDate: selectedDates.prevEndDate === "" ? previous_end_date : new Date(selectedDates.prevEndDate),
      key: "compare",
    },
  });


  const applyClickHandler = (state) => {
    props.setOpen(!props.open);

    dispatch(setStartDate(formatDateDisplay(state.selection.startDate)));
    dispatch(setEndDate(formatDateDisplay(state.selection.endDate)));
    dispatch(setPrevStartDate(formatDateDisplay(state.compare.startDate)));
    dispatch(setPrevEndDate(formatDateDisplay(state.compare.endDate)));

    // dispatch(setStartDate(formatDateDisplay(new Date("2018-01-01"))));
    // dispatch(setEndDate(formatDateDisplay(new Date("2018-06-30"))));
    // dispatch(setPrevStartDate(formatDateDisplay(new Date("2017-07-01"))));
    // dispatch(setPrevEndDate(formatDateDisplay(new Date("2017-12-31"))));
  };

  const staticRanges = createStaticRanges([
    // ...defaultStaticRanges,
    {
      label: "Today",
      range: () => ({
        startDate: moment().startOf("day").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: moment().subtract(1, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: moment().startOf("week").toDate(),
        endDate: moment().endOf("week").toDate(),
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: moment().subtract(1, "weeks").startOf("week").toDate(),
        endDate: moment().subtract(1, "weeks").endOf("week").toDate(),
      }),
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: moment().subtract(7, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 28 Days",
      range: () => ({
        startDate: moment().subtract(28, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: moment().subtract(30, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 90 Days",
      range: () => ({
        startDate: moment().subtract(90, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 12 Months",
      range: () => ({
        startDate: moment().subtract(12, "months").startOf("month").toDate(),
        endDate: moment().subtract(1, "months").endOf("month").toDate(),
      }),
    },
    {
      label: "Last Calendar Year",
      range: () => ({
        startDate: moment().subtract(1, "years").startOf("year").toDate(),
        endDate: moment().subtract(1, "years").endOf("year").toDate(),
      }),
    },
    {
      label: "This Year (Jan - Today)",
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
  ]);

  const HandleChangeDateRange = (item) => {

    let itemChild;

    if (item.selection !== undefined) {
      itemChild = item.selection
    } else if (item.compare !== undefined) {
      itemChild = item.compare
    } else {
      itemChild = item.range1
    }

    if (itemChild.fromStaticRange !== undefined && itemChild.fromStaticRange === true) {
      if (itemChild.identifier === "Today") {
        let obj = {
          "selection": {
            startDate: moment().startOf("day").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(1, "days").startOf("day").toDate(),
            endDate: moment().subtract(1, "days").endOf("day").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "Yesterday") {
        let obj = {
          "selection": {
            startDate: moment().subtract(1, "days").startOf("day").toDate(),
            endDate: moment().subtract(1, "days").endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(2, "days").startOf("day").toDate(),
            endDate: moment().subtract(2, "days").endOf("day").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "last7Days") {
        let obj = {
          "selection": {
            startDate: moment().subtract(7, "days").startOf("day").toDate(),
            endDate: moment().subtract(1, "days").endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(14, "days").startOf("day").toDate(),
            endDate: moment().subtract(8, "days").endOf("day").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "lastWeek") {
        let obj = {
          "selection": {
            startDate: moment().subtract(1, "weeks").startOf("week").toDate(),
            endDate: moment().subtract(1, "weeks").endOf("week").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(2, "weeks").startOf("week").toDate(),
            endDate: moment().subtract(2, "weeks").endOf("week").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "thisWeek") {
        let obj = {
          "selection": {
            startDate: moment().startOf("week").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(1, "weeks").startOf("week").toDate(),
            endDate: moment().subtract(1, "weeks").endOf("week").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "thisMonth") {
        let obj = {
          "selection": {
            startDate: moment().startOf("month").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(1, "months").startOf("month").toDate(),
            endDate: moment().subtract(1, "months").endOf("month").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "last12Months") {
        let obj = {
          "selection": {
            startDate: moment().subtract(12, "months").startOf("month").toDate(),
            endDate: moment().subtract(1, "months").endOf("month").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(24, "months").startOf("month").toDate(),
            endDate: moment().subtract(13, "months").endOf("month").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "lastCalenderYear") {
        let obj = {
          selection: {
            startDate: moment().subtract(1, "years").startOf("year").toDate(),
            endDate: moment().subtract(1, "years").endOf("year").toDate(),
            key: "selection",
          },
          compare: {
            startDate: moment().subtract(2, "years").startOf("year").toDate(),
            endDate: moment().subtract(2, "years").endOf("year").toDate(),
            key: "compare",
          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "thisYear") {
        let obj = {
          selection: {
            startDate: moment().startOf("year").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection",
          },
          compare: {
            startDate: moment().subtract(1, "years").startOf("year").toDate(),
            endDate: moment().subtract(1, "year").toDate(),
            key: "compare",
          }
        }
        setState({ ...state, ...obj })
      }

      delete itemChild.fromStaticRange;
      delete itemChild.identifier;
    } else if (item.selection !== undefined) {
      const date1 = new Date(item.selection.startDate);
      const date2 = new Date(item.selection.endDate);
      const diffTime = Math.abs(date2 - date1);
      const totalDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      let previousEndDate = new Date(date1.setDate(date1.getDate() - 1))
      let previousStartDate = new Date(date1.setDate(date1.getDate() - (totalDays - 1)))
      let dateObj = {
        compare: {
          startDate: previousStartDate,
          endDate: previousEndDate,
          key: "compare",
        },
      }
      setState({ ...state, ...item, ...dateObj })
    } else {
      setState({ ...state, ...item })
    }
  }

  return (
    <Wrapper>
      <div
        onClick={() => props.setOpen(!props.open)}
        className="datepicker_wrapper"
      >
        <div className="datepicker_section">
          <div className="datepicker">
            <div className="date_title">
              {formatDateDisplay(state.selection.startDate)}
            </div>
            <div className="date_subtitle">to</div>
            <div className="date_title">
              {formatDateDisplay(state.selection.endDate)}
            </div>
          </div>
          <CalendarIcon className="icon" />
        </div>
      </div>
      {props.open && (
        <div className="datepicker_dropdown_wrapper">
          <DateRangePicker
            className="datepicker_calender"
            editableDateInputs={true}
            onChange={(item) => HandleChangeDateRange(item)}
            direction={"vertical"}
            scroll={{ enabled: true }}
            ranges={[state.selection, state.compare]}
            color="#11a1fd"
            rangeColors={["#11a1fd", "#FF9931"]}
            staticRanges={staticRanges}
            maxDate={new Date()}
            minDate={new Date("2018-1-1")}
          // disabledDates={[new Date()]}
          />
          <div className="datepicker_footer">
            <div className="btn_wrapper">
              <Button handleClick={() => props.setOpen(!props.open)} className="secondary_danger_btn">
                Cancel
              </Button>
            </div>
            <div className="btn_wrapper">
              <Button handleClick={() => applyClickHandler(state)} className="primary_btn">
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
export default DatePicker;
