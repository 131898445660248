import React, { useEffect } from 'react'
import ReactDOM from "react-dom";
import Wrapper from '../helpers/Wrapper'
import SectionDefinationsBadge from './SectionDefinationsBadge';

export default function Modal({
    open,
    title,
    parentId,
    dropdown,
    exportDropdown,
    chart,
    setOpen
}) {

    useEffect(() => {
        document.body.classList.toggle('modal_open', open);
    }, [open])


    if (!open) {
        return (<></>)
    }

    window.scroll(0, 0);


    return ReactDOM.createPortal(
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
            <div className="popup_wrapper">
                <div className="section_card_header">
                    <div className="section_info">
                        <h2 className="section_card_title">
                            {title && title}
                            <SectionDefinationsBadge
                                title={''}
                                module={''}
                                platform={''}
                                section={''}
                            />
                        </h2>
                    </div>
                    <div data-html2canvas-ignore={true} style={{ gap: "20px" }}>
                        <button
                            onClick={() => {
                                parentId && document.getElementById(parentId).scrollIntoView({ block: 'center' })
                                setOpen(false)

                            }}
                            className="close_popup icon_btn btn-small-secondary">
                            &times;
                        </button>
                        {dropdown && dropdown}
                        {exportDropdown && exportDropdown}
                    </div>
                </div>
                <div className="popup_box section_card_body" >
                    <div className="section_card_chart_wrapper unset_height">
                        {chart && chart}
                    </div>

                </div>
            </div>
        </div>,
        document.getElementById("modal")
    )
}

