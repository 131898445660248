// PACKAGES
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// STORE
import AuthContext from "./store/authContext";
import FullPageExportContext from "./store/fullPageExportContext";

// COMPONENTS
import useApi from "./hooks/useApi";
import RouteUrls from "./components/helpers/RouteUrls";
import Header from "./components/layout/Header";
import Wrapper from "./components/helpers/Wrapper";
import withClearCache from "./ClearCache";
import ErrorBoundary from "./utils/ErrorBoundary";
import AnimatedInput, { AnimatedInputSkeltonLoader } from "./components/input/animateInput";
import InsightCards, { InsightCardsErrorLoader, InsightCardsSekeltonLoader } from "./components/ui/cards/insightCards";

// STYLE
import "./App.scss?v=0.0.1";
import { useSelector } from "react-redux";
import NoDataAvailableChecker from "./utils/NoDataAvailableChecker";
import { removeTags, sortByAnalysisType } from "./utils/Utils";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  // startDate / endDate / prevStartDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const ARYABOT_BASE_URL = process.env.REACT_APP_META_LAMA_API_URL;

  // API CALLS
  const suggestionsAPI = useApi();
  const insightCardsAPI = useApi();

  useEffect(() => {

    if (authCtx.isLoggedIn && location.pathname !== "/ask-icogz") {
      let params = {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
      }

      suggestionsAPI.fetchData(ARYABOT_BASE_URL, "llama/question/", params);
      insightCardsAPI.fetchData(ARYABOT_BASE_URL, "llama/top-insight-v1/", params);
    }
  }, [authCtx.isLoggedIn, selectedDates])

  const [fullPageExport, setFullPageExport] = useState({});
  const [fullPageExportHeader, setFullPageExportHeader] = useState({})
  const [exportSummary, setexportSummary] = useState(null)
  const [exportPageName, setExportPageName] = useState(null)
  const [isExportDisabled, setIsExportDisabled] = useState(true)



  const [insightCardExportData, setInsightCardExportData] = useState([])


  useEffect(() => {

    if (!insightCardsAPI.apiStatus.isLoading && insightCardsAPI.apiStatus.isLoaded) {
      const formattedInsightCardData = [];

      if (!NoDataAvailableChecker(insightCardsAPI?.data ?? [])) {


        // insightCardsAPI?.data?.map((item, index) => {

        //   formattedInsightCardData[`TableDynaLabels_` + item.type + "-" + index] = []

        //   const removeString = removeTags(item.response.model_data.rich_text).replaceAll('\n', " ") ?? ''
        //   const chartData = item?.response?.model_data?.chart_data?.data ?? []
        //   const tableData = item?.response?.model_data?.table_data?.data?.results ?? [];

        //   formattedInsightCardData[`TableDynaLabels_` + item.type + "-" + index].push({
        //     Title: item.title,
        //     Text: removeString,
        //   })
        //   formattedInsightCardData[`TableDynaLabels_` + item.type + "-" + index].push(...chartData)
        //   formattedInsightCardData[`TableDynaLabels_` + item.type + "-" + index].push(...tableData)
        // })
      }

      setInsightCardExportData(formattedInsightCardData ?? [])
    }


  }, [insightCardsAPI.data, insightCardsAPI.apiStatus])


  const value = { insightCardExportData, exportPageName, fullPageExport, setFullPageExport, setFullPageExportHeader, exportSummary, setexportSummary, setExportPageName, isExportDisabled, setIsExportDisabled };

  // SUGGESTED QUESTIONS
  const [suggestedQuestionsList, setSuggestedQuestionsList] = useState([])

  useEffect(() => {
    if (suggestionsAPI.apiStatus.isLoaded && !suggestionsAPI.apiStatus.isLoading) {
      if (suggestionsAPI.data) {
        setSuggestedQuestionsList(suggestionsAPI?.data?.data)
      }
    }
  }, [suggestionsAPI.apiStatus])

  let askIcogzInput =
    <Wrapper>
      <AnimatedInputSkeltonLoader />
    </Wrapper>

  if (suggestionsAPI.apiStatus.isLoaded && !suggestionsAPI.apiStatus.isLoading) {
    askIcogzInput =
      <Wrapper>
        <AnimatedInput
          placeholders={suggestedQuestionsList}
          typingSpeed={10}
          delayBetweenPlaceholders={2000}
        />
      </Wrapper>
  } else {
    askIcogzInput = <Wrapper><AnimatedInputSkeltonLoader /></Wrapper>
  }

  // INSIGHT CARDS
  const [insightCardsList, setInsightCardsList] = useState([])

  useEffect(() => {
    if (insightCardsAPI.apiStatus.isLoaded && !insightCardsAPI.apiStatus.isLoading) {
      if (insightCardsAPI?.data) {

        const orderList = ['Inventory Forecasting', 'Overstock Analysis', 'Understock Analysis', 'Summary Digest', 'Pareto Analysis'];

        setInsightCardsList(sortByAnalysisType(insightCardsAPI.data, orderList))
      }
    }
  }, [insightCardsAPI.apiStatus, insightCardsAPI.data])

  let askIcogzInsightCards =
    <Wrapper>
      <InsightCardsSekeltonLoader />
    </Wrapper>

  if (insightCardsAPI.apiStatus.isLoaded && !insightCardsAPI.apiStatus.isLoading) {
    if (insightCardsAPI.apiStatus.error !== null) {
      askIcogzInsightCards = <Wrapper>
        <InsightCardsErrorLoader />
      </Wrapper>
    } else {
      askIcogzInsightCards =
        <Wrapper>
          <InsightCards data={insightCardsList} />
        </Wrapper>
    }
  } else {
    askIcogzInsightCards = <Wrapper><InsightCardsSekeltonLoader /></Wrapper>
  }

  return (
    <Wrapper>
      <div className="App">
        <div
          className="dashboard_container"
          id={`${splitLocation[splitLocation.length - 1] === '' ? 'Dashboard' : splitLocation[splitLocation.length - 1]}_section`}
        >
          {isLoggedIn && (
            <ErrorBoundary>
              <Header
                identity={`${splitLocation[splitLocation.length - 1] === '' ? 'Dashboard' : splitLocation[splitLocation.length - 1]}_section`}
                name={exportPageName !== null ? exportPageName : splitLocation[splitLocation.length - 1] === '' ? 'Dashboard' : splitLocation[splitLocation.length - 1]}
                data={fullPageExport}
                headers={fullPageExportHeader}
                disabled={isExportDisabled}
              />
            </ErrorBoundary>
          )}
          <main
            role="main"
            className={isLoggedIn ? "main_page" : "main_page unauth_page"}
          >
            {isLoggedIn && location.pathname !== "/ask-icogz" && location.pathname !== "/test" && (
              <Wrapper>
                {askIcogzInput}
                {askIcogzInsightCards}
              </Wrapper>
            )}

            <FullPageExportContext.Provider value={value}>
              <ErrorBoundary>
                <RouteUrls />
              </ErrorBoundary>
            </FullPageExportContext.Provider>
          </main>
        </div>
      </div>
    </Wrapper>
  );
}

export default App;
