export const DefaultCardDefinations = [
  {
    title: "Total Sales",
    module: "",
    platform: "",
    section: "",
    data: "Total Sales across the categories and products",
  },
  {
    title: "Total Stock",
    module: "",
    platform: "",
    section: "",
    data: "Total Stock in hand across the categories and product",
  },
  {
    title: "Total Orders",
    module: "",
    platform: "",
    section: "",
    data: "Total Orders in hand across the categories and product",
  },
  {
    title: "Avg Daily Demand",
    module: "",
    platform: "",
    section: "",
    data: " Average demand acorss categories and product on daily basis",
  },
  {
    title: "Avg Order Size",
    module: "",
    platform: "",
    section: "",
    data: " Average Order acorss categories and product on daily basis",
  },
  {
    title: "Avg Reorder Point",
    module: "",
    platform: "",
    section: "",
    data: "Average reorder size acorss categories and product",
  },
  {
    title: "No of Days Coverage",
    module: "",
    platform: "",
    section: "",
    data: "The average number of days needed to be held before the stock being sold out.",
  },
  {
    title: "CSAT",
    module: "",
    platform: "",
    section: "",
    data: " The overall customer satisfaction rating given by customers",
  },

  // COKE / CARD / SUMMARY / OVERALL / OVERALL
  {
    title: "Sales",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Sales performance by Sales Volume in unit cases & number of campaigns (Digital + TV)",
  },
  {
    title: "TV Campaigns",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "TV performance by Gross Rating Points and amount spent on TV",
  },
  {
    title: "Digital Campaigns",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Digital Campaign performance by number of campaigns and amount spent across FB, IG & DV 360",
  },
  {
    title: "Social",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Social performace by followers gained in the selected time period and organic impressions across FB & IG",
  },
  {
    title: "Website",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "By default, all properties of all brands' data are shown, when brand/s is selected, data of selected brand/s properties are shown",
  },
  {
    title: "Video",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Performance by total number of videos and total views across FB, IG & DV 360 (views count as defined by the respective platforms)",
  },
  {
    title: "Hashtag",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "When a post includes multiple #tags, the individual #tags' ENG and IMP are calculated based on the number of times each #tag appears, which affects the overall engagement rate",
  },
  {
    title: "Meta Engagements",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Paid and Organic engagements across Meta - FB & IG Platforms",
  },
  {
    title: "Meta Reach",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Paid and Organic reach across Meta - FB & IG platforms",
  },
  {
    title: "Impressions - India Level",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Tv impressions and Digital Impressions at India level",
  },

  // BASE / CARD / CAMPAIGN / OVERALL / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The number of times your ads were on screen on all platforms",
  },
  {
    title: "Meta Engagements",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Paid and Organic engagements across Meta - FB & IG Platforms",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The number of clicks on your ads on all platforms",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The total number of conversions across all channels and platform",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The total estimated amount of money you've spent on your campaigns on all platform",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Engagement includes summation of engagement across all the platforms",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Click through rate, The percentage of times people saw your ad and performed a click (all)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Cost per clicks, The average cost for each click (all)",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions",
  },
  {
    title: "Cost per video views",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Cost per video view",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Reach rate",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Reach rate equals to total reach of a post per impressions",
  },
  {
    title: "Frequency",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The average number of times each person saw your ad. This metric is estimated",
  },
  {
    title: "View rate",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Percentage of users who viewed your ad, formulates as video views per impressions",
  },

  // BASE / CARD / CAMPAIGN / OVERALL / VIDEO
  {
    title: "Video views",
    module: "campaign",
    platform: "overall",
    section: "video",
    data: "Count of the number of times a video ad is watched",
  },

  // BASE / CARD / CAMPAIGN / GOOGLE / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "Count of how often your ad has appeared on a search results page or website on the Google Network",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The number of clicks",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The number of conversions",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "total amount of money you've spent on your campaign, ad set or ad during its schedule",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The number of engagements. An engagement occurs when a viewer expands your Lightbox ad",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The number of clicks your ad receives divided by the number of times your ad is shown (Impressions)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The total cost of all clicks divided by the total number of clicks received",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "Cost-per-thousand impressions",
  },

  // BASE / CARD / CAMPAIGN / GOOGLE / VIDEO
  {
    title: "Video Views",
    module: "campaign",
    platform: "google",
    section: "video",
    data: "The number of times your video ads were viewed",
  },
  {
    title: "View Through Conversions",
    module: "campaign",
    platform: "google",
    section: "video",
    data: "Customer views an ad, then later completes a conversion on your site",
  },
  {
    title: "Video View Rate",
    module: "campaign",
    platform: "google",
    section: "video",
    data: "The number of views your video ad receives divided by its number of impressions",
  },

  // BASE / CARD / CAMPAIGN / FACEBOOK / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The number of times your ads were on screen",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The number of clicks on your ads",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The total number of conversions across all channels attributed to your ads",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Engagement includes all clicks, likes, comments and shares",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Click through rate, The percentage of times people saw your ad and performed a click (all)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Cost per clicks, The average cost for each click (all)",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions",
  },
  {
    title: "Cost per video view",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Reach rate",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Reach rate equals to total reach of a post per impressions",
  },
  {
    title: "Frequency",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The average number of times each person saw your ad This metric is estimated",
  },
  {
    title: "View rate",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Percentage of users who viewed your ad and watched your video for more than 3 seconds",
  },

  // BASE / CARD / CAMPAIGN / FACEBOOK / VIDEO
  {
    title: "Avg. Watched Time",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "The average time a video was played, including any time spent replaying the video",
  },
  {
    title: "Video Play Actions",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "The number of times your video starts to play. Counted for each impression, excludes replays",
  },

  // BASE / CARD / CAMPAIGN / INSTAGRAM / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The number of times your ads were on screen",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The number of clicks on your ads",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The total number of conversions across all channels attributed to your ads",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Engagement includes all clicks, likes, comments and shares",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Click through rate, The percentage of times people saw your ad and performed a click (all)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Cost per clicks, The average cost for each click (all)",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions",
  },
  {
    title: "Cost per video view",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Reach rate",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Reach rate equals to total reach of a post per impressions",
  },
  {
    title: "Frequency",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The average number of times each person saw your ad This metric is estimated",
  },
  {
    title: "View rate",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Percentage of users who viewed your ad and watched your video for more than 3 seconds",
  },

  // BASE / CARD / CAMPAIGN / INSTAGRAM / VIDEO
  {
    title: "Avg. watched time",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "The average time a video was played, including any time spent replaying the video",
  },
  {
    title: "Video play actions",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "The number of times your video starts to play. Counted for each impression, excludes replays",
  },

  // BASE / CARD / CAMPAIGN / TWITTER / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of impressions",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of clicks, including favorites and other engagements",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "The total number of all conversions attributed to your ads",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total spend of campaigns or ads",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of engagements",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Click through rate, The percentage of times people saw your ad and performed a click (all)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Cost per clicks, The average cost for each click (all)",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions",
  },
  {
    title: "Media views",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of views (autoplay and click) of media across Videos, Vines, GIFs, and Images",
  },
  {
    title: "Media engagements",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of clicks of media across Videos, Vines, GIFs, and Images",
  },

  // BASE / CARD / CAMPAIGN / TWITTER / VIDEO
  {
    title: "Video total views",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "Count of the number of times a video ad is watched",
  },
  {
    title: "Video rate",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "The number of views your video ad receives divided by its number of impressions",
  },
  {
    title: "cost per view",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },

  // BASE / CARD / CAMPAIGN / LINKEDIN / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Number of times your ad was displayed",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Total chargeable clicks based on your campaign's objective",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Total number of times member accounts took a desired action after clicking on or seeing your ad",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Amount you spent on ads impressions or interactions",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "The sum of all social actions, clicks to Landing Page, and clicks to LinkedIn Page",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Percentage of chargeable clicks relative to impressions",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Total spent on your ads divided by total clicks",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Total spent on your ads divided by 1,000 impressions",
  },

  // BASE / CARD / CAMPAIGN / LINKEDIN / VIDEO
  {
    title: "Video views",
    module: "campaign",
    platform: "linkedin",
    section: "video",
    data: "Two or more continuous seconds of playback while the video is at least 50% on screen",
  },

  // BASE / CAMPAIGN / DV360 / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of impressions that reached the intended or guaranteed audience.",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a person has clicked on an ad.",
  },
  {
    title: "Total Conversions",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The aggregate total of all Post-View Conversions and Post-Click Conversions.",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The total amount spent on the campaigns.",
  },

  {
    title: "CTR",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The percentage of time people clicked on an ad, calculated as Clicks / Impressions × 100.",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions.",
  },

  {
    title: "CPC",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "Cost per clicks, The average cost for clicks on an ad.",
  },

  {
    title: "Post Click Conversion Rate",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The percentage of post-click conversions out of total conversions from campaigns.",
  },
  {
    title: "Views",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times your ad has been viewed.",
  },
  {
    title: "VTR",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The percentage of time your ad is viewed, relative to the total number of times your ad is shown.",
  },
  {
    title: "Complete Views Video",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been played to its end.",
  },
  {
    title: "Complete View Rate",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of views your video ad receives divided by its number of impressions.",
  },
  {
    title: "Cost per Complete View",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The average cost of complete video view, based on the Total Media Cost spent, calculated as Total Media Cost / Complete Views (Video).",
  },
  {
    title: "Rich Media Engagements",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times that users engaged with an interactive element in a rich media creative.",
  },
  {
    title: "Scrolls",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times that a user has scrolled through a rich media creative.",
  },
  {
    title: "Full Screens",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been viewed in full screen mode.",
  },
  {
    title: "Audio Mutes",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been muted.",
  },
  {
    title: "Pauses",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been paused.",
  },
  {
    title: "Skips Video",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been skipped.",
  },

  // BASE / CAMPAIGN / DV360 / VIDEO

  {
    title: "No of campaigns",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "No. of video campaigns running in selected time period",
  },
  {
    title: "Impressions",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of impressions that reached the intended or guaranteed audience.",
  },
  {
    title: "Views",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of times your ad has been viewed.",
  },
  {
    title: "VTR",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The percentage of time your ad is viewed, relative to the total number of times your ad is shown (video and thumbnail impressions).",
  },
  {
    title: "Cost per View",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Complete Views (Video)",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of times a video has been played to its end.",
  },
  {
    title: "Complete View Rate",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of views your video ad receives divided by its number of impressions",
  },
  {
    title: "Cost per Complete View",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The average cost of each complete video view, based on the Total Media Cost spent, calculated as the product of Total Media Cost / Complete Views (Video).",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of times a person has clicked on an ad.",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The percentage of time people clicked on an ad, calculated as the product of Clicks / Impressions × 100.",
  },
  {
    title: "Completion Rate (Complete/Starts) * 100",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "Percentage of times the video played to the end, calculated as the product of Complete Views (Video)/ Starts× 100.",
  },
  {
    title: "Total Media Cost (Advertiser Currency)",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The total amount spent on the campaigns.",
  },

  // BASE / CARD / SOCIAL / FACEBOOK / PAGE
  {
    title: "Total Followers",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The total number of people who have followed your Page",
  },
  {
    title: "New Page Likes",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The total number of people who have liked your Page",
  },
  {
    title: "Views",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of times a Page has been viewed by logged-in and logged-out people",
  },
  {
    title: "Reach",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of people who had any content from your Page or about your Page enter their screen",
  },
  {
    title: "Clicks",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of clicks on your Page",
  },

  // BASE / CARD / SOCIAL / FACEBOOK / POST / OVERALL
  {
    title: "Total Posts",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of organic and promoted posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of times Page's overall post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of people who had Page's overall post enter their screen",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of likes received on overall posts",
  },
  {
    title: "Shares",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of times overall posts have been shared",
  },
  {
    title: "Total Clicks",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of total clicks have been made on overall posts",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Rate of interaction on the content. (Reactions + comments + shares) / Post impressions * 100",
  },

  // BASE / CARD / SOCIAL / FACEBOOK / POST / ORAGNIC
  {
    title: "Total Posts",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of organic posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of times Page's organic post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of people who had Page's organic post enter their screen",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of likes received on organic posts",
  },
  {
    title: "Shares",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of times organic posts have been shared",
  },
  {
    title: "Total Clicks",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of total clicks have been made on organic posts",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Rate of interaction on the content. (Reactions + comments + shares) / Post impressions * 100",
  },

  // BASE / CARD / SOCIAL / FACEBOOK / POST / PAID
  {
    title: "Total Posts",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of promoted posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of times Page's promoted post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of people who had Page's promoted post enter their screen",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of likes received on promoted posts",
  },
  {
    title: "Shares",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of times promoted posts have been shared",
  },
  {
    title: "Comments",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of commens made on Page's promoted posts",
  },
  {
    title: "Total Spends",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The amount of money spent on promoting the Page's posts",
  },
  {
    title: "Total Clicks",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of total clicks have been made on promoted posts",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Rate of interaction on the content. (Reactions + comments + shares) / Post impressions * 100",
  },
  {
    title: "CPC",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Cost per click (CPC) is a metric for how much each click costs",
  },
  {
    title: "CPM",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Cost per 1000 impressions, the amount spend for each 1000 impressions",
  },

  // BASE / CARD / SOCIAL / INSTAGRAM / PAGE
  {
    title: "Total Followers",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Total number of Instagram users following the user",
  },
  {
    title: "New Followers",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Number of new users who started following the user",
  },
  {
    title: "Profile Views",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Total number of users who have viewed the IG User's profile within the specified period",
  },
  {
    title: "Page Clicks",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Total number of taps on the text message link in the IG User's profile",
  },

  // BASE / CARD / SOCIAL / INSTAGRAM / POST / OVERALL
  {
    title: "Total Posts",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The number of organic and promoted posts",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The number of likes received on your posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The number of times your Page's post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The number of people who had your Page's post enter their screen",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Rate of interaction on the content. (likes + comments + shares) / Post impressions * 100",
  },
  {
    title: "Average Post Per Day",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The average number of posts published on daily basis",
  },

  // BASE / CARD / SOCIAL / INSTAGRAM / POST / ORAGNIC
  {
    title: "Total Posts",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The number of organic posts",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The number of likes received on your posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The number of times your Page's post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The number of people who had your Page's post enter their screen",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Rate of interaction on the content. (likes + comments + shares) / Post impressions * 100",
  },
  {
    title: "Average Posts Per Day",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The average number of posts published on daily basis",
  },

  // BASE / CARD / SOCIAL / INSTAGRAM / POST / PAID
  {
    title: "Total Posts",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The number of promoted posts",
  },
  {
    title: "Total Post Impressions",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The number of times Page's promoted post entered a person's screen",
  },
  {
    title: "Total Post Reach",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The number of people who had Page's promoted post enter their screen",
  },
  {
    title: "Spends",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The amount of money spent on promoting the Page's posts",
  },
  {
    title: "CPE",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "CPE (Cost per engagement), which compares the cost to the total amount of engagements",
  },

  // BASE / CARD / SOCIAL / TWITTER / PAGE
  {
    title: "Followers Gained",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Total number of followers gained in current period",
  },
  {
    title: "Total following",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Total number of followings",
  },
  {
    title: "Mentions",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Mention is a Tweet that contains person's username anywhere in the body of the Tweet",
  },
  {
    title: "Total Profile Clicks",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Count of the number of times a user clicks the on display name, user name, profile picture of tweet",
  },

  // BASE / CARD / SOCIAL / TWITTER / POST / OVERALL
  {
    title: "Total Tweets",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Number of total tweet, i.e organic and paid",
  },
  {
    title: "Total Engagements",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Total engagement includes likes, retweets, replies, clicks and follows",
  },
  {
    title: "Total Impressions",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Count of how many times the Tweet has been viewed (not unique by user)",
  },
  {
    title: "Total profile clicks",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Count of the number of times a user clicks the on display name, user name, profile picture of tweet",
  },
  {
    title: "Engagement rate",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "It gives by formula total engagement per total impressions on tweet",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Count of how many times the video included in the Tweet has been viewed",
  },
  {
    title: "Url link clicks",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Total number of times a user clicks on a URL link or URL preview card in a Tweet",
  },

  // BASE / CARD / SOCIAL / TWITTER / POST / ORAGNIC
  {
    title: "Total Tweets",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Number of orgnaic tweet",
  },
  {
    title: "Total Engagements",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Total engagement includes likes, retweets, replies, clicks and follows on orgnic tweets",
  },
  {
    title: "Total Impressions",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Count of how many times the organic tweet has been viewed (not unique by user)",
  },
  {
    title: "Total profile clicks",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Number of times a user clicks the on display name, user name, profile picture of organic tweets",
  },
  {
    title: "Engagement rate",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "It gives by formula total engagement per total impressions on organic tweet",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Count of how many times the video included in the organic tweet has been viewed",
  },
  {
    title: "Url link clicks",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Total number of times a user clicks on a URL link or URL preview card in a organic tweet",
  },

  // BASE / CARD / SOCIAL / TWITTER / POST / PAID
  {
    title: "Total Tweets",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Number of paid tweet",
  },
  {
    title: "Total Engagements",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Total engagement includes likes, retweets, replies, clicks and follows on paid tweets",
  },
  {
    title: "Total Impressions",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Count of how many times the paid tweet has been viewed (not unique by user)",
  },
  {
    title: "Total profile clicks",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Number of times a user clicks the on display name, user name, profile picture of paid tweets",
  },
  {
    title: "Engagement rate",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "It gives by formula total engagement per total impressions on paid tweet",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Count of how many times the video included in the paid tweet has been viewed",
  },
  {
    title: "Url link clicks",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Total number of times a user clicks on a URL link or URL preview card in a paid tweet",
  },
  {
    title: "Spends",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "The amount of money spent on promoting the paid tweet",
  },
  {
    title: "CPE",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Cost per engagement, the amount spend for total engagement on paid tweet",
  },
  {
    title: "CPM",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Cost per 1000 impressions, the amount spend for each 1000 impressions",
  },
  {
    title: "Cost per video views",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "The amount spend for video included in the paid tweet has been viewed",
  },

  // BASE / CARD / SOCIAL / LINKEDIN / PAGE
  {
    title: "Page Views",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of all page views",
  },
  {
    title: "Page Reach",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of unique impressions",
  },
  {
    title: "Page Mentions",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of mentions of the organizational entity in a shares & comments across LinkedIn",
  },
  {
    title: "Page Clicks",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of clicks",
  },

  // BASE / CARD / SOCIAL / LINKEDIN / AUDIENCE
  {
    title: "New Follower",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by the new followers in that given time period",
  },
  {
    title: "Total Follower",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by the total followers",
  },
  {
    title: "Organic Follower",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by the organic followers",
  },
  {
    title: "Paid Follower",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by the paid followers",
  },

  // BASE / CARD / SOCIAL / LINKEDIN / POST / OVERALL
  {
    title: "Overall Post",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "The number of organic + promoted posts",
  },
  {
    title: "Impressions",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of impressions",
  },
  {
    title: "Reach",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of unique impressions",
  },
  {
    title: "Clicks",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of clicks",
  },
  {
    title: "Engagements",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of organic clicks, likes, comments, and shares over impressions",
  },

  // BASE / CARD / SOCIAL / LINKEDIN / POST / ORAGNIC
  {
    title: "Organic Posts",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "The number of organic posts",
  },
  {
    title: "Impressions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of impressions",
  },
  {
    title: "Reach",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of unique impressions",
  },
  {
    title: "Clicks",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of clicks",
  },
  {
    title: "Likes",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of likes",
  },
  {
    title: "Total comments",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of comments",
  },
  {
    title: "Shares",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of shares",
  },
  {
    title: "Engagements",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of organic clicks, likes, comments, and shares over impressions",
  },
  {
    title: "Total Interactions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of number of organic clicks, likes, comments, and shares over impressions",
  },
  {
    title: "CTR",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Click through rate is the number of clicks divided  the number of impressions",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Rate of interaction on the content. (likes + comments + shares) / Total number of followers * 100",
  },

  // BASE / CARD / SOCIAL / LINKEDIN / POST / PAID
  {
    title: "Promoted posts",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "The number of promoted posts",
  },
  {
    title: "Impressions",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of impressions",
  },
  {
    title: "Reach",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of unique impressions",
  },
  {
    title: "Clicks",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of clicks",
  },
  {
    title: "Engagements",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of organic clicks, likes, comments, and shares over impressions",
  },
  {
    title: "Total Spends",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "The amount of spends",
  },
  {
    title: "CTR",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "CTR is the number of clicks that your ad receives divided by the number of times your ad is shown",
  },
  {
    title: "CPC",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Cost per click (CPC) is a metric for how much each click costs",
  },

  // BASE / CARD / WEBSITE / OVERALL
  {
    title: "Sessions",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // BASE / CARD / WEBSITE / TRAFFIC / ORGANIC
  {
    title: "Sessions",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // BASE / CARD / WEBSITE / TRAFFIC / CHANNEL WISE TRAFFIC
  {
    title: "Sessions",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // BASE / CARD / WEBSITE / BEHAVIOUR
  {
    title: "Sessions",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // BASE / CARD / WEBSITE / TECHNOLOGY / BROWSER USED
  {
    title: "Sessions",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // BASE / CARD / WEBSITE / TECHNOLOGY / DEVICE USED
  {
    title: "Sessions",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // BASE / CARD / WEBSITE / TECHNOLOGY / TRAFFIC TYPE
  {
    title: "Sessions",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // BASE / CARD / WEBSITE / LOCATION / COUNTRY
  {
    title: "Sessions",
    module: "website",
    platform: "location",
    section: "country",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "location",
    section: "country",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "location",
    section: "country",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "location",
    section: "country",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "location",
    section: "country",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "location",
    section: "country",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "location",
    section: "country",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "location",
    section: "country",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "location",
    section: "country",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "location",
    section: "country",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // BASE / CARD / WEBSITE / ECOM
  {
    title: "Transactions",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The total number of transactions",
  },
  {
    title: "Conversion Rate",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The average number of transactions in a session",
  },
  {
    title: "Revenue",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The total sale revenue (excluding shipping and tax) of the transaction",
  },
  {
    title: "Revenue / Transaction",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The average revenue of an ecommerce transaction",
  },
  {
    title: "Quantity",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "Total number of items purchased",
  },
  {
    title: "Unique Purchases",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The number of product sets purchased",
  },
  {
    title: "Items / Purchase",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The average quantity of this item (or group of items) sold per purchase",
  },

  // BASE / CARD / TV
  {
    title: "Sales Volume",
    module: "tv",
    platform: "tv_overall",
    section: "tv_overall",
    data: "Number of unit cases of the Product sold",
  },
  {
    title: "GRPs",
    module: "tv",
    platform: "tv_overall",
    section: "tv_overall",
    data: "Television Gross Rating Points. It measures the size of an audience reached by television campaigns",
  },
  {
    title: "Spends",
    module: "tv",
    platform: "tv_overall",
    section: "tv_overall",
    data: "Amount of money spent on TV advertising",
  },
  {
    title: "No of Campaigns",
    module: "tv",
    platform: "tv_overall",
    section: "tv_overall",
    data: "Number of Campaigns run",
  },

  // BASE / CARD / SALES
  {
    title: "Sales Volume",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Product sales volume unit cases",
  },
  {
    title: "Sales Value",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Product sales value in Rupees",
  },
  {
    title: "GRPs",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Television Gross Rating Points. It measures the size of an audience reached by television campaigns",
  },
  {
    title: "Impressions",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "The number of times an advertisement is displayed on TV & Digital platforms by an audience (Digital Impressions will only be available for own brands)",
  },
  {
    title: "Spends",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Amount spent on advertising for TV & Digital Platforms (Digital Spends will only be available for own brands)",
  },
  {
    title: "No of Campaigns",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Number of Campaigns run on TV & Digital Platforms (Digital Campaign numbers will only be available for own brands)",
  },

  // BASE / CARD / VIDEO / OVERALL / OVERALL
  {
    title: "No Of Videos",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "The total engagement (likes + comments ) of a video post",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Average Watch Time",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Average number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  // BASE / CARD / VIDEO / OVERALL / OVERALL SINGLE
  {
    title: "Total Views",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "The total engagement (likes + comments ) of a video post",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Average Watched Time",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Average number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  // BASE / CARD / VIDEO / FACEBOOK / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Total minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Average number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Avg Views Per Video Post",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "On average, the number of views received per video post",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  {
    title: "Total video complete views unique",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The number of people who viewed your videos for 95%, or more, of its length",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Complete Video Views",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The number of times your videos played for 95%, or more, or its length",
  },
  // BASE / CARD / VIDEO / FACEBOOK /  OVERALL SINGLE
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Total minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Average number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "On average, the number of views received per video post",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  {
    title: "Total video complete views unique",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "The number of people who viewed your videos for 95%, or more, of its length",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Complete Video Views",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "The number of times your videos played for 95%, or more, or its length",
  },
  // BASE / CARD / VIDEO / FACEBOOK / ORGANIC
  {
    title: "Total Videos",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Total number of organic videos published in selected time period",
  },
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Total organic minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Average organic number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Rate of  organic views on the video.( organic views /  organic impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "On average, the number of organic views received per video post",
  },

  {
    title: "Total Video Complete Views Organic Unique",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The number of people who viewed your videos for 95%, or more, of its length by organic reach",
  },
  {
    title: "Total Video Views Organic",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The number of times video has been played for at least 3 seconds",
  },

  // BASE / CARD / VIDEO / FACEBOOK /  ORGANIC SINGLE
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Total organic minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Average organic number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Rate of  organic views on the video.( organic views /  organic impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "On average, the number of organic views received per video post",
  },

  {
    title: "Total video complete views organic unique",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "The number of people who viewed your videos for 95%, or more, of its length by organic reach",
  },
  {
    title: "Total Video Views Organic",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "The number of times video has been played for at least 3 seconds",
  },

  // BASE / CARD / VIDEO / FACEBOOK / PAID
  {
    title: "Total Videos",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Total number of organic videos published in selected time period",
  },
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Total organic minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Average organic number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Rate of  organic views on the video.( organic views /  organic impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "On average, the number of organic views received per video post",
  },

  {
    title: "Total video complete views unique",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The number of people who viewed your videos for 95%, or more, of its length by organic reach",
  },
  {
    title: "Complete Video Views",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The number of times your videos played for 95%, or more, or its length",
  },
  {
    title: "Total Video Views Paid",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The number of times video has been played for at least 3 seconds",
  },

  // BASE / CARD / VIDEO / FACEBOOK / PAID SINGLE
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Total organic minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Average organic number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Rate of  organic views on the video.( organic views /  organic impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "On average, the number of organic views received per video post",
  },

  {
    title: "Total video complete views unique",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The number of people who viewed your videos for 95%, or more, of its length by organic reach",
  },
  {
    title: "Total Video Views Paid",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The number of times video has been played for at least 3 seconds",
  },

  // BASE / CARD / VIDEO / INSTAGRAM / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Views",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Reach",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of unique Instagram accounts that have seen the video",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Engagements",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The total engagement (likes + comments +saved) of a video post",
  },
  {
    title: "Engagement Rate",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Rate of interaction on the content. (likes + comments + saved) / Total number of views * 100",
  },
  {
    title: "Saved",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of unique Instagram accounts that have saved the video",
  },

  // BASE / CARD / VIDEO / INSTAGRAM / OVERALL SING:E
  {
    title: "Views",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Reach",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Total number of unique Instagram accounts that have seen the video",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Engagements",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The total engagement (likes + comments +saved) of a video post",
  },
  {
    title: "Engagement Rate",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Rate of interaction on the content. (likes + comments + saved) / Total number of views * 100",
  },
  {
    title: "Saved",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Total number of unique Instagram accounts that have saved the video",
  },
  // BASE / CARD / VIDEO / YOUTUBE / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that a video was viewed",
  },
  {
    title: "Total Subscribers",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Total lifetime subscriber",
  },
  {
    title: "Subscribers Gained",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users subscribed to a channel",
  },
  {
    title: "Subscribers Lost",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users unsubscribed from a channel",
  },
  {
    title: "Average View Duration",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Average minutes watched by people (mm:hh format)",
  },
  {
    title: "Average View Percentage",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The average percentage of a video watched during a video playback",
  },
  {
    title: "Engagement Rate",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Rate of interaction on the content. (likes + comments + shares) / Total number of views * 100",
  },
  {
    title: "Likes",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users indicated that they liked a video by giving it a positive rating",
  },
  {
    title: "Comments",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users commented on a video",
  },
  {
    title: "Shares",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users shared a video through the Share button",
  },
  {
    title: "Dislikes",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users indicated that they disliked a video by giving it a positive rating",
  },
  {
    title: "Red Views",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that a video was viewed by YouTube Premium members",
  },
  {
    title: "Estimated Minutes Watched",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Total minutes watched by people",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  {
    title: "Resonance Strength",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Score based on recent released, popularity and video/channel KPI's",
  },
  // BASE / CARD / VIDEO / YOUTUBE / OVERALL SINGLE

  {
    title: "Total Views",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that a video was viewed",
  },
  {
    title: "Total Subscribers",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Total lifetime subscriber",
  },
  {
    title: "Subscribers Gained",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users subscribed to a channel",
  },
  {
    title: "Subscribers Lost",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users unsubscribed from a channel",
  },
  {
    title: "Average View Duration",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Average minutes watched by people (mm:hh format)",
  },
  {
    title: "Average View Percentage",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The average percentage of a video watched during a video playback",
  },
  {
    title: "Engagement Rate",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Rate of interaction on the content. (likes + comments + shares) / Total number of views * 100",
  },
  {
    title: "Likes",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users indicated that they liked a video by giving it a positive rating",
  },
  {
    title: "Comments",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users commented on a video",
  },
  {
    title: "Shares",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users shared a video through the Share button. ",
  },
  {
    title: "Dislikes",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users indicated that they disliked a video by giving it a positive rating",
  },
  {
    title: "Red Views",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that a video was viewed by YouTube Premium members",
  },
  {
    title: "Estimated Minutes Watched",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Total minutes watched by people",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  {
    title: "Resonance Strength",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Score based on recent released, popularity and video/channel KPI's",
  },
  // BASE / CARD / VIDEO / YOUTUBE / PAID
  {
    title: "Total Videos",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "View Rate",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Total Number of views of videos",
  },
  // BASE / CARD / VIDEO / YOUTUBE / PAID SINGLE
  {
    title: "View Rate",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Total Number of views of videos",
  },
  // BASE / CARD / VIDEO / TWITTER / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // BASE / CARD / VIDEO / TWITTER / OVERALL SINGLE

  {
    title: "Total Views",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // BASE / CARD / VIDEO / TWITTER / ORGANIC
  {
    title: "Total Videos",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total views",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // BASE / CARD / VIDEO / TWITTER / ORGANIC SINGLE
  {
    title: "Total views",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // BASE / CARD / VIDEO / TWITTER / PAID
  {
    title: "Total Videos",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // BASE / CARD / VIDEO / TWITTER / PAID SINGLE

  {
    title: "Total Views",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // BASE / CARD / VIDEO / LINKEDIN / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Total Number of views of videos",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Video Completion Rate",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Percentage of times the video played to the end.(Complete video views ∕ Video starts)",
  },
  {
    title: "Minutes Watched",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Total minutes watched by people",
  },
  // BASE / CARD / VIDEO / LINKEDIN / OVERALL SINGLE
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Total Number of views of videos",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Video Completion Rate",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Percentage of times the video played to the end.(Complete video views ∕ Video starts)",
  },
  {
    title: "Minutes Watched",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Total minutes watched by people",
  },
  // BASE / CARD / VIDEO / LINKEDIN / ORGANIC
  {
    title: "Total Videos",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Total Number of views of videos",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Minutes Watched",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Total minutes watched by people",
  },

  // BASE / CARD / VIDEO / LINKEDIN / ORGANIC SINGLE
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Total Number of views of videos",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Minutes Watched",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Total minutes watched by people",
  },

  // BASE / CARD / VIDEO / LINKEDIN / PAID
  {
    title: "Total Videos",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Total Number of views of videos",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Video Completion Rate",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Percentage of times the video played to the end.(Complete video views ∕ Video starts)",
  },

  // BASE / CARD / VIDEO / LINKEDIN / PAID SINGLE
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Total Number of views of videos",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Video Completion Rate",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Percentage of times the video played to the end.(Complete video views ∕ Video starts)",
  },
];

export const DefaultSectionDefinations = [
  {
    title: "",
    module: "",
    platform: "",
    section: "",
    data: "Empty Data",
  },
  {
    title: "Action Table",
    module: "",
    platform: "",
    section: "",
    data: "Empty Data",
  },
  {
    title: "Summary Table on Sell Through Deep Dive",
    module: "",
    platform: "",
    section: "",
    data: "Empty Data",
  },
  // COKE / CARD / SUMMARY / OVERALL / OVERALL
  {
    title: "Summary",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "A weekly/monthly trendline for TV GRPs, TV impressions, Sales Volume, Sales Value can be filtered across multiple markets for weekly or monthly view",
  },
  //heading with space in end
  {
    title: "Summary Table ",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Summary Chart table for TV GRPs, TV impressions, Sales Volume, Sales Value filterable at Market and week level",
  },
  //heading with no space in end
  {
    title: "Summary Table",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Summary Chart table for TV GRPs, TV impressions, Sales Volume, Sales Value filterable at Market and week level",
  },
  {
    title: "Brand Wise Market Share Trend",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Trendline of market share across multiple brands",
  },
  {
    title: "Prime Time GRP's %",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Weekly trendline of percentage of GRP's attained during primetime having benchmark line set at a 49%",
  },
  {
    title: "Premium Position GRP's %",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Weekly trendline for percentage of GRP's attained during ads run in premium positions having benchmark line set at 30%",
  },
  {
    title: "Brand Wise Monthly TV Reach Compare",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Compare reach upto 5+ for brands(own+competition) across multiple markets",
  },
  {
    title: "Hashtag Summary Table",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Table for hashtag across platform, engagements, impressions, no of posts",
  },
  {
    title: "Overall Summary by Impressions Table",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Summary table of the impressions originating from Medium - Paid/Organic, Publishing medium-Social/Mass Media/Campaign, Platforms - FB, IG, TV, DV360",
  },
  {
    title: "Video Summary",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Metrics performance across multiple platforms for current and previous period",
  },
  {
    title: "Video Analysis",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Daily trendline for total number of videos, total views and vg score having dropdown of VG grouping",
  },
  {
    title: "Paid Reach Monthly Trendline",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Monthly paid reach across brands for DV 360 & DV360 + Meta",
  },
  {
    title: "Video Analysis Summary table ",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Summary table for total number of videos, total views and vg score having dropdown of VG grouping",
  },
  {
    title: "Overall Summary by Impressions",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Visualise contribution of impressions coming from organic and paid mediums at platform level",
  },
  {
    title: "Digital Campaigns Summary",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Trendline analysis of campaign performance metrics across multiple platforms",
  },
  {
    title: "Planned V/s Actual",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Comparison of planned & actual metrics across multiple platforms",
  },
  {
    title: "Social Summary",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Trendline analysis of metrics across multiple social platforms",
  },

  {
    title: "Hashtag Summary",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "List of top hashtags across platforms by impressions and engagements",
  },
  {
    title: "Website Summary",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Trend for website metrics selected through dropdown",
  },
  {
    title: "TV + Digital Summary",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Trendline of TV + Digital impressions and spends ",
  },

  {
    title: "Prime Time GRPs",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "TV metrics comparison for planned & actual during PT/NPT by genre of channels",
  },
  {
    title: "Non Prime Time GRPs",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "TV metrics comparison for planned & actual during PT/NPT by genre of channels",
  },
  {
    title: "Planned vs Delivered Metrics Performance",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Delivered percentage across KPIs for selected filters of Markets or Target Groups",
  },
  {
    title: "Campaign wise Planned v/s Delivered table",
    module: "coke summary",
    platform: "overall",
    section: "overall",
    data: "Tabulated view of the KPIs across campaign and adset names for which the planning has been done",
  },

  // BASE / SECTION /  SOCIAL / FACEBOOK / PAGE
  {
    title: "Page Views",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "A daily trend of the number of times a page has been viewed",
  },
  {
    title: "Page Growth",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of likes or dislikes a page has recieved on daily basis",
  },
  {
    title: "Current Period Source",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of likes or dislikes from the originating source for the current time period",
  },
  {
    title: "previous Period Source",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of likes or dislikes from the originating source for the previous time period",
  },

  // BASE / SECTION /  SOCIAL / FACEBOOK / AUDIENCE
  {
    title: "Audience Distribution: PTAT",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The age and gender across which a page has people talking about the page (any interaction)",
  },
  {
    title: "Audience Distribution: Followers",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The age and gender across which the followers of page are distributed",
  },
  {
    title: "By Country",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The number of followers distributed across multiple countries",
  },
  {
    title: "By City",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The number of followers distributed across multiple cities",
  },
  {
    title: "Audience Activity Trend",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The time of the day during which your followers are most active",
  },

  // BASE / SECTION /  SOCIAL / FACEBOOK / POST / OVERALL
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Content Analysis",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The distribution of interactions on overall post across the number of posts on various content type ",
  },
  {
    title: "Reach By Content Type",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The distribution of reach across various content type on overall posts",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of number of impressions received on overall posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of number of videos, video views (total views) and video views (10 seconds view) on overall posts",
  },
  {
    title: "Post engagement",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of number of overall post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Post action sentiment",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of times people have reacted positively or negatively to Page's overall posts",
  },
  {
    title: "Reaction Sentiment",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of positive of negative reactions people have given to Page's overall posts",
  },
  {
    title: "Comment sentiment",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The sentiment of comments derived from an AI based model for overall posts",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / FACEBOOK / POST / ORGANIC
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Content Analysis",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The distribution of interactions on overall post across the number of posts on various content type ",
  },
  {
    title: "Reach By Content Type",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The distribution of reach across various content type on overall posts",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Daily trend of number of impressions received on overall posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Daily trend of number of videos, video views (total views) and video views (10 seconds view) on overall posts",
  },
  {
    title: "Post engagement",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Daily trend of number of overall post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Post action sentiment",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of times people have reacted positively or negatively to Page's overall posts",
  },
  {
    title: "Reaction Sentiment",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of positive of negative reactions people have given to Page's overall posts",
  },
  {
    title: "Comment sentiment",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The sentiment of comments derived from an AI based model for overall posts",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / FACEBOOK / POST / PAID
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of number of impressions received on promoted posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of number of videos, video views and video views 10seconds on promoted posts",
  },
  {
    title: "Post Engagement",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of number of promoted post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },
  {
    title: "compare",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "When pure ads are run data for compare section will not be available if the ad was not run in that time duration",
  },

  // BASE / SECTION /  SOCIAL / INSTAGRAM / PAGE
  {
    title: "Profile Views",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Daily trend of number of followers on IG user'd profile ",
  },
  {
    title: "Followers Growth",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Daily trend of number of views on IG user'd profile. (Follower count will not be available for date range greatedr thatn 30 days from the 1st data pull)",
  },

  // BASE / SECTION /  SOCIAL / INSTAGRAM / AUDIENCE
  {
    title: "Audience Distribution Followers",
    module: "social",
    platform: "instagram",
    section: "audience",
    data: "Total number of followers distributed across age & gender",
  },
  {
    title: "Audience Distribution City",
    module: "social",
    platform: "instagram",
    section: "audience",
    data: "Total number of followers distributed across multiple cities",
  },
  {
    title: "Audience Distribution Country",
    module: "social",
    platform: "instagram",
    section: "audience",
    data: "Total number of followers distributed across multiple countries",
  },
  {
    title: "Audience Activity Trend",
    module: "social",
    platform: "instagram",
    section: "audience",
    data: "The time of the day during which your followers are most active",
  },

  // BASE / SECTION /  SOCIAL / INSTAGRAM / POST / OVERALL
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Content Analysis",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The distribution of interactions on overall post across the number of posts on various content type ",
  },
  {
    title: "Reach By Content Type",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The distribution of reach across various content type on overall posts",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Daily trend of number of impressions received on overall posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Daily trend of number of videos, video views on overall posts",
  },
  {
    title: "Post engagement",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Daily trend of number of overall post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Comment analysis",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The sentiment of comments derived from an AI based model for overall posts",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / INSTAGRAM / POST / ORGANIC
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Content Analysis",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The distribution of interactions on organic post across the number of posts on various content type ",
  },
  {
    title: "Reach By Content Type",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The distribution of reach across various content type on organic posts",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Daily trend of number of impressions received on organic posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Daily trend of number of videos, video views and on organic posts",
  },
  {
    title: "Post engagement",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Daily trend of number of organic post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Comment analysis",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The sentiment of comments derived from an AI based model for organic posts",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / INSTAGRAM / POST / PAID
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "Daily trend of number of impressions received on promoted posts for current & previous period",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / LINKEDIN / PAGE
  {
    title: "Page views",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Daily trend of number of all pages views",
  },
  {
    title: "Reach",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Daily trend of number of unique impressions",
  },
  {
    title: "Career Page View",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of carrer page views by device type",
  },
  {
    title: "Jobs Page View",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of job page views by device type",
  },

  // BASE / SECTION /  SOCIAL / LINKEDIN / AUDIENCE
  {
    title: "New Followers Trend line",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Daily trend of count of followers",
  },
  {
    title: "Audience Distribution Likes",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by age & gender of the Page likes",
  },
  {
    title: "Audience Distribution Followers",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by age & gender of the followers",
  },
  {
    title: "Audience Distribution By City",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by city of the followers",
  },
  {
    title: "Audience Distribution By Country",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by country of the followers",
  },
  {
    title: "Industry and Designation",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by industry & designation of the followers",
  },
  {
    title: "Company Sizewise Distribution",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by staff count range of the current organizations of the followers",
  },

  // BASE / SECTION /  SOCIAL / LINKEDIN / POST / OVERALL
  {
    title: "Impressions & Reach",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Daily trend of number of impressions & number of unique impressions",
  },
  {
    title: "Object Type",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of object type",
  },
  {
    title: "Interactions",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of organic likes, comments, and shares ",
  },
  {
    title: "Total Interactions",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Daily trend of number of organic likes, comments, and shares ",
  },
  {
    title: "Reactions",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "The type of reaction",
  },
  {
    title: "Comment Type",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of primary and secondary comment",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / LINKEDIN / POST / ORGANIC
  {
    title: "Impressions & Reach",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of number of impressions & number of unique impressions",
  },
  {
    title: "Object Type",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of object type",
  },
  {
    title: "Interactions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of organic likes, comments, and shares ",
  },
  {
    title: "Total Interactions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of number of organic likes, comments, and shares ",
  },
  {
    title: "Reactions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "The type of reaction",
  },
  {
    title: "Comment Type",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of primary and secondary comment",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / LINKEDIN / POST / PAID
  {
    title: "Impressions & Reach",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Daily trend of number of impressions & number of unique impressions",
  },
  {
    title: "Object Type",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of object type",
  },
  {
    title: "Interactions",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of organic likes, comments, and shares ",
  },
  {
    title: "Total Interactions",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Daily trend of number of organic likes, comments, and shares ",
  },
  {
    title: "Reactions",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "The type of reaction",
  },
  {
    title: "Comment Type",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of primary and secondary comment",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / TWITTER / PAGE
  {
    title: "User Profile Clicks / Mentions",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Daily trendline of user profile clicks and mentions with current and previous period",
  },
  {
    title: "Followers Growth",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Daily trendline of followers gained and lost in current period",
  },
  {
    title: "Word Cloud",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Most repetitive words with frequncy and bifurcation of overall, postive, negative and neutral words",
  },
  {
    title: "Mentions sentiment",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Percentage distribution of positive,negative and neutral tweets",
  },
  {
    title: "Lists",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "User can join lists created by others, or they can create lists of other accounts by group, topic",
  },

  // BASE / SECTION /  SOCIAL / TWITTER / AUDIENCE
  {
    title: "By Age",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "It includes age wise distribution of followers and impressions",
  },
  {
    title: "By Gender",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "It includes gender wise distribution of followers and impressions",
  },
  {
    title: "By Country",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "It includes city level distribution of followers and impressions",
  },
  {
    title: "Twitter Top Mentions",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "User's top tweet mentions in descending order on the basis of tweet enagagement",
  },
  {
    title: "Twitter Top Followers",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "User's top followers in descending order on the basis of follower's followers",
  },

  // BASE / SECTION /  SOCIAL / TWITTER / POST / OVERALL
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "The hashtags used in the overall posts receiving the most number of impressions",
  },
  {
    title: "Tweet Impressions",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of  how many times the Tweet has been viewed with respect to current and previous period",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of total video posted and video views in current period",
  },
  {
    title: "Video Views (Quartiles)",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Gives the quartile wise views on total number of videos posted in user's timeline",
  },
  {
    title: "Poll Votes",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of total number of poll tweets and poll votes",
  },
  {
    title: "Tweet Engagement",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of total engagement on tweet and engagement typewise trend in current period",
  },
  {
    title: "Campaign Effectiveness",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Organic vs paid impressions and engagement trend showing performance of paid vs orgnaic tweet",
  },
  {
    title: "Tweet Activity",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Organic, paid and combined tweet activities by day of the week and hour wise",
  },
  {
    title: "Engagement vs Impression",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Tweet performance represented by enagagment vs impressions or tweet length of tweet",
  },

  // BASE / SECTION /  SOCIAL / TWITTER / POST / ORGANIC
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "The hashtags used in the orgnaic posts receiving the most number of impressions",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of number of times the organic tweet has been viewed",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of total organic video posted and video views in current period",
  },
  {
    title: "Video Views (Quartiles)",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Gives the quartile wise views on total orgnaic videos posted in user's timeline",
  },
  {
    title: "Poll Votes",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of total number of orgnaic poll tweets and poll votes",
  },
  {
    title: "Tweet Engagement",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of total engagement on orgnaic tweet and engagement typewise trend in current period",
  },
  {
    title: "User profile clicks",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trendline of user profile clicks from organic contexts",
  },
  {
    title: "Top 5 Tweets",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Detailed view of organic tweet KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  SOCIAL / TWITTER / POST / PAID
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "The hashtags used in the paid posts receiving the most number of impressions",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of number of times the paid tweet has been viewed",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of total paid video posted and video views in current period",
  },
  {
    title: "Poll Votes",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of total number of paid poll tweets and poll votes",
  },
  {
    title: "Tweet Engagement",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of total engagement on paid tweet and engagement typewise trend in current period",
  },
  {
    title: "User profile clicks",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trendline of user profile clicks from paid contexts",
  },
  {
    title: "Top 5 Tweets",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Detailed view of paid tweet KPI's on top posts based on impressions & comparison between them",
  },

  // BASE / SECTION /  CAMPAIGN / OVERALL / OVERALL /
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Platform's campaign objective wise distribution representing number of campaigns",
  },
  {
    title: "Performance By KPI",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Comparative view of platform KPI's daily trend",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Detailed view of each platform's campaign, ad agroup and ads with respective KPI metrics performance",
  },
  {
    title: "Planned vs Delivered Metrics Performance",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Delivered percentage across KPIs for selected filters of Markets or Target Groups",
  },
  {
    title: "Campaign wise Planned v/s Delivered table",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Tabulated view of the KPIs across campaign and adset names for which the planning has been done",
  },

  // BASE / SECTION /  CAMPAIGN / OVERALL / VIDEO
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "overall",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video adss",
  },

  // BASE / SECTION /  CAMPAIGN / GOOGLE / OVERALL
  {
    title: "Platform Wise Distribution",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "Google ad network wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "It includes gender wise distribution of KPI's performance",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "It includes device wise distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Age",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "It includes age wise distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "It includes country and city level targeting distribution of KPI's performance",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // BASE / SECTION /  CAMPAIGN / GOOGLE / VIDEO
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "google",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },

  // BASE / SECTION /  CAMPAIGN / FACEBOOK / OVERALL
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Campaign Objective wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "It includes gender wise distribution of KPI's performance",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "It Includes device level targeting distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "It includes country and city level targeting distribution of KPI's performance",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },
  {
    title: "Planned vs Delivered Metrics Performance",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Delivered percentage across KPIs for selected filters of Markets or Target Groups",
  },
  {
    title: "Campaign wise Planned v/s Delivered table",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Tabulated view of the KPIs across campaign and adset names for which the planning has been done",
  },

  // BASE / SECTION /  CAMPAIGN / FACEBOOK / VIDEO
  {
    title: "Video Views Analysis",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "It includes gender wise distribution of KPI's performance",
  },

  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "It includes country and city level targeting distribution of KPI's performance",
  },

  // BASE / SECTION /  CAMPAIGN / INSTAGRAM / OVERALL
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Campaign Objective wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "It includes gender wise distribution of KPI's performance",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "It Includes device level targeting distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "It includes country and city level targeting distribution of KPI's performance",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },
  {
    title: "Planned vs Delivered Metrics Performance",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Delivered percentage across KPIs for selected filters of Markets or Target Groups",
  },
  {
    title: "Campaign wise Planned v/s Delivered table",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Tabulated view of the KPIs across campaign and adset names for which the planning has been done",
  },
  // BASE / SECTION /  CAMPAIGN / INSTAGRAM / VIDEO
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "It includes gender wise distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "It includes country and city level targeting distribution of KPI's performance",
  },

  // BASE / SECTION /  CAMPAIGN / TWITTER / OVERALL
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Campaign Objective wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "It includes gender wise distribution of KPI's performance",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "It Includes device level targeting distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "It includes country level targeting distribution of KPI's performance",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // BASE / SECTION /  CAMPAIGN / TWITTER / VIDEO
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "It includes gender wise distribution of video KPI's performance",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "It includes gender wise distribution of video KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "It includes country level targeting distribution of video KPI's performance",
  },
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },
  // BASE / SECTION /  CAMPAIGN / LINKEDIN / OVERALL
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Campaign Objective wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience Distribution",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "It includes country and city level targeting distribution of KPI's performance",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // BASE / SECTION /  CAMPAIGN / LINKEDIN / VIDEO
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "linkedin",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },

  // BASE / SECTION /  CAMPAIGN / DV360 / OVERALL
  {
    title: "Campaign Type Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "Campaign type wise number of campaigns such as video, display and audio with daily trend of KPI's",
  },
  {
    title: "Device Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It Includes device type and operating system level targeting distribution of KPI's performance.",
  },
  {
    title: "Content Position Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It Includes ad content position wise distribution with respect to KPI's performance.",
  },
  {
    title: "App/Url Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It gives top 10 app/urls performance with respect to KPI metrics selected.",
  },
  {
    title: "App/Url Wise Trend",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It includes daily trendline of top 10 app/urls with respect to KPI metrics selected.",
  },
  {
    title: "Audience Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It includes country, state and city level targeting with respect to KPI's performance.",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "Detailed view of campaign, line items and creative with respective KPI metrics performance",
  },
  {
    title: "Planned vs Delivered Metrics Performance",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "Delivered percentage across KPIs for selected filters of Markets or Target Groups",
  },
  {
    title: "Campaign wise Planned v/s Delivered table",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "Tabulated view of the KPIs across campaign and adset names for which the planning has been done",
  },
  // BASE / SECTION /  CAMPAIGN / DV360 / VIDEO

  {
    title: "Video views",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },
  {
    title: "Trend analysis",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of views received on video campaigns filtered by Video content duration bucket",
  },
  {
    title: "KPI Trend analysis",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of KPI's ",
  },
  {
    title: "Content position wise distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "The distribution of Content position format filterd by views type",
  },
  {
    title: "App/Url Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of Top 10 App/Url filtered by views type",
  },
  {
    title: "Device Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "The distribution of Complete views by device and os",
  },
  {
    title: "Audience Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "KPI Metrics Performance across State & City",
  },
  {
    title: "Ad Type Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "The distribution of Ad Type filterd by views type",
  },
  {
    title: "Performance By Ad Type",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of Ad Type of video campaigns filtered by views type",
  },
  {
    title: "VTR Trend Analysis",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of VTR filtered by Ad type",
  },
  {
    title: "YouTube Ad-Type Wise Benchmarking At Ad-Group Level",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of KPI metrics with comparing benchmarking metrics filtered by Ad type and KPI Metrics",
  },
  {
    title: "Planned vs Delivered Metrics Performance",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Delivered percentage across KPIs for selected filters of Markets or Target Groups",
  },
  {
    title: "Campaign wise Planned v/s Delivered table",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Tabulated view of the KPIs across campaign and adset names for which the planning has been done",
  },
  // BASE / SECTION /  VIDEO / OVERALL / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Channel Summary Table",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Performance view of each brand with respective video KPI metrics",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / OVERALL / OVERALL SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Channel Summary Table",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Performance view of each brand with respective video KPI metrics",
  },

  // BASE / SECTION /  VIDEO / FACEBOOK / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Progressive view of video given by views distribution format,Daily trend of views received on videos",
  },
  {
    title: "Playing Behaviour Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Distribution and Daily trend of views by playing behaviour type, filter by views distribution format",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of views and unique views filter by views distribution format",
  },
  {
    title: "View Publish Trend",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Sentiment",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The number of positive of negative reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Audience Distribution By Gender",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Total minutes watched by Top Audiences age and gender",
  },
  {
    title: "Audience Distribution By Country",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Total minutes watched by Top Audiences country and city",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / FACEBOOK / SINGLE ORAGNIC
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Progressive view of video given by views distribution format,Daily trend of views received on videos",
  },
  {
    title: "Playing Behaviour Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Distribution and Daily trend of views by playing behaviour type, filter by views distribution format",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Daily trend of views and unique views filter by views distribution format",
  },
  {
    title: "View Publish Trend",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Sentiment",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The number of positive of negative reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Audience Distribution By Gender",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Total minutes watched by Top Audiences age and gender",
  },
  {
    title: "Audience Distribution By Country",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Total minutes watched by Top Audiences country and city",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Video Retention Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The number of times your video played at each interval as a percentage of all views",
  },

  // BASE / SECTION /  VIDEO / FACEBOOK / ORGANIC
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The hashtags used in the organic video posts receiving the most number of organic views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The distribution of organic views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Progressive view of video given by views distribution format,Daily trend of organic views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Daily trend of organic views and unique views filter by views distribution format",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Detailed view of video KPI's on top videos based on organic views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / FACEBOOK / ORGANIC SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "The hashtags used in the organic video posts receiving the most number of organic views",
  },

  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Progressive view of video given by views distribution format,Daily trend of organic views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Daily trend of organic views and unique views filter by views distribution format",
  },

  // BASE / SECTION /  VIDEO / FACEBOOK / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The hashtags used in the organic video posts receiving the most number of paid views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The distribution of paid views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Progressive view of video given by views distribution format,Daily trend of paid views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of paid views and unique views filter by views distribution format",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on paid views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / FACEBOOK / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The hashtags used in the organic video posts receiving the most number of paid views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The distribution of paid views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Progressive view of video given by views distribution format,Daily trend of paid views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Daily trend of paid views and unique views filter by views distribution format",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Detailed view of video KPI's on top videos based on paid views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / FACEBOOK / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The hashtags used in the organic video posts receiving the most number of paid views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The distribution of paid views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Progressive view of video given by views distribution format,Daily trend of paid views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of paid views and unique views filter by views distribution format",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on paid views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / FACEBOOK / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The hashtags used in the organic video posts receiving the most number of paid views",
  },

  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Progressive view of video given by views distribution format,Daily trend of paid views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Daily trend of paid views and unique views filter by views distribution format",
  },

  // BASE / SECTION /  VIDEO / INSTAGRAM / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },
  {
    title: "Total Videos",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },

  {
    title: "Views",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The number of times video has been played for at least 3 seconds",
  },

  {
    title: "Reach",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of unique Instagram accounts that have seen the video",
  },

  {
    title: "View Through Rate",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Rate of views on the video.(views /  impression) * 100",
  },

  {
    title: "Engagement",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The total engagement (likes + comments +saved) of a video post",
  },

  {
    title: "Engagement Rate",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Rate of interaction on the content. (likes + comments + saved) / Total number of views * 100",
  },

  {
    title: "Saved",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of unique Instagram accounts that have saved the video",
  },

  {
    title: "View Analysis",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Funnel view analysis of impressions to engagement of video ",
  },

  {
    title: "Top 5 Videos",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / INSTAGRAM / OVERALL SING:E
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },

  {
    title: "Views",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The number of times video has been played for at least 3 seconds",
  },

  {
    title: "Reach",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Total number of unique Instagram accounts that have seen the video",
  },

  {
    title: "View Through Rate",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },

  {
    title: "Engagement",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The total engagement (likes + comments +saved) of a video post",
  },

  {
    title: "Engagement Rate",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Rate of interaction on the content. (likes + comments + saved) / Total number of views * 100",
  },

  {
    title: "Saved",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Total number of unique Instagram accounts that have saved the video",
  },

  {
    title: "View Analysis",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Funnel view analysis of impressions to engagement of video ",
  },

  // BASE / SECTION /  VIDEO / YOUTUBE / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  {
    title: "View Trend Analysis",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Traffic Analysis",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Video Sentiment",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Audience Distribution By Gender",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Audience Distribution By Country",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Device Distribution",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Channel Summary",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "youtube",
    section: "organic",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / YOUTUBE / OVERALL SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Traffic Analysis",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Video Sentiment",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Audience Distribution By Gender",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Audience Distribution By Country",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Device Distribution",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Channel Summary",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  {
    title: "Video Retention Analysis",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / YOUTUBE / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Bucket wise Funnel",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Audience Distribution by Country",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The number of views distributed across multiple countries",
  },
  {
    title: "View of Device",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The distribution of views by device",
  },
  {
    title: "Published Time Views analysis",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Channel Summary",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Performance view of each brand with respective video KPI metrics",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / YOUTUBE / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Bucket wise Funnel",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Audience Distribution by Country",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "The number of views distributed across multiple countries",
  },
  {
    title: "View of Device",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "The distribution of views by device",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Channel Summary",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Performance view of each brand with respective video KPI metrics",
  },
  // BASE / SECTION /  VIDEO / TWITTER / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "The sentiment of replys derived from an AI based model for videos",
  },
  {
    title: "Top 5 Tweets",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  // BASE / SECTION /  VIDEO / TWITTER / OVERALL SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "The sentiment of replys derived from an AI based model for videos",
  },

  // BASE / SECTION /  VIDEO / TWITTER / ORGANIC
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The sentiment of replys derived from an AI based model for videos",
  },
  {
    title: "Top 5 Tweets",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  // BASE / SECTION /  VIDEO / TWITTER / ORAGNIC SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "The sentiment of replys derived from an AI based model for videos",
  },

  // BASE / SECTION /  VIDEO / TWITTER / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "The sentiment of replys derived from an AI based model for videos",
  },
  {
    title: "Top 5 Tweets",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  // BASE / SECTION /  VIDEO / TWITTER / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "The sentiment of replys derived from an AI based model for videos",
  },

  // BASE / SECTION /  VIDEO / LINKEDIN / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / LINKEDIN / OVERALL SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The sentiment of comments derived from an AI based model for videos",
  },

  // BASE / SECTION /  VIDEO / LINKEDIN / ORGANIC
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / LINKEDIN / ORGANIC SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The sentiment of comments derived from an AI based model for videos",
  },

  // BASE / SECTION /  VIDEO / LINKEDIN / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // BASE / SECTION /  VIDEO / LINKEDIN / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  // BASE / SECTION /  WEBSITE / TRAFFIC / CHANNEL WISE TRAFFIC
  {
    title: "Summary",
    module: "website",
    platform: "traffic",
    section: "channel",
    data: "Analyzing the performance of each traffic channel with corresponding KPI metrics",
  },
  // BASE / SECTION /  WEBSITE / TRAFFIC / MONTHLY
  {
    title: "Monthly Traffic Comparison",
    module: "website",
    platform: "traffic",
    section: "monthly",
    data: "Comparing the number of sessions and new users on a monthly basis by traffic channel",
  },
  {
    title: "Traffic Source",
    module: "website",
    platform: "traffic",
    section: "monthly",
    data: "Analyzing source-specific sessions for current and previous periodsby by traffic channel",
  },
  // BASE / SECTION /  WEBSITE / TECHNOLOGY / DEVICE TRAFFIC
  {
    title: "Summary",
    module: "website",
    platform: "technology",
    section: "device traffic",
    data: "Analyzing the performance of device and traffic source type with corresponding KPI metrics",
  },
  // BASE / SECTION /  WEBSITE / LOCATION / CITY
  {
    title: "Summary",
    module: "website",
    platform: "location",
    section: "city",
    data: "Analyzing the performance of city with corresponding KPI metrics",
  },
  // BASE / SECTION /  WEBSITE / LOCATION / OVERALL
  {
    title: "Summary",
    module: "website",
    platform: "landing page",
    section: "overall",
    data: "Analyzing the performance of landing page with corresponding KPI metrics",
  },
  // BASE / SECTION /  WEBSITE / EVENTS / OVERALL
  {
    title: "Summary",
    module: "website",
    platform: "events",
    section: "overall",
    data: "Analyzing the performance of events and traffic source type with corresponding KPI metrics",
  },
  // BASE / SECTION /  WEBSITE / ECOM / OVERALL
  {
    title: "Summary",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "Analyzing the performance of products with corresponding KPI metrics",
  },
  // BASE / SECTION /  SALES / OVERALL / OVERALL
  {
    title: "Map",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Distribution of Sales across country divided into zones & states, pin color determines growth/degrowth compared to previous period",
  },
  {
    title: "Performance Trend",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Analyze trends and relationships between Sales Value, Sales Volume, GRP and Spends (Digital Impressions will only be available for own brands)",
  },
  {
    title: "Top / Bottom Performing Markets",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Regionwise performance of GRPs vs Sales Volume",
  },
  {
    title: "Summary",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "State wise performance with option to sort by available metrics in ascending or descending order (Digital KPI's will only be available for own brands)",
  },
  {
    title: "Brandwise Performance",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Identify the Sales Volume hierarchy between Brands, Zones & States (Digital KPI's will only be available for own brands)",
  },
  {
    title: "Compare Table",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Comparison view upto 3 brands for KPI's (Digital KPI's will only be available for own brands)",
  },
  {
    title: "Impressions",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "The number of times an advertisement is viewed by an audience",
  },
  {
    title: "Sales Volume",
    module: "sales compare",
    platform: "overall",
    section: "overall",
    data: "The number of times an advertisement is viewed by an audience",
  },
  {
    title: "Sales Value",
    module: "sales compare",
    platform: "overall",
    section: "overall",
    data: "The number of times an advertisement is viewed by an audience",
  },
  {
    title: "Grp",
    module: "sales compare",
    platform: "overall",
    section: "overall",
    data: "The number of times an advertisement is viewed by an audience",
  },
  {
    title: "Impressions",
    module: "sales compare",
    platform: "overall",
    section: "overall",
    data: "The number of times an advertisement is viewed by an audience",
  },
  // BASE / SECTION /  TV / OVERALL / OVERALL
  {
    title: "Map",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Distribution of GRP across country divided into zones and markets, pin color determines growth/degrowth compared to previous period",
  },
  {
    title: "Performance Trend",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Analyze trends and relationships between Sales Value, Sales Volume, GRP and Spends",
  },
  {
    title: "Top Genre & GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Top 9 Genres of TV channels by GRPs where ads were run (other genres clubbed under 'others')",
  },
  {
    title: "Time Slot & GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Top 5 time slots by GRPs where ads were run",
  },
  {
    title: "Ad duration wise GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Top duration of ads by GRPs",
  },
  {
    title: "Brand wise GRP & Spends",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Spends vs Share of Voice for each brand along with growth % w.r.t. the previous period",
  },
  {
    title: "Ad duration wise GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Top duration of ads by GRPs",
  },
  {
    title: "Brand wise GRP & Spends",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Spends vs Share of Voice for each brand along with growth % w.r.t. the previous period",
  },
  {
    title: "Brand wise GRP & Spends",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Spends vs Share of Voice for each brand along with growth % w.r.t. the previous period",
  },
  {
    title: "Time Slot wise GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Identify the Day and time where ads got the maximum number of GRPs",
  },
  {
    title: "Ad position wise GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Identify the Top Genres where ads ran in Premium and non-Premium positions",
  },
  {
    title: "Ad / Campaign Summary",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Campaign & ad performance with option to sort by available metrics in ascending or desending order",
  },
  {
    title: "Compare Table",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Comparison view between brands for KPI's between brands (up to 3 brands at a time)",
  },
  {
    title: "Tv Genre with High GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Compare genrewise GRPs contribution amongst brands",
  },
  {
    title: "GRP's by PT/Non PT",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Compare Ad position wise GRPs contribution amongst brands",
  },
];

export const HaleonCardDefinations = [
  // HALEON / CARD / CAMPAIGN / OVERALL / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The number of times your ads were on screen on all platforms",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The number of clicks on your ads on all platforms",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The total number of conversions across all channels and platform",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The total estimated amount of money you've spent on your campaigns on all platform",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Engagement includes summation of engagement across all the platforms",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Click through rate, The percentage of times people saw your ad and performed a click (all)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Cost per clicks, The average cost for each click (all)",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions",
  },
  {
    title: "Cost per video views",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Cost per video view",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Reach rate",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Reach rate equals to total reach of a post per impressions",
  },
  {
    title: "Frequency",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "The average number of times each person saw your ad. This metric is estimated",
  },
  {
    title: "View rate",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Percentage of users who viewed your ad, formulates as video views per impressions",
  },

  // HALEON / CARD / CAMPAIGN / OVERALL / VIDEO
  {
    title: "Video views",
    module: "campaign",
    platform: "overall",
    section: "video",
    data: "Count of the number of times a video ad is watched",
  },

  // HALEON / CARD / CAMPAIGN / GOOGLE / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "Count of how often your ad has appeared on a search results page or website on the Google Network",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The number of clicks",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The number of conversions",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "total amount of money you've spent on your campaign, ad set or ad during its schedule",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The number of engagements. An engagement occurs when a viewer expands your Lightbox ad",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The number of clicks your ad receives divided by the number of times your ad is shown (Impressions)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "The total cost of all clicks divided by the total number of clicks received",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "Cost-per-thousand impressions",
  },

  // HALEON / CARD / CAMPAIGN / GOOGLE / VIDEO
  {
    title: "Video Views",
    module: "campaign",
    platform: "google",
    section: "video",
    data: "The number of times your video ads were viewed",
  },
  {
    title: "View Through Conversions",
    module: "campaign",
    platform: "google",
    section: "video",
    data: "Customer views an ad, then later completes a conversion on your site",
  },
  {
    title: "Video View Rate",
    module: "campaign",
    platform: "google",
    section: "video",
    data: "The number of views your video ad receives divided by its number of impressions",
  },

  // HALEON / CARD / CAMPAIGN / FACEBOOK / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The number of times your ads were on screen",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The number of clicks on your ads",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The total number of conversions across all channels attributed to your ads",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Engagement includes all clicks, likes, comments and shares",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Click through rate, The percentage of times people saw your ad and performed a click (all)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Cost per clicks, The average cost for each click (all)",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions",
  },
  {
    title: "Cost per video view",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Reach rate",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Reach rate equals to total reach of a post per impressions",
  },
  {
    title: "Frequency",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "The average number of times each person saw your ad This metric is estimated",
  },
  {
    title: "View rate",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Total 3 sec video views/Impressions*100 = View rate %",
  },

  // HALEON / CARD / CAMPAIGN / FACEBOOK / VIDEO
  {
    title: "Avg. Watched Time",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "The average time a video was played, including any time spent replaying the video",
  },
  {
    title: "Video Play Actions",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "The number of times your video starts to play. Counted for each impression, excludes replays",
  },

  // HALEON / CARD / CAMPAIGN / INSTAGRAM / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The number of times your ads were on screen",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The number of clicks on your ads",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The total number of conversions across all channels attributed to your ads",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Engagement includes all clicks, likes, comments and shares",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Click through rate, The percentage of times people saw your ad and performed a click (all)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Cost per clicks, The average cost for each click (all)",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions",
  },
  {
    title: "Cost per video view",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Reach rate",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Reach rate equals to total reach of a post per impressions",
  },
  {
    title: "Frequency",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "The average number of times each person saw your ad This metric is estimated",
  },
  {
    title: "View rate",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Total 3 sec video views/Impressions*100 = View rate %",
  },

  // HALEON / CARD / CAMPAIGN / INSTAGRAM / VIDEO
  {
    title: "Avg. watched time",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "The average time a video was played, including any time spent replaying the video",
  },
  {
    title: "Video play actions",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "The number of times your video starts to play. Counted for each impression, excludes replays",
  },

  // HALEON / CARD / CAMPAIGN / TWITTER / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of impressions",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of clicks, including favorites and other engagements",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "The total number of all conversions attributed to your ads",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total spend of campaigns or ads",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of engagements",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Click through rate, The percentage of times people saw your ad and performed a click (all)",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Cost per clicks, The average cost for each click (all)",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions",
  },
  {
    title: "Media views",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of views (autoplay and click) of media across Videos, Vines, GIFs, and Images",
  },
  {
    title: "Media engagements",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Total number of clicks of media across Videos, Vines, GIFs, and Images",
  },

  // HALEON / CARD / CAMPAIGN / TWITTER / VIDEO
  {
    title: "Video total views",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "Count of the number of times a video ad is watched",
  },
  {
    title: "Video rate",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "The number of views your video ad receives divided by its number of impressions",
  },
  {
    title: "cost per view",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },

  // HALEON / CARD / CAMPAIGN / LINKEDIN / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Number of times your ad was displayed",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Total chargeable clicks based on your campaign's objective",
  },
  {
    title: "Conversions",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Total number of times member accounts took a desired action after clicking on or seeing your ad",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Amount you spent on ads impressions or interactions",
  },
  {
    title: "Engagements",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "The sum of all social actions, clicks to Landing Page, and clicks to LinkedIn Page",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Percentage of chargeable clicks relative to impressions",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Total spent on your ads divided by total clicks",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Total spent on your ads divided by 1,000 impressions",
  },

  // HALEON / CARD / CAMPAIGN / LINKEDIN / VIDEO
  {
    title: "Video views",
    module: "campaign",
    platform: "linkedin",
    section: "video",
    data: "Two or more continuous seconds of playback while the video is at least 50% on screen",
  },

  // HALEON / CARD / CAMPAIGN / DV360 / OVERALL
  {
    title: "Impressions",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of impressions that reached the intended or guaranteed audience.",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a person has clicked on an ad.",
  },
  {
    title: "Total Conversions",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The aggregate total of all Post-View Conversions and Post-Click Conversions.",
  },
  {
    title: "Spends",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The total amount spent on the campaigns.",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The percentage of time people clicked on an ad, calculated as Clicks / Impressions × 100.",
  },
  {
    title: "CPM",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions.",
  },
  {
    title: "CPC",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "Cost per clicks, The average cost for clicks on an ad.",
  },
  {
    title: "Post Click Conversion Rate",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The percentage of post-click conversions out of total conversions from campaigns.",
  },
  {
    title: "Views",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times your ad has been viewed.",
  },
  {
    title: "VTR",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The percentage of time your ad is viewed, relative to the total number of times your ad is shown.",
  },
  {
    title: "Complete Views Video",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been played to its end.",
  },
  {
    title: "Complete View Rate",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of views your video ad receives divided by its number of impressions.",
  },
  {
    title: "Cost per Complete View",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The average cost of complete video view, based on the Total Media Cost spent, calculated as Total Media Cost / Complete Views (Video).",
  },
  {
    title: "Rich Media Engagements",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times that users engaged with an interactive element in a rich media creative.",
  },
  {
    title: "Scrolls",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times that a user has scrolled through a rich media creative.",
  },
  {
    title: "Full Screens",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been viewed in full screen mode.",
  },
  {
    title: "Audio Mutes",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been muted.",
  },
  {
    title: "Pauses",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been paused.",
  },
  {
    title: "Skips Video",
    module: "campaign",
    platform: "dv360",
    section: "overall",
    data: "The number of times a video has been skipped.",
  },

  // HALEON / CARD / CAMPAIGN / DV360 / VIDEO

  {
    title: "No of campaigns",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "No. of video campaigns running in selected time period",
  },
  {
    title: "Impressions",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of impressions that reached the intended or guaranteed audience.",
  },
  {
    title: "Views",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of times your ad has been viewed.",
  },
  {
    title: "VTR",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The percentage of time your ad is viewed, relative to the total number of times your ad is shown (video and thumbnail impressions).",
  },
  {
    title: "Cost per View",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "Calculated by dividing the total cost of the ad campaign by the number of views",
  },
  {
    title: "Complete Views (Video)",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of times a video has been played to its end.",
  },
  {
    title: "Complete View Rate",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of views your video ad receives divided by its number of impressions",
  },
  {
    title: "Cost per Complete View",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The average cost of each complete video view, based on the Total Media Cost spent, calculated as the product of Total Media Cost / Complete Views (Video).",
  },
  {
    title: "Clicks",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The number of times a person has clicked on an ad.",
  },
  {
    title: "CTR",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The percentage of time people clicked on an ad, calculated as the product of Clicks / Impressions × 100.",
  },
  {
    title: "Completion Rate (Complete/Starts) * 100",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "Percentage of times the video played to the end, calculated as the product of Complete Views (Video)/ Starts× 100.",
  },
  {
    title: "Total Media Cost (Advertiser Currency)",
    module: "campaign",
    platform: "dv360",
    section: "video",
    data: "The total amount spent on the campaigns.",
  },

  // HALEON / CARD / SOCIAL / FACEBOOK / PAGE
  {
    title: "Total Followers",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The total number of people who have followed your Page",
  },
  {
    title: "New Page Likes",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The total number of people who have liked your Page",
  },
  {
    title: "Views",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of times a Page has been viewed by logged-in and logged-out people",
  },
  {
    title: "Reach",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of people who had any content from your Page or about your Page enter their screen",
  },
  {
    title: "Clicks",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of clicks on your Page",
  },

  // HALEON / CARD / SOCIAL / FACEBOOK / POST / OVERALL
  {
    title: "Total Posts",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of organic and promoted posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of times Page's overall post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of people who had Page's overall post enter their screen",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of likes received on overall posts",
  },
  {
    title: "Shares",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of times overall posts have been shared",
  },
  {
    title: "Total Clicks",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of total clicks have been made on overall posts",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Rate of interaction on the content. (Reactions + comments + shares) / Post impressions * 100",
  },

  // HALEON / CARD / SOCIAL / FACEBOOK / POST / ORAGNIC
  {
    title: "Total Posts",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of organic posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of times Page's organic post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of people who had Page's organic post enter their screen",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of likes received on organic posts",
  },
  {
    title: "Shares",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of times organic posts have been shared",
  },
  {
    title: "Total Clicks",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of total clicks have been made on organic posts",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Rate of interaction on the content. (Reactions + comments + shares) / Post impressions * 100",
  },

  // HALEON / CARD / SOCIAL / FACEBOOK / POST / PAID
  {
    title: "Total Posts",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of promoted posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of times Page's promoted post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of people who had Page's promoted post enter their screen",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of likes received on promoted posts",
  },
  {
    title: "Shares",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of times promoted posts have been shared",
  },
  {
    title: "Comments",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of commens made on Page's promoted posts",
  },
  {
    title: "Total Spends",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The amount of money spent on promoting the Page's posts",
  },
  {
    title: "Total Clicks",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The number of total clicks have been made on promoted posts",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Rate of interaction on the content. (Reactions + comments + shares) / Post impressions * 100",
  },
  {
    title: "CPC",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Cost per click (CPC) is a metric for how much each click costs",
  },
  {
    title: "CPM",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Cost per 1000 impressions, the amount spend for each 1000 impressions",
  },

  // HALEON / CARD / SOCIAL / INSTAGRAM / PAGE
  {
    title: "Total Followers",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Total number of Instagram users following the user",
  },
  {
    title: "New Followers",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Number of new users who started following the user",
  },
  {
    title: "Profile Views",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Total number of users who have viewed the IG User's profile within the specified period",
  },
  {
    title: "Page Clicks",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Total number of taps on the text message link in the IG User's profile",
  },

  // HALEON / CARD / SOCIAL / INSTAGRAM / POST / OVERALL
  {
    title: "Total Posts",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The number of organic and promoted posts",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The number of likes received on your posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The number of times your Page's post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The number of people who had your Page's post enter their screen",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Rate of interaction on the content. (likes + comments + shares) / Post impressions * 100",
  },
  {
    title: "Average Post Per Day",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The average number of posts published on daily basis",
  },

  // HALEON / CARD / SOCIAL / INSTAGRAM / POST / ORAGNIC
  {
    title: "Total Posts",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The number of organic posts",
  },
  {
    title: "Total Likes",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The number of likes received on your posts",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The number of times your Page's post entered a person's screen",
  },
  {
    title: "Post Reach",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The number of people who had your Page's post enter their screen",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Rate of interaction on the content. (likes + comments + shares) / Post impressions * 100",
  },
  {
    title: "Average Posts Per Day",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The average number of posts published on daily basis",
  },

  // HALEON / CARD / SOCIAL / INSTAGRAM / POST / PAID
  {
    title: "Total Posts",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The number of promoted posts",
  },
  {
    title: "Total Post Impressions",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The number of times Page's promoted post entered a person's screen",
  },
  {
    title: "Total Post Reach",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The number of people who had Page's promoted post enter their screen",
  },
  {
    title: "Spends",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The amount of money spent on promoting the Page's posts",
  },
  {
    title: "CPE",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "CPE (Cost per engagement), which compares the cost to the total amount of engagements",
  },

  // HALEON / CARD / SOCIAL / TWITTER / PAGE
  {
    title: "Followers Gained",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Total number of followers gained in current period",
  },
  {
    title: "Total following",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Total number of followings",
  },
  {
    title: "Mentions",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Mention is a Tweet that contains person's username anywhere in the body of the Tweet",
  },
  {
    title: "Total Profile Clicks",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Count of the number of times a user clicks the on display name, user name, profile picture of tweet",
  },

  // HALEON / CARD / SOCIAL / TWITTER / POST / OVERALL
  {
    title: "Total Tweets",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Number of total tweet, i.e organic and paid",
  },
  {
    title: "Total Engagements",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Total engagement includes likes, retweets, replies, clicks and follows",
  },
  {
    title: "Total Impressions",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Count of how many times the Tweet has been viewed (not unique by user)",
  },
  {
    title: "Total profile clicks",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Count of the number of times a user clicks the on display name, user name, profile picture of tweet",
  },
  {
    title: "Engagement rate",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "It gives by formula total engagement per total impressions on tweet",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Count of how many times the video included in the Tweet has been viewed",
  },
  {
    title: "Url link clicks",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Total number of times a user clicks on a URL link or URL preview card in a Tweet",
  },

  // HALEON / CARD / SOCIAL / TWITTER / POST / ORAGNIC
  {
    title: "Total Tweets",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Number of orgnaic tweet",
  },
  {
    title: "Total Engagements",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Total engagement includes likes, retweets, replies, clicks and follows on orgnic tweets",
  },
  {
    title: "Total Impressions",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Count of how many times the organic tweet has been viewed (not unique by user)",
  },
  {
    title: "Total profile clicks",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Number of times a user clicks the on display name, user name, profile picture of organic tweets",
  },
  {
    title: "Engagement rate",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "It gives by formula total engagement per total impressions on organic tweet",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Count of how many times the video included in the organic tweet has been viewed",
  },
  {
    title: "Url link clicks",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Total number of times a user clicks on a URL link or URL preview card in a organic tweet",
  },

  // HALEON / CARD / SOCIAL / TWITTER / POST / PAID
  {
    title: "Total Tweets",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Number of paid tweet",
  },
  {
    title: "Total Engagements",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Total engagement includes likes, retweets, replies, clicks and follows on paid tweets",
  },
  {
    title: "Total Impressions",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Count of how many times the paid tweet has been viewed (not unique by user)",
  },
  {
    title: "Total profile clicks",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Number of times a user clicks the on display name, user name, profile picture of paid tweets",
  },
  {
    title: "Engagement rate",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "It gives by formula total engagement per total impressions on paid tweet",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Count of how many times the video included in the paid tweet has been viewed",
  },
  {
    title: "Url link clicks",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Total number of times a user clicks on a URL link or URL preview card in a paid tweet",
  },
  {
    title: "Spends",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "The amount of money spent on promoting the paid tweet",
  },
  {
    title: "CPE",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Cost per engagement, the amount spend for total engagement on paid tweet",
  },
  {
    title: "CPM",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Cost per 1000 impressions, the amount spend for each 1000 impressions",
  },
  {
    title: "Cost per video views",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "The amount spend for video included in the paid tweet has been viewed",
  },

  // HALEON / CARD / SOCIAL / LINKEDIN / PAGE
  {
    title: "Page Views",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of all page views",
  },
  {
    title: "Page Reach",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of unique impressions",
  },
  {
    title: "Page Mentions",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of mentions of the organizational entity in a shares & comments across LinkedIn",
  },
  {
    title: "Page Clicks",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of clicks",
  },

  // HALEON / CARD / SOCIAL / LINKEDIN / AUDIENCE
  {
    title: "New Follower",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by the new followers in that given time period",
  },
  {
    title: "Total Follower",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by the total followers",
  },
  {
    title: "Organic Follower",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by the organic followers",
  },
  {
    title: "Paid Follower",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by the paid followers",
  },

  // HALEON / CARD / SOCIAL / LINKEDIN / POST / OVERALL
  {
    title: "Overall Post",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "The number of organic + promoted posts",
  },
  {
    title: "Impressions",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of impressions",
  },
  {
    title: "Reach",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of unique impressions",
  },
  {
    title: "Clicks",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of clicks",
  },
  {
    title: "Engagements",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of organic clicks, likes, comments, and shares over impressions",
  },

  // HALEON / CARD / SOCIAL / LINKEDIN / POST / ORAGNIC
  {
    title: "Organic Posts",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "The number of organic posts",
  },
  {
    title: "Impressions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of impressions",
  },
  {
    title: "Reach",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of unique impressions",
  },
  {
    title: "Clicks",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of clicks",
  },
  {
    title: "Likes",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of likes",
  },
  {
    title: "Total comments",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of comments",
  },
  {
    title: "Shares",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of shares",
  },
  {
    title: "Engagements",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of organic clicks, likes, comments, and shares over impressions",
  },
  {
    title: "Total Interactions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of number of organic clicks, likes, comments, and shares over impressions",
  },
  {
    title: "CTR",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Click through rate is the number of clicks divided  the number of impressions",
  },
  {
    title: "Engagement Rate",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Rate of interaction on the content. (likes + comments + shares) / Total number of followers * 100",
  },

  // HALEON / CARD / SOCIAL / LINKEDIN / POST / PAID
  {
    title: "Promoted posts",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "The number of promoted posts",
  },
  {
    title: "Impressions",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of impressions",
  },
  {
    title: "Reach",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of unique impressions",
  },
  {
    title: "Clicks",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of clicks",
  },
  {
    title: "Engagements",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of organic clicks, likes, comments, and shares over impressions",
  },
  {
    title: "Total Spends",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "The amount of spends",
  },
  {
    title: "CTR",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "CTR is the number of clicks that your ad receives divided by the number of times your ad is shown",
  },
  {
    title: "CPC",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Cost per click (CPC) is a metric for how much each click costs",
  },

  // HALEON / CARD / WEBSITE / OVERALL
  {
    title: "Sessions",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "overall",
    section: "overall",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // HALEON / CARD / WEBSITE / TRAFFIC / ORGANIC
  {
    title: "Sessions",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "traffic",
    section: "organic",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // HALEON / CARD / WEBSITE / TRAFFIC / CHANNEL WISE TRAFFIC
  {
    title: "Sessions",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "traffic",
    section: "channel_wise_traffic",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // HALEON / CARD / WEBSITE / BEHAVIOUR
  {
    title: "Sessions",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "behaviour",
    section: "overall",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // HALEON / CARD / WEBSITE / TECHNOLOGY / BROWSER USED
  {
    title: "Sessions",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "technology",
    section: "browser_used",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // HALEON / CARD / WEBSITE / TECHNOLOGY / DEVICE USED
  {
    title: "Sessions",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "technology",
    section: "device_used",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // HALEON / CARD / WEBSITE / TECHNOLOGY / TRAFFIC TYPE
  {
    title: "Sessions",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "technology",
    section: "device_traffic_type",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // HALEON / CARD / WEBSITE / LOCATION / COUNTRY
  {
    title: "Sessions",
    module: "website",
    platform: "location",
    section: "country",
    data: "The total number of sessions",
  },
  {
    title: "Users",
    module: "website",
    platform: "location",
    section: "country",
    data: "The total number of users for the requested time period",
  },
  {
    title: "New Users",
    module: "website",
    platform: "location",
    section: "country",
    data: "The number of sessions marked as a user's first sessions",
  },
  {
    title: "% New Sessions",
    module: "website",
    platform: "location",
    section: "country",
    data: "The percentage of sessions by users who had never visited the property before",
  },
  {
    title: "Bounce Rate",
    module: "website",
    platform: "location",
    section: "country",
    data: "Percentage of single-page session(session in which the person left the property from the first page)",
  },
  {
    title: "Avg. Session Duration",
    module: "website",
    platform: "location",
    section: "country",
    data: "The average duration (in seconds) of users' sessions",
  },
  {
    title: "Organic Search",
    module: "website",
    platform: "location",
    section: "country",
    data: "The number of organic searches happened in a session",
  },
  {
    title: "Page Views",
    module: "website",
    platform: "location",
    section: "country",
    data: "The total number of pageviews for the property",
  },
  {
    title: "Page Views per Session",
    module: "website",
    platform: "location",
    section: "country",
    data: "The average number of pages viewed during a session, including repeated views of a single page",
  },
  {
    title: "Goal Conversion Rate",
    module: "website",
    platform: "location",
    section: "country",
    data: "The percentage of sessions which resulted in a conversion to at least one of the goals",
  },

  // HALEON / CARD / WEBSITE / ECOM
  {
    title: "Transactions",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The total number of transactions",
  },
  {
    title: "Conversion Rate",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The average number of transactions in a session",
  },
  {
    title: "Revenue",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The total sale revenue (excluding shipping and tax) of the transaction",
  },
  {
    title: "Revenue / Transaction",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The average revenue of an ecommerce transaction",
  },
  {
    title: "Quantity",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "Total number of items purchased",
  },
  {
    title: "Unique Purchases",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The number of product sets purchased",
  },
  {
    title: "Items / Purchase",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "The average quantity of this item (or group of items) sold per purchase",
  },

  // HALEON / CARD / TV
  {
    title: "Sales Volume",
    module: "tv",
    platform: "tv_overall",
    section: "tv_overall",
    data: "Number of unit cases of the Product sold",
  },
  {
    title: "GRPs",
    module: "tv",
    platform: "tv_overall",
    section: "tv_overall",
    data: "Television Gross Rating Points. It measures the size of an audience reached by television campaigns",
  },
  {
    title: "Spends",
    module: "tv",
    platform: "tv_overall",
    section: "tv_overall",
    data: "Amount of money spent on TV advertising",
  },
  {
    title: "No of Campaigns",
    module: "tv",
    platform: "tv_overall",
    section: "tv_overall",
    data: "Number of Campaigns run",
  },

  // HALEON / CARD / SALES
  {
    title: "Sales Volume",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Product sales volume unit cases",
  },
  {
    title: "Sales Value",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Product sales value in Rupees",
  },
  {
    title: "GRPs",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Television Gross Rating Points. It measures the size of an audience reached by television campaigns",
  },
  {
    title: "Spends",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Amount of money spent by on advertising on TV",
  },
  {
    title: "No of Campaigns",
    module: "sales",
    platform: "sales_overall",
    section: "sales_overall",
    data: "Number of Campaigns run",
  },

  // HALEON / CARD / VIDEO / OVERALL / OVERALL
  {
    title: "No Of Videos",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "The total engagement (likes + comments ) of a video post",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Average Watch Time",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Average number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  // HALEON / CARD / VIDEO / OVERALL / OVERALL SINGLE
  {
    title: "Total Views",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "The total engagement (likes + comments ) of a video post",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Average Watched Time",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Average number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  // HALEON / CARD / VIDEO / FACEBOOK / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Total minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Average number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Avg Views Per Video Post",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "On average, the number of views received per video post",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  {
    title: "Total video complete views unique",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The number of people who viewed your videos for 95%, or more, of its length",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Complete Video Views",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The number of times your videos played for 95%, or more, or its length",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The number of times video has been played for at least 3 seconds",
  },

  // HALEON / CARD / VIDEO / FACEBOOK / OVERALL SINGLE
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Total minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Average number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "On average, the number of views received per video post",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  {
    title: "Total video complete views unique",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "The number of people who viewed your videos for 95%, or more, of its length",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Complete Video Views",
    module: "video",
    platform: "facebook",
    section: "overall single",
    data: "The number of times your videos played for 95%, or more, or its length",
  },
  // HALEON / CARD / VIDEO / FACEBOOK / ORGANIC
  {
    title: "Total Videos",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Total number of organic videos published in selected time period",
  },
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Total organic minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Average organic number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Rate of  organic views on the video.( organic views /  organic impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "On average, the number of organic views received per video post",
  },

  {
    title: "Total Video Complete Views Organic Unique",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The number of people who viewed your videos for 95%, or more, of its length by organic reach",
  },
  {
    title: "Total Video Views Organic",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The number of times video has been played for at least 3 seconds",
  },

  // HALEON / CARD / VIDEO / FACEBOOK /  ORGANIC SINGLE

  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Total organic minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Average organic number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Rate of  organic views on the video.( organic views /  organic impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "On average, the number of organic views received per video post",
  },
  {
    title: "Total video complete views organic unique",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "The number of people who viewed your videos for 95%, or more, of its length by organic reach",
  },
  {
    title: "Total Video Views Organic",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "The number of times video has been played for at least 3 seconds",
  },

  // HALEON / CARD / VIDEO / FACEBOOK / PAID
  {
    title: "Total Videos",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Total number of organic videos published in selected time period",
  },
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Total organic minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Average organic number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Rate of  organic views on the video.( organic views /  organic impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "On average, the number of organic views received per video post",
  },

  {
    title: "Total video complete views unique",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The number of people who viewed your videos for 95%, or more, of its length by organic reach",
  },
  {
    title: "Complete Video Views",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The number of times your videos played for 95%, or more, or its length",
  },
  {
    title: "Total Video Views Paid",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The number of times video has been played for at least 3 seconds",
  },

  // HALEON / CARD / VIDEO / FACEBOOK / PAID SINGLE
  {
    title: "Total Video Views in Minutes",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Total organic minutes watched by people",
  },
  {
    title: "Avg Time Viewed(in Mins)",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Average organic number of seconds watched by people (video watch time/no. of video plays)",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Rate of  organic views on the video.( organic views /  organic impression) * 100",
  },
  {
    title: "Avg views per video post",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "On average, the number of organic views received per video post",
  },

  {
    title: "Total video complete views unique",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The number of people who viewed your videos for 95%, or more, of its length by organic reach",
  },
  {
    title: "Total Video Views Paid",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Total Video Views",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Complete Video Views",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The number of times your videos played for 95%, or more, or its length",
  },

  // HALEON / CARD / VIDEO / INSTAGRAM / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Views",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Reach",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of unique Instagram accounts that have seen the video",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Engagements",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The total engagement (likes + comments +saved) of a video post",
  },
  {
    title: "Engagement Rate",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Rate of interaction on the content. (likes + comments + saved) / Total number of views * 100",
  },
  {
    title: "Saved",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of unique Instagram accounts that have saved the video",
  },

  // HALEON / CARD / VIDEO / INSTAGRAM / OVERALL SING:E
  {
    title: "Views",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The number of times video has been played for at least 3 seconds",
  },
  {
    title: "Reach",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Total number of unique Instagram accounts that have seen the video",
  },
  {
    title: "View Through Rate",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Engagements",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The total engagement (likes + comments +saved) of a video post",
  },
  {
    title: "Engagement Rate",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Rate of interaction on the content. (likes + comments + saved) / Total number of views * 100",
  },
  {
    title: "Saved",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Total number of unique Instagram accounts that have saved the video",
  },
  // HALEON / CARD / VIDEO / YOUTUBE / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that a video was viewed",
  },
  {
    title: "Total Subscribers",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Total lifetime subscriber",
  },
  {
    title: "Subscribers Gained",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users subscribed to a channel",
  },
  {
    title: "Subscribers Lost",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users unsubscribed from a channel",
  },
  {
    title: "Average View Duration",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Average minutes watched by people (mm:hh format)",
  },
  {
    title: "Average View Percentage",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The average percentage of a video watched during a video playback",
  },
  {
    title: "Engagement Rate",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Rate of interaction on the content. (likes + comments + shares) / Total number of views * 100",
  },
  {
    title: "Likes",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users indicated that they liked a video by giving it a positive rating",
  },
  {
    title: "Comments",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users commented on a video",
  },
  {
    title: "Shares",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users shared a video through the Share button. ",
  },
  {
    title: "Dislikes",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that users indicated that they disliked a video by giving it a positive rating",
  },
  {
    title: "Red Views",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The number of times that a video was viewed by YouTube Premium members",
  },
  {
    title: "Estimated Minutes Watched",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Total minutes watched by people",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  {
    title: "Resonance Strength",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Score based on recent released, popularity and video/channel KPI's",
  },
  // HALEON / CARD / VIDEO / YOUTUBE / OVERALL SINGLE

  {
    title: "Total Views",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that a video was viewed",
  },
  {
    title: "Total Subscribers",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Total lifetime subscriber",
  },
  {
    title: "Subscribers Gained",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users subscribed to a channel",
  },
  {
    title: "Subscribers Lost",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users unsubscribed from a channel",
  },
  {
    title: "Average View Duration",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Average minutes watched by people (mm:hh format)",
  },
  {
    title: "Average View Percentage",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The average percentage of a video watched during a video playback",
  },
  {
    title: "Engagement Rate",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Rate of interaction on the content. (likes + comments + shares) / Total number of views * 100",
  },
  {
    title: "Likes",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users indicated that they liked a video by giving it a positive rating",
  },
  {
    title: "Comments",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users commented on a video",
  },
  {
    title: "Shares",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users shared a video through the Share button. ",
  },
  {
    title: "Dislikes",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that users indicated that they disliked a video by giving it a positive rating",
  },
  {
    title: "Red Views",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The number of times that a video was viewed by YouTube Premium members",
  },
  {
    title: "Estimated Minutes Watched",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Total minutes watched by people",
  },
  {
    title: "Viral Grade",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Rank based on recent released, popularity and video/channel KPI's",
  },
  {
    title: "Resonance Strength",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Score based on recent released, popularity and video/channel KPI's",
  },
  // HALEON / CARD / VIDEO / YOUTUBE / PAID
  {
    title: "Total Videos",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "View Rate",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Total Number of views of videos",
  },
  // HALEON / CARD / VIDEO / YOUTUBE / PAID SINGLE
  {
    title: "View Rate",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Total Number of views of videos",
  },
  // HALEON / CARD / VIDEO / TWITTER / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // HALEON / CARD / VIDEO / TWITTER / OVERALL SINGLE

  {
    title: "Total Views",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // HALEON / CARD / VIDEO / TWITTER / ORGANIC
  {
    title: "Total Videos",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total views",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // HALEON / CARD / VIDEO / TWITTER / ORGANIC SINGLE
  {
    title: "Total views",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // HALEON / CARD / VIDEO / TWITTER / PAID
  {
    title: "Total Videos",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // HALEON / CARD / VIDEO / TWITTER / PAID SINGLE

  {
    title: "Total Views",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Total Number of views of videos",
  },
  {
    title: "Video Through Rate",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "The total engagement (likes + reply+retweet ) of a video post",
  },
  // HALEON / CARD / VIDEO / LINKEDIN / OVERALL
  {
    title: "Total Videos",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Total Number of views of videos",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Video Completion Rate",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Percentage of times the video played to the end.(Complete video views ∕ Video starts)",
  },
  {
    title: "Minutes Watched",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Total minutes watched by people",
  },
  // HALEON / CARD / VIDEO / LINKEDIN / OVERALL SINGLE
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Total Number of views of videos",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Video Completion Rate",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Percentage of times the video played to the end.(Complete video views ∕ Video starts)",
  },
  {
    title: "Minutes Watched",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Total minutes watched by people",
  },
  // HALEON / CARD / VIDEO / LINKEDIN / ORGANIC
  {
    title: "Total Videos",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Total Number of views of videos",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Minutes Watched",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Total minutes watched by people",
  },

  // HALEON / CARD / VIDEO / LINKEDIN / ORGANIC SINGLE
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Total Number of views of videos",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Minutes Watched",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Total minutes watched by people",
  },

  // HALEON / CARD / VIDEO / LINKEDIN / PAID
  {
    title: "Total Videos",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Total number of videos published in selected time period",
  },
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Total Number of views of videos",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Video Completion Rate",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Percentage of times the video played to the end.(Complete video views ∕ Video starts)",
  },

  // HALEON / CARD / VIDEO / LINKEDIN / PAID SINGLE
  {
    title: "Total Views",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Total Number of views of videos",
  },
  {
    title: "Video View Rate",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Rate of views on the video.(views / impression) * 100",
  },
  {
    title: "Total Engagements",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The total engagement (likes + comments+Shares) of a video post",
  },
  {
    title: "Video Completion Rate",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Percentage of times the video played to the end.(Complete video views ∕ Video starts)",
  },
];

export const HaleonSectionDefinations = [
  // HALEON / SECTION / SOCIAL / FACEBOOK / PAGE
  {
    title: "Page Views",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "A daily trend of the number of times a page has been viewed",
  },
  {
    title: "Page Growth",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of likes or dislikes a page has recieved on daily basis",
  },
  {
    title: "Current Period Source",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of likes or dislikes from the originating source for the current time period",
  },
  {
    title: "previous Period Source",
    module: "social",
    platform: "facebook",
    section: "page",
    data: "The number of likes or dislikes from the originating source for the previous time period",
  },

  // HALEON / SECTION / SOCIAL / FACEBOOK / AUDIENCE
  {
    title: "Audience Distribution: PTAT",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The age and gender across which a page has people talking about the page (any interaction)",
  },
  {
    title: "Audience Distribution: Followers",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The age and gender across which the followers of page are distributed",
  },
  {
    title: "By Country",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The number of followers distributed across multiple countries",
  },
  {
    title: "By City",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The number of followers distributed across multiple cities",
  },
  {
    title: "Audience Activity Trend",
    module: "social",
    platform: "facebook",
    section: "audience",
    data: "The time of the day during which your followers are most active",
  },

  // HALEON / SECTION / SOCIAL / FACEBOOK / POST / OVERALL
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Content Analysis",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The distribution of interactions on overall post across the number of posts on various content type ",
  },
  {
    title: "Reach By Content Type",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The distribution of reach across various content type on overall posts",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of number of impressions received on overall posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of number of videos, video views (total views) and video views (10 seconds view) on overall posts",
  },
  {
    title: "Post engagement",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of number of overall post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Post action sentiment",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of times people have reacted positively or negatively to Page's overall posts",
  },
  {
    title: "Reaction Sentiment",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The number of positive of negative reactions people have given to Page's overall posts",
  },
  {
    title: "Comment sentiment",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "The sentiment of comments derived from an AI based model for overall posts",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "facebook",
    section: "overall",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / FACEBOOK / POST / ORGANIC
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Content Analysis",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The distribution of interactions on overall post across the number of posts on various content type ",
  },
  {
    title: "Reach By Content Type",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The distribution of reach across various content type on overall posts",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Daily trend of number of impressions received on overall posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Daily trend of number of videos, video views (total views) and video views (10 seconds view) on overall posts",
  },
  {
    title: "Post engagement",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Daily trend of number of overall post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Post action sentiment",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of times people have reacted positively or negatively to Page's overall posts",
  },
  {
    title: "Reaction Sentiment",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The number of positive of negative reactions people have given to Page's overall posts",
  },
  {
    title: "Comment sentiment",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "The sentiment of comments derived from an AI based model for overall posts",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "facebook",
    section: "organic",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / FACEBOOK / POST / PAID
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of number of impressions received on promoted posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of number of videos, video views and video views 10seconds on promoted posts",
  },
  {
    title: "Post Engagement",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of number of promoted post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },
  {
    title: "compare",
    module: "social",
    platform: "facebook",
    section: "paid",
    data: "When pure ads are run data for compare section will not be available if the ad was not run in that time duration",
  },

  // HALEON / SECTION / SOCIAL / INSTAGRAM / PAGE
  {
    title: "Profile Views",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Daily trend of number of followers on IG user'd profile ",
  },
  {
    title: "Followers Growth",
    module: "social",
    platform: "instagram",
    section: "page",
    data: "Daily trend of number of views on IG user'd profile. (Follower count will not be available for date range greatedr thatn 30 days from the 1st data pull)",
  },

  // HALEON / SECTION / SOCIAL / INSTAGRAM / AUDIENCE
  {
    title: "Audience Distribution Followers",
    module: "social",
    platform: "instagram",
    section: "audience",
    data: "Total number of followers distributed across age & gender",
  },
  {
    title: "Audience Distribution City",
    module: "social",
    platform: "instagram",
    section: "audience",
    data: "Total number of followers distributed across multiple cities",
  },
  {
    title: "Audience Distribution Country",
    module: "social",
    platform: "instagram",
    section: "audience",
    data: "Total number of followers distributed across multiple countries",
  },
  {
    title: "Audience Activity Trend",
    module: "social",
    platform: "instagram",
    section: "audience",
    data: "The time of the day during which your followers are most active",
  },

  // HALEON / SECTION / SOCIAL / INSTAGRAM / POST / OVERALL
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Content Analysis",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The distribution of interactions on overall post across the number of posts on various content type ",
  },
  {
    title: "Reach By Content Type",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The distribution of reach across various content type on overall posts",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Daily trend of number of impressions received on overall posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Daily trend of number of videos, video views on overall posts",
  },
  {
    title: "Post engagement",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Daily trend of number of overall post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Comment analysis",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "The sentiment of comments derived from an AI based model for overall posts",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "instagram",
    section: "overall",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / INSTAGRAM / POST / ORGANIC
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Content Analysis",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The distribution of interactions on organic post across the number of posts on various content type ",
  },
  {
    title: "Reach By Content Type",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The distribution of reach across various content type on organic posts",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Daily trend of number of impressions received on organic posts for current & previous period",
  },
  {
    title: "Video Views",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Daily trend of number of videos, video views and on organic posts",
  },
  {
    title: "Post engagement",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Daily trend of number of organic post engagements (clicks, shares, comments, reactions, impressions)",
  },
  {
    title: "Comment analysis",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "The sentiment of comments derived from an AI based model for organic posts",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "instagram",
    section: "organic",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / INSTAGRAM / POST / PAID
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "The hashtags used in the posts receiving the most number of impressions",
  },
  {
    title: "Post Impression",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "Daily trend of number of impressions received on promoted posts for current & previous period",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "instagram",
    section: "paid",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / LINKEDIN / PAGE
  {
    title: "Page views",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Daily trend of number of all pages views",
  },
  {
    title: "Reach",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Daily trend of number of unique impressions",
  },
  {
    title: "Career Page View",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of carrer page views by device type",
  },
  {
    title: "Jobs Page View",
    module: "social",
    platform: "linkedin",
    section: "page",
    data: "Number of job page views by device type",
  },

  // HALEON / SECTION / SOCIAL / LINKEDIN / AUDIENCE
  {
    title: "New Followers Trend line",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Daily trend of count of followers",
  },
  {
    title: "Audience Distribution Likes",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by age & gender of the Page likes",
  },
  {
    title: "Audience Distribution Followers",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by age & gender of the followers",
  },
  {
    title: "Audience Distribution By City",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by city of the followers",
  },
  {
    title: "Audience Distribution By Country",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by country of the followers",
  },
  {
    title: "Industry and Designation",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by industry & designation of the followers",
  },
  {
    title: "Company Sizewise Distribution",
    module: "social",
    platform: "linkedin",
    section: "audience",
    data: "Counts by staff count range of the current organizations of the followers",
  },

  // HALEON / SECTION / SOCIAL / LINKEDIN / POST / OVERALL
  {
    title: "Impressions & Reach",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Daily trend of number of impressions & number of unique impressions",
  },
  {
    title: "Object Type",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of object type",
  },
  {
    title: "Interactions",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of organic likes, comments, and shares ",
  },
  {
    title: "Total Interactions",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Daily trend of number of organic likes, comments, and shares ",
  },
  {
    title: "Reactions",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "The type of reaction",
  },
  {
    title: "Comment Type",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Number of primary and secondary comment",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "linkedin",
    section: "overall",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / LINKEDIN / POST / ORGANIC
  {
    title: "Impressions & Reach",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of number of impressions & number of unique impressions",
  },
  {
    title: "Object Type",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of object type",
  },
  {
    title: "Interactions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of organic likes, comments, and shares ",
  },
  {
    title: "Total Interactions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of number of organic likes, comments, and shares ",
  },
  {
    title: "Reactions",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "The type of reaction",
  },
  {
    title: "Comment Type",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Number of primary and secondary comment",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "linkedin",
    section: "organic",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / LINKEDIN / POST / PAID
  {
    title: "Impressions & Reach",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Daily trend of number of impressions & number of unique impressions",
  },
  {
    title: "Object Type",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of object type",
  },
  {
    title: "Interactions",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of organic likes, comments, and shares ",
  },
  {
    title: "Total Interactions",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Daily trend of number of organic likes, comments, and shares ",
  },
  {
    title: "Reactions",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "The type of reaction",
  },
  {
    title: "Comment Type",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Number of primary and secondary comment",
  },
  {
    title: "Top 5 Post",
    module: "social",
    platform: "linkedin",
    section: "paid",
    data: "Detailed view of post KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / TWITTER / PAGE
  {
    title: "User Profile Clicks / Mentions",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Daily trendline of user profile clicks and mentions with current and previous period",
  },
  {
    title: "Followers Growth",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Daily trendline of followers gained and lost in current period",
  },
  {
    title: "Word Cloud",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Most repetitive words with frequncy and bifurcation of overall, postive, negative and neutral words",
  },
  {
    title: "Mentions sentiment",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "Percentage distribution of positive,negative and neutral tweets",
  },
  {
    title: "Lists",
    module: "social",
    platform: "twitter",
    section: "page",
    data: "User can join lists created by others, or they can create lists of other accounts by group, topic",
  },

  // HALEON / SECTION / SOCIAL / TWITTER / AUDIENCE
  {
    title: "By Age",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "It includes age wise distribution of followers and impressions",
  },
  {
    title: "By Gender",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "It includes gender wise distribution of followers and impressions",
  },
  {
    title: "By Country",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "It includes city level distribution of followers and impressions",
  },
  {
    title: "Twitter Top Mentions",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "User's top tweet mentions in descending order on the basis of tweet enagagement",
  },
  {
    title: "Twitter Top Followers",
    module: "social",
    platform: "twitter",
    section: "audience",
    data: "User's top followers in descending order on the basis of follower's followers",
  },

  // HALEON / SECTION / SOCIAL / TWITTER / POST / OVERALL
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "The hashtags used in the overall posts receiving the most number of impressions",
  },
  {
    title: "Tweet Impressions",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of  how many times the Tweet has been viewed with respect to current and previous period",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of total video posted and video views in current period",
  },
  {
    title: "Video Views (Quartiles)",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Gives the quartile wise views on total number of videos posted in user's timeline",
  },
  {
    title: "Poll Votes",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of total number of poll tweets and poll votes",
  },
  {
    title: "Tweet Engagement",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of total engagement on tweet and engagement typewise trend in current period",
  },
  {
    title: "Campaign Effectiveness",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Organic vs paid impressions and engagement trend showing performance of paid vs orgnaic tweet",
  },
  {
    title: "Tweet Activity",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Organic, paid and combined tweet activities by day of the week and hour wise",
  },
  {
    title: "Engagement vs Impression",
    module: "social",
    platform: "twitter",
    section: "overall",
    data: "Tweet performance represented by enagagment vs impressions or tweet length of tweet",
  },

  // HALEON / SECTION / SOCIAL / TWITTER / POST / ORGANIC
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "The hashtags used in the orgnaic posts receiving the most number of impressions",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of number of times the organic tweet has been viewed",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of total organic video posted and video views in current period",
  },
  {
    title: "Video Views (Quartiles)",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Gives the quartile wise views on total orgnaic videos posted in user's timeline",
  },
  {
    title: "Poll Votes",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of total number of orgnaic poll tweets and poll votes",
  },
  {
    title: "Tweet Engagement",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of total engagement on orgnaic tweet and engagement typewise trend in current period",
  },
  {
    title: "User profile clicks",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Daily trendline of user profile clicks from organic contexts",
  },
  {
    title: "Top 5 Tweets",
    module: "social",
    platform: "twitter",
    section: "organic",
    data: "Detailed view of organic tweet KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / SOCIAL / TWITTER / POST / PAID
  {
    title: "Trending Hashtags",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "The hashtags used in the paid posts receiving the most number of impressions",
  },
  {
    title: "Post Impressions",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of number of times the paid tweet has been viewed",
  },
  {
    title: "Video views",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of total paid video posted and video views in current period",
  },
  {
    title: "Poll Votes",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of total number of paid poll tweets and poll votes",
  },
  {
    title: "Tweet Engagement",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of total engagement on paid tweet and engagement typewise trend in current period",
  },
  {
    title: "User profile clicks",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Daily trendline of user profile clicks from paid contexts",
  },
  {
    title: "Top 5 Tweets",
    module: "social",
    platform: "twitter",
    section: "paid",
    data: "Detailed view of paid tweet KPI's on top posts based on impressions & comparison between them",
  },

  // HALEON / SECTION / CAMPAIGN / OVERALL / OVERALL /
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Platform's campaign objective wise distribution representing number of campaigns",
  },
  {
    title: "Performance By KPI",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Comparative view of platform KPI's daily trend",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "overall",
    section: "overall",
    data: "Detailed view of each platform's campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // HALEON / SECTION / CAMPAIGN / OVERALL / VIDEO
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "overall",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video adss",
  },

  // HALEON / SECTION / CAMPAIGN / GOOGLE / OVERALL
  {
    title: "Platform Wise Distribution",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "Google ad network wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "It includes gender wise distribution of KPI's performance",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "It includes device wise distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Age",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "It includes age wise distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "It includes country and city level targeting distribution of KPI's performance",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "google",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // HALEON / SECTION / CAMPAIGN / GOOGLE / VIDEO
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "google",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },

  // HALEON / SECTION / CAMPAIGN / FACEBOOK / OVERALL
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Campaign Objective wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience distribution - Meta level By Gender",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "It includes age and gender wise distribution of KPI performance at Meta level",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "It Includes device level targeting distribution of KPI's performance",
  },
  {
    title: "Audience distribution - Meta level By Country",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "It includes country and city level targeting distribution of KPI performance at Meta level",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "facebook",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // HALEON / SECTION / CAMPAIGN / FACEBOOK / VIDEO
  {
    title: "Video Views Analysis",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },
  {
    title: "Audience distribution - Meta level By Gender",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "It includes age and gender wise distribution of KPI performance at Meta level",
  },

  {
    title: "Audience distribution - Meta level By Country",
    module: "campaign",
    platform: "facebook",
    section: "video",
    data: "It includes country and city level targeting distribution of KPI performance at Meta level",
  },

  // HALEON / SECTION / CAMPAIGN / INSTAGRAM / OVERALL
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Campaign Objective wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience distribution - Meta level By Gender",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "It includes age and gender wise distribution of KPI performance at Meta level",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "It Includes device level targeting distribution of KPI's performance",
  },
  {
    title: "Audience distribution - Meta level By Country",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "It includes country and state level targeting distribution of KPI performance at Meta level",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "instagram",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // HALEON / SECTION / CAMPAIGN / INSTAGRAM / VIDEO
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },
  {
    title: "Audience distribution - Meta level By Gender",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "It includes age and gender wise distribution of KPI performance at Meta level",
  },
  {
    title: "Audience distribution - Meta level By Country",
    module: "campaign",
    platform: "instagram",
    section: "video",
    data: "It includes country level targeting distribution of KPI performance at Meta level",
  },

  // HALEON / SECTION / CAMPAIGN / TWITTER / OVERALL
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Campaign Objective wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "It includes gender wise distribution of KPI's performance",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "It Includes device level targeting distribution of KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "It includes country level targeting distribution of KPI's performance",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "twitter",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // HALEON / SECTION / CAMPAIGN / TWITTER / VIDEO
  {
    title: "Audience Distribution By Gender",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "It includes gender wise distribution of video KPI's performance",
  },
  {
    title: "Device Distribution",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "It includes gender wise distribution of video KPI's performance",
  },
  {
    title: "Audience Distribution By Country",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "It includes country level targeting distribution of video KPI's performance",
  },
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "twitter",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },
  // HALEON / SECTION / CAMPAIGN / LINKEDIN / OVERALL
  {
    title: "Objective Wise Distribution",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Campaign Objective wise number of campaigns distribution with daily trend of KPI's",
  },
  {
    title: "Audience Distribution",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "It includes country and city level targeting distribution of KPI's performance",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "linkedin",
    section: "overall",
    data: "Detailed view of campaign, ad agroup and ads with respective KPI metrics performance",
  },

  // HALEON / SECTION / CAMPAIGN / LINKEDIN / VIDEO
  {
    title: "Video views analysis",
    module: "campaign",
    platform: "linkedin",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },

  // HALEON / SECTION /  CAMPAIGN / DV360 / OVERALL
  {
    title: "Campaign Type Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "Campaign type wise number of campaigns such as video, display and audio with daily trend of KPI's",
  },
  {
    title: "Device Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It Includes device type and operating system level targeting distribution of KPI's performance.",
  },
  {
    title: "Content Position Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It Includes ad content position wise distribution with respect to KPI's performance.",
  },
  {
    title: "App/Url Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It gives top 10 app/urls performance with respect to KPI metrics selected.",
  },
  {
    title: "App/Url Wise Trend",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It includes daily trendline of top 10 app/urls with respect to KPI metrics selected.",
  },
  {
    title: "Audience Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "It includes country, state and city level targeting with respect to KPI's performance.",
  },
  {
    title: "Summary",
    module: "campaign",
    platform: "Dv360",
    section: "overall",
    data: "Detailed view of campaign, line items and creative with respective KPI metrics performance",
  },

  // HALEON / SECTION /  CAMPAIGN / DV360 / VIDEO

  {
    title: "Video views",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Funnel view analysis of impressions to quadrant views of video ads",
  },
  {
    title: "Trend analysis",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of views received on video campaigns filtered by Video content duration bucket",
  },
  {
    title: "KPI Trend analysis",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of KPI's ",
  },
  {
    title: "Content position wise distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "The distribution of Content position format filterd by views type",
  },
  {
    title: "App/Url Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Top 10 App/Url filtered by views type",
  },
  {
    title: "Device Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "The distribution of Complete views by device and os",
  },
  {
    title: "Audience Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "KPI Metrics Performance across Country, State & City",
  },
  {
    title: "Ad Type Wise Distribution",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "The distribution of Ad Type filterd by views type",
  },
  {
    title: "Performance By Ad Type",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of Ad Type of video campaigns filtered by views type",
  },
  {
    title: "VTR Trend Analysis",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of VTR filtered by Ad type",
  },
  {
    title: "YouTube Ad-Type Wise Benchmarking At Ad-Group Level",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Daily trend of KPI metrics with comparing benchmarking metrics filtered by Ad type and KPI Metrics",
  },
  {
    title: "Youtube Ad-type Wise Demographic",
    module: "campaign",
    platform: "Dv360",
    section: "video",
    data: "Age-Gender wise Views distribution filtered by views type",
  },

  // HALEON / SECTION / VIDEO / OVERALL / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Channel Summary Table",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Performance view of each brand with respective video KPI metrics",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "overall",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / OVERALL / OVERALL SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Channel Summary Table",
    module: "video",
    platform: "overall",
    section: "overall single",
    data: "Performance view of each brand with respective video KPI metrics",
  },

  // HALEON / SECTION / VIDEO / FACEBOOK / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Progressive view of video given by views distribution format,Daily trend of views received on videos",
  },
  {
    title: "Playing Behaviour Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Distribution and Daily trend of views by playing behaviour type, filter by views distribution format",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of views and unique views filter by views distribution format",
  },
  {
    title: "View Publish Trend",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Sentiment",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The number of positive of negative reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Audience Distribution By Gender",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Total minutes watched by Top Audiences age and gender",
  },
  {
    title: "Audience Distribution By Country",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Total minutes watched by Top Audiences country and city",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / FACEBOOK / SINGLE ORAGNIC
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Progressive view of video given by views distribution format,Daily trend of views received on videos",
  },
  {
    title: "Playing Behaviour Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Distribution and Daily trend of views by playing behaviour type, filter by views distribution format",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Daily trend of views and unique views filter by views distribution format",
  },
  {
    title: "View Publish Trend",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Sentiment",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The number of positive of negative reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Audience Distribution By Gender",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Total minutes watched by Top Audiences age and gender",
  },
  {
    title: "Audience Distribution By Country",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Total minutes watched by Top Audiences country and city",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Video Retention Analysis",
    module: "video",
    platform: "facebook",
    section: "single overall",
    data: "The number of times your video played at each interval as a percentage of all views",
  },

  // HALEON / SECTION / VIDEO / FACEBOOK / ORGANIC
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The hashtags used in the organic video posts receiving the most number of organic views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "The distribution of organic views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Progressive view of video given by views distribution format,Daily trend of organic views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Daily trend of organic views and unique views filter by views distribution format",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "organic",
    data: "Detailed view of video KPI's on top videos based on organic views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / FACEBOOK / ORGANIC SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "The hashtags used in the organic video posts receiving the most number of organic views",
  },

  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Progressive view of video given by views distribution format,Daily trend of organic views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "organic single",
    data: "Daily trend of organic views and unique views filter by views distribution format",
  },

  // HALEON / SECTION / VIDEO / FACEBOOK / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The hashtags used in the organic video posts receiving the most number of paid views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The distribution of paid views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Progressive view of video given by views distribution format,Daily trend of paid views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of paid views and unique views filter by views distribution format",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on paid views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / FACEBOOK / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The hashtags used in the organic video posts receiving the most number of paid views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The distribution of paid views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Progressive view of video given by views distribution format,Daily trend of paid views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Daily trend of paid views and unique views filter by views distribution format",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Detailed view of video KPI's on top videos based on paid views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / FACEBOOK / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The hashtags used in the organic video posts receiving the most number of paid views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "The distribution of paid views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Progressive view of video given by views distribution format,Daily trend of paid views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Daily trend of paid views and unique views filter by views distribution format",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "facebook",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on paid views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / FACEBOOK / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "The hashtags used in the organic video posts receiving the most number of paid views",
  },

  {
    title: "View Trend Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Progressive view of video given by views distribution format,Daily trend of paid views received on videos",
  },
  {
    title: "Unique View Analysis",
    module: "video",
    platform: "facebook",
    section: "paid single",
    data: "Daily trend of paid views and unique views filter by views distribution format",
  },

  // HALEON / SECTION / VIDEO / INSTAGRAM / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },
  {
    title: "Total Videos",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of videos published in selected time period",
  },

  {
    title: "Views",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The number of times video has been played for at least 3 seconds",
  },

  {
    title: "Reach",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of unique Instagram accounts that have seen the video",
  },

  {
    title: "View Through Rate",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Rate of views on the video.(views /  impression) * 100",
  },

  {
    title: "Engagement",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "The total engagement (likes + comments +saved) of a video post",
  },

  {
    title: "Engagement Rate",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Rate of interaction on the content. (likes + comments + saved) / Total number of views * 100",
  },

  {
    title: "Saved",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Total number of unique Instagram accounts that have saved the video",
  },

  {
    title: "View Analysis",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Funnel view analysis of impressions to engagement of video ",
  },

  {
    title: "Top 5 Videos",
    module: "video",
    platform: "instagram",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / INSTAGRAM / OVERALL SING:E
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },

  {
    title: "Views",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The number of times video has been played for at least 3 seconds",
  },

  {
    title: "Reach",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Total number of unique Instagram accounts that have seen the video",
  },

  {
    title: "View Through Rate",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Rate of views on the video.(views /  impression) * 100",
  },

  {
    title: "Engagement",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "The total engagement (likes + comments +saved) of a video post",
  },

  {
    title: "Engagement Rate",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Rate of interaction on the content. (likes + comments + saved) / Total number of views * 100",
  },

  {
    title: "Saved",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Total number of unique Instagram accounts that have saved the video",
  },

  {
    title: "View Analysis",
    module: "video",
    platform: "instagram",
    section: "overall single",
    data: "Funnel view analysis of impressions to engagement of video ",
  },

  // HALEON / SECTION / VIDEO / YOUTUBE / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },

  {
    title: "Video Length Analysis",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  {
    title: "View Trend Analysis",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Traffic Analysis",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Video Sentiment",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Audience Distribution By Gender",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Audience Distribution By Country",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Device Distribution",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Channel Summary",
    module: "video",
    platform: "youtube",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "youtube",
    section: "organic",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / YOUTUBE / OVERALL SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Traffic Analysis",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Video Sentiment",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Audience Distribution By Gender",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Audience Distribution By Country",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Device Distribution",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  {
    title: "Channel Summary",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  {
    title: "Video Retention Analysis",
    module: "video",
    platform: "youtube",
    section: "overall single",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / YOUTUBE / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Bucket wise Funnel",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Audience Distribution by Country",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The number of views distributed across multiple countries",
  },
  {
    title: "View of Device",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The distribution of views by device",
  },
  {
    title: "Published Time Views analysis",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Channel Summary",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Performance view of each brand with respective video KPI metrics",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "youtube",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / YOUTUBE / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "The hashtags used in the organic video posts receiving the most number of views",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Bucket wise Funnel",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Audience Distribution by Country",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "The number of views distributed across multiple countries",
  },
  {
    title: "View of Device",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "The distribution of views by device",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Channel Summary",
    module: "video",
    platform: "youtube",
    section: "paid single",
    data: "Performance view of each brand with respective video KPI metrics",
  },
  // HALEON / SECTION / VIDEO / TWITTER / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "The sentiment of replys derived from an AI based model for videos",
  },
  {
    title: "Top 5 Tweets",
    module: "video",
    platform: "twitter",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  // HALEON / SECTION / VIDEO / TWITTER / OVERALL SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "overall single",
    data: "The sentiment of replys derived from an AI based model for videos",
  },

  // HALEON / SECTION / VIDEO / TWITTER / ORGANIC
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "The sentiment of replys derived from an AI based model for videos",
  },
  {
    title: "Top 5 Tweets",
    module: "video",
    platform: "twitter",
    section: "organic",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  // HALEON / SECTION / VIDEO / TWITTER / ORAGNIC SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "organic single",
    data: "The sentiment of replys derived from an AI based model for videos",
  },

  // HALEON / SECTION / VIDEO / TWITTER / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "The sentiment of replys derived from an AI based model for videos",
  },
  {
    title: "Top 5 Tweets",
    module: "video",
    platform: "twitter",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },
  // HALEON / SECTION / VIDEO / TWITTER / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "The distribution of views format and number of videos by video length type",
  },
  {
    title: "View Trend Analysis",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Tweet Sentiment",
    module: "video",
    platform: "twitter",
    section: "paid single",
    data: "The sentiment of replys derived from an AI based model for videos",
  },

  // HALEON / SECTION / VIDEO / LINKEDIN / OVERALL
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "linkedin",
    section: "overall",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / LINKEDIN / OVERALL SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "overall single",
    data: "The sentiment of comments derived from an AI based model for videos",
  },

  // HALEON / SECTION / VIDEO / LINKEDIN / ORGANIC
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "linkedin",
    section: "organic",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / LINKEDIN / ORGANIC SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },

  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "organic single",
    data: "The sentiment of comments derived from an AI based model for videos",
  },

  // HALEON / SECTION / VIDEO / LINKEDIN / PAID
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Length Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The distribution of engagement and number of videos by video length type",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  {
    title: "Top 5 Videos",
    module: "video",
    platform: "linkedin",
    section: "paid",
    data: "Detailed view of video KPI's on top videos based on views & comparison between them",
  },

  // HALEON / SECTION / VIDEO / LINKEDIN / PAID SINGLE
  {
    title: "Trending Hashtags",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The hashtags used in the video posts receiving the most number of views",
  },
  {
    title: "Video Trend Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Daily trend of views received on videos",
  },
  {
    title: "Published Time Views Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The time of day when videos were published and gained the most views",
  },
  {
    title: "View Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Funnel view analysis of start to complete video views",
  },
  {
    title: "Video Rate Analysis",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "Daily trend of video KPI's",
  },
  {
    title: "Video Engagement",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The distribution of reactions people have given to video",
  },
  {
    title: "Comment Sentiment",
    module: "video",
    platform: "linkedin",
    section: "paid single",
    data: "The sentiment of comments derived from an AI based model for videos",
  },
  // HALEON / SECTION / WEBSITE / TRAFFIC / CHANNEL WISE TRAFFIC
  {
    title: "Summary",
    module: "website",
    platform: "traffic",
    section: "channel",
    data: "Analyzing the performance of each traffic channel with corresponding KPI metrics",
  },
  // HALEON / SECTION / WEBSITE / TRAFFIC / MONTHLY
  {
    title: "Monthly Traffic Comparison",
    module: "website",
    platform: "traffic",
    section: "monthly",
    data: "Comparing the number of sessions and new users on a monthly basis by traffic channel",
  },
  {
    title: "Traffic Source",
    module: "website",
    platform: "traffic",
    section: "monthly",
    data: "Analyzing source-specific sessions for current and previous periodsby by traffic channel",
  },
  // HALEON / SECTION / WEBSITE / TECHNOLOGY / DEVICE TRAFFIC
  {
    title: "Summary",
    module: "website",
    platform: "technology",
    section: "device traffic",
    data: "Analyzing the performance of device and traffic source type with corresponding KPI metrics",
  },
  // HALEON / SECTION / WEBSITE / LOCATION / CITY
  {
    title: "Summary",
    module: "website",
    platform: "location",
    section: "city",
    data: "Analyzing the performance of city with corresponding KPI metrics",
  },
  // HALEON / SECTION / WEBSITE / LOCATION / OVERALL
  {
    title: "Summary",
    module: "website",
    platform: "landing page",
    section: "overall",
    data: "Analyzing the performance of landing page with corresponding KPI metrics",
  },
  // HALEON / SECTION / WEBSITE / EVENTS / OVERALL
  {
    title: "Summary",
    module: "website",
    platform: "events",
    section: "overall",
    data: "Analyzing the performance of events and traffic source type with corresponding KPI metrics",
  },
  // HALEON / SECTION / WEBSITE / ECOM / OVERALL
  {
    title: "Summary",
    module: "website",
    platform: "ecom",
    section: "overall",
    data: "Analyzing the performance of products with corresponding KPI metrics",
  },
  // HALEON / SECTION / SALES / OVERALL / OVERALL
  {
    title: "Map",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Distribution of Sales across country divided into zones & states, pin color determines growth/degrowth compared to previous period",
  },
  {
    title: "Performance Trend",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Analyze trends and relationships between Sales Value, Sales Volume, GRP and Spends",
  },
  {
    title: "Top / Bottom Performing Markets",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Regionwise performance of GRPs vs Sales Volume",
  },
  {
    title: "Summary",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "State wise performance with option to sort by available metrics in ascending or desending order",
  },
  {
    title: "Brandwise Performance",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Identify the Sales Volume hirerachy between Brands,Zones & States",
  },
  {
    title: "Compare Table",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "Comparison view between brands for KPI's between brands (up to 3 brands at a time)",
  },
  {
    title: "Impressions",
    module: "sales",
    platform: "overall",
    section: "overall",
    data: "The number of times an advertisement is viewed by an audience",
  },
  // HALEON / SECTION / TV / OVERALL / OVERALL
  {
    title: "Map",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Distribution of GRP across country divided into zones and markets, pin color determines growth/degrowth compared to previous period",
  },
  {
    title: "Performance Trend",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Analyze trends and relationships between Sales Value, Sales Volume, GRP and Spends",
  },
  {
    title: "Top Genre & GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Top 9 Genres of TV channels by GRPs where ads were run (other genres clubbed under 'others')",
  },
  {
    title: "Time Slot & GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Top 5 time slots by GRPs where ads were run",
  },
  {
    title: "Ad duration wise GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Top duration of ads by GRPs",
  },
  {
    title: "Brand wise GRP & Spends",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Spends vs Share of Voice for each brand along with growth % w.r.t. the previous period",
  },
  {
    title: "Ad duration wise GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Top duration of ads by GRPs",
  },
  {
    title: "Brand wise GRP & Spends",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Spends vs Share of Voice for each brand along with growth % w.r.t. the previous period",
  },
  {
    title: "Brand wise GRP & Spends",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Spends vs Share of Voice for each brand along with growth % w.r.t. the previous period",
  },
  {
    title: "Time Slot wise GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Identify the Day and time where ads got the maximum number of GRPs",
  },
  {
    title: "Ad position wise GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Identify the Top Genres where ads ran in Premium and non-Premium positions",
  },
  {
    title: "Ad / Campaign Summary",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Campaign & ad performance with option to sort by available metrics in ascending or desending order",
  },
  {
    title: "Compare Table",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Comparison view between brands for KPI's between brands (up to 3 brands at a time)",
  },
  {
    title: "Tv Genre with High GRP",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Compare genrewise GRPs contribution amongst brands",
  },
  {
    title: "GRP's by PT/Non PT",
    module: "tv",
    platform: "overall",
    section: "overall",
    data: "Compare Ad position wise GRPs contribution amongst brands",
  },
];
