import React from 'react'
import Dropdown from '../../components/ui/dropdown/Dropdown'
import ExportDropdown from '../../components/ui/dropdown/ExportDropdown'
import { BarChartDataLabelssample, BarChartDataSample, SimpleTreeMapDataSample, SolidGaugeDataSample } from '../../data/chartsDataSamples'
import SimpleTreeMap from '../../components/ui/charts/SimpleTreeMap'
import PageHeader from '../../components/layout/pageHeader'
import { useState } from 'react'
import { useEffect } from 'react'
import useApi from '../../hooks/useApi'
import { useSelector } from 'react-redux'
import Wrapper from '../../components/helpers/Wrapper'
import Loader from '../../components/ui/loaders/Loader'
import NoDataAvailableChecker from '../../utils/NoDataAvailableChecker'
import NoDataAvailableLoader from '../../components/ui/loaders/NoDataAvailable'
import SolidGauge from '../../components/ui/charts/SolidGauge'
import { gaugeChartData } from '../../constants/constants'
import BarChart from '../../components/ui/charts/BarChart'
import CategoryBarChart from '../../components/ui/charts/CategoryBarChart'
import ErrorBoundary from '../../utils/ErrorBoundary'
import ServerErrorsLoader from '../../components/ui/loaders/ServerErrosLoader'
import SectionDefinationsBadge from '../../components/ui/SectionDefinationsBadge'

export const GaugeMetric = ({ setGaugeExport, setBarExport }) => {
  const gaugeChartAPI = useApi(); // CATEGORY WISE DEEP DIVE DATA API
  const barchartCategories = useApi(); // CATEGORY WISE DEEP DIVE DATA API
  // startDate / endDate / prevStartDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const selectedMasterCategories = useSelector((state) => state.metaFilterDropdown.selectedProductMasterCategories)
  const selectedProductNames = useSelector((state) => state.metaFilterDropdown.selectedProductNames)

  const BASE_URL = process.env.REACT_APP_META_LAMA_API_URL;


  const gaugeMetricDrodowns = [
    { value: 7, label: "7 days" },
    { value: 15, label: "15 days" },
    { value: 30, label: "30 days" },
  ]

  const [gaugeMetricSelectedValue, setGaugeMetricSelectedValue] = useState(gaugeMetricDrodowns[0])


  const onGaugeMetricDropdownClickHandler = (e) => {
    if (e === null) {
      setGaugeMetricSelectedValue("");
    } else {
      setBarChartSelectedValue({ value: 'top', label: "Top" })
      setGaugeMetricSelectedValue(e);
    }
  }

  useEffect(() => {

    let gaugeMetricParams = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      days_span: gaugeMetricSelectedValue.value,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
    }
    gaugeChartAPI.fetchData(BASE_URL, "llama/stock-status-wise/", gaugeMetricParams)

  }, [selectedDates, gaugeMetricSelectedValue, selectedMasterCategories, selectedProductNames])


  const [rawGaugeData, setRawGaugeData] = useState([])

  const [understockGaugeData, setUnderstockGaugeData] = useState([])
  const [overstockGaugeData, setOverstockGaugeData] = useState([])



  useEffect(() => {

    if (gaugeChartAPI.apiStatus.isLoaded && !gaugeChartAPI.apiStatus.isLoading) {

      if (!NoDataAvailableChecker(gaugeChartAPI?.data?.data ?? [])) {

        setRawGaugeData(gaugeChartAPI.data.data)


      }
    }

  }, [gaugeChartAPI.apiStatus, gaugeChartAPI.data])


  useEffect(() => {

    if (rawGaugeData.length !== 0) {
      setGaugeExport(gaugeChartAPI)
      const formattedOverstockData = [];
      const formattedUnderstockData = [];

      rawGaugeData.map((item) => {

        if (item.stock_status === "Overstock") {
          formattedOverstockData.push({ label: '# of M. Cat', value: item.no_of_master_category, max: 8 })
          formattedOverstockData.push({ label: '# of Cat', value: item.no_of_category, max: 52 })
          formattedOverstockData.push({ label: '# of Sub. Cat', value: item.no_of_sub_category, max: 8 })
        } else if (item.stock_status === "Understock") {
          formattedUnderstockData.push({ label: '# of M. Cat', value: item.no_of_master_category, max: 8 })
          formattedUnderstockData.push({ label: '# of Cat', value: item.no_of_category, max: 52 })
          formattedUnderstockData.push({ label: '# of Sub. Cat', value: item.no_of_sub_category, max: 8 })
        }
      })
      setUnderstockGaugeData(formattedUnderstockData)
      setOverstockGaugeData(formattedOverstockData)
    }

  }, [rawGaugeData])



  let underStockGaugeSection = <Wrapper><Loader loaderType={'solidGaugeChartLoader'} /></Wrapper>
  let orderStockGaugeSection = <Wrapper><Loader loaderType={'solidGaugeChartLoader'} /></Wrapper>


  if (gaugeChartAPI.apiStatus.isLoaded && !gaugeChartAPI.apiStatus.isLoading) {

    if (!NoDataAvailableChecker(rawGaugeData)) {
      underStockGaugeSection = (<Wrapper>
        <SolidGauge
          chartId={'solid-gauge'}
          chartClass={'section_card_chart'}
          chartData={understockGaugeData}
        />
      </Wrapper>)

      orderStockGaugeSection = (<Wrapper>
        <SolidGauge
          chartId={'solid-gauge23423'}
          chartClass={'section_card_chart'}
          chartData={overstockGaugeData}
        />
      </Wrapper>)
    } else if (NoDataAvailableChecker(rawGaugeData) && NoDataAvailableChecker(gaugeChartAPI.data.data)) {
      underStockGaugeSection = <Wrapper> <NoDataAvailableLoader chartType={'solidGaugeChartType'} /></Wrapper>
      orderStockGaugeSection = <Wrapper> <NoDataAvailableLoader chartType={'solidGaugeChartType'} /></Wrapper>
    }
  }


  if (gaugeChartAPI.apiStatus.isLoading) {
    underStockGaugeSection = <Wrapper><Loader loaderType={'solidGaugeChartLoader'} /></Wrapper>
    orderStockGaugeSection = <Wrapper><Loader loaderType={'solidGaugeChartLoader'} /></Wrapper>
  }

  if (gaugeChartAPI.apiStatus.error) {
    underStockGaugeSection = <Wrapper> <ServerErrorsLoader chartType={'solidGaugeChartType'} /></Wrapper>
    orderStockGaugeSection = <Wrapper> <ServerErrorsLoader chartType={'solidGaugeChartType'} /></Wrapper>
  }



  const barchartDrodowns = [
    { value: 'top', label: "Top" },
    { value: 'bottom', label: "Bottom" },
  ]

  const [barchartSelectedValue, setBarChartSelectedValue] = useState(barchartDrodowns[0])


  const onBarChartDropdownClickHandler = (e) => {
    if (e === null) {
      setBarChartSelectedValue("");
    } else {
      setBarChartSelectedValue(e);
    }
  }


  useEffect(() => {

    let barchartCatParams = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      dropdown_value: barchartSelectedValue.value,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
      dropdown_value: gaugeMetricSelectedValue.value
    }
    barchartCategories.fetchData(BASE_URL, "llama/categories-stock-status-wise/", barchartCatParams)

  }, [selectedDates, selectedMasterCategories, selectedProductNames, gaugeMetricSelectedValue])


  const [barChartCatRawData, setBarChartCatRawData] = useState({})


  const [underStockBarChartData, setUnderStockBarChartData] = useState({
    master_category: [],
    sub_category: [],
    category: []
  })
  const [overStockBarChartData, setOverStockBarChartData] = useState({
    master_category: [],
    sub_category: [],
    category: []
  })


  useEffect(() => {

    if (barchartCategories.apiStatus.isLoaded && !barchartCategories.apiStatus.isLoading) {
      if (!NoDataAvailableChecker(barchartCategories?.data ?? [])) {
        setBarChartCatRawData(barchartCategories.data.data)
      }
    }
  }, [barchartCategories])


  useEffect(() => {

    try {
      function convertIntoKeyValue(data, key, value) {

        if (data.length === 0) return []
        return data.map((item) => ({
          label: item[key],
          value: item[value]
        })).sort((a, b) => b.value - a.value).slice(0, 5).reverse()
      }


      const overstockObj = {
        master_category: convertIntoKeyValue(barChartCatRawData?.overstock?.master_category ?? [], 'master_category', 'products') ?? [],
        sub_category: convertIntoKeyValue(barChartCatRawData?.overstock?.sub_category ?? [], 'sub_category', 'products') ?? [],
        category: convertIntoKeyValue(barChartCatRawData?.overstock?.category ?? [], 'category', 'products') ?? [],
      }
      const understockObj = {
        master_category: convertIntoKeyValue(barChartCatRawData?.understock?.master_category ?? [], 'master_category', 'products') ?? [],
        sub_category: convertIntoKeyValue(barChartCatRawData?.understock?.sub_category ?? [], 'sub_category', 'products') ?? [],
        category: convertIntoKeyValue(barChartCatRawData?.understock?.category ?? [], 'category', 'products') ?? [],
      }


      setUnderStockBarChartData(understockObj)
      setOverStockBarChartData(overstockObj)

    } catch (error) {

      setUnderStockBarChartData([])
      setOverStockBarChartData([])
    }


  }, [barChartCatRawData, barchartSelectedValue])



  let overStockMasterCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
  let overStockSubCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
  let overStockCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>

  let underStockMasterCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
  let underStockSubCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
  let underStockCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>


  if (barchartCategories.apiStatus.isLoaded && !barchartCategories.apiStatus.isLoading) {
    if (!NoDataAvailableChecker(barChartCatRawData)) {
      overStockMasterCatChartSection = (<Wrapper>
        <ErrorBoundary>
          <CategoryBarChart
            chartId={'overstock_master_category'}
            chartClass={"section_card_chart"}
            chartData={overStockBarChartData.master_category}
            chartLabels={BarChartDataLabelssample}
          />
        </ErrorBoundary>
      </Wrapper>)

      overStockSubCatChartSection = (<Wrapper>
        <ErrorBoundary>
          <CategoryBarChart
            chartId={'overstock_sub_Category'}
            chartClass={"section_card_chart"}
            chartData={overStockBarChartData.sub_category}
            chartLabels={BarChartDataLabelssample}
          />
        </ErrorBoundary>
      </Wrapper>)

      overStockCatChartSection = (<Wrapper>
        <ErrorBoundary>
          <CategoryBarChart
            chartId={'understock_category'}
            chartClass={"section_card_chart"}
            chartData={underStockBarChartData.category}
            chartLabels={BarChartDataLabelssample}
          />
        </ErrorBoundary>
      </Wrapper>)

      underStockMasterCatChartSection = (<Wrapper>
        <ErrorBoundary>
          <CategoryBarChart
            chartId={'understock_master_category'}
            chartClass={"section_card_chart"}
            chartData={underStockBarChartData.master_category}
            chartLabels={BarChartDataLabelssample}
          />
        </ErrorBoundary>
      </Wrapper>)

      underStockSubCatChartSection = (<Wrapper>
        <ErrorBoundary>
          <CategoryBarChart
            chartId={'understock_sub_Category'}
            chartClass={"section_card_chart"}
            chartData={underStockBarChartData.sub_category}
            chartLabels={BarChartDataLabelssample}
          />
        </ErrorBoundary>
      </Wrapper>)

      underStockCatChartSection = (<Wrapper>
        <ErrorBoundary>
          <CategoryBarChart
            chartId={'overstock_category'}
            chartClass={"section_card_chart"}
            chartData={overStockBarChartData.category}
            chartLabels={BarChartDataLabelssample}
          />
        </ErrorBoundary>
      </Wrapper>)

    } else if (NoDataAvailableChecker(barChartCatRawData) && NoDataAvailableChecker(barchartCategories.data.data)) {
      overStockMasterCatChartSection = <Wrapper> <NoDataAvailableLoader chartType={'barChartType'} /> </Wrapper>
      overStockSubCatChartSection = <Wrapper> <NoDataAvailableLoader chartType={'barChartType'} /> </Wrapper>
      overStockCatChartSection = <Wrapper> <NoDataAvailableLoader chartType={'barChartType'} /> </Wrapper>

      underStockMasterCatChartSection = <Wrapper> <NoDataAvailableLoader chartType={'barChartType'} /> </Wrapper>
      underStockSubCatChartSection = <Wrapper> <NoDataAvailableLoader chartType={'barChartType'} /> </Wrapper>
      underStockCatChartSection = <Wrapper> <NoDataAvailableLoader chartType={'barChartType'} /> </Wrapper>
    }
  }

  if (barchartCategories.apiStatus.isLoading) {
    overStockMasterCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
    overStockSubCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
    overStockCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>

    underStockMasterCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
    underStockSubCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
    underStockCatChartSection = <Wrapper><Loader loaderType={'barChartLoader'} /></Wrapper>
  }

  if (barchartCategories.apiStatus.error) {
    overStockMasterCatChartSection = <Wrapper> <ServerErrorsLoader chartType={'barChartType'} /> </Wrapper>
    overStockSubCatChartSection = <Wrapper> <ServerErrorsLoader chartType={'barChartType'} /> </Wrapper>
    overStockCatChartSection = <Wrapper> <ServerErrorsLoader chartType={'barChartType'} /> </Wrapper>

    underStockMasterCatChartSection = <Wrapper> <ServerErrorsLoader chartType={'barChartType'} /> </Wrapper>
    underStockSubCatChartSection = <Wrapper> <ServerErrorsLoader chartType={'barChartType'} /> </Wrapper>
    underStockCatChartSection = <Wrapper> <ServerErrorsLoader chartType={'barChartType'} /> </Wrapper>
  }


  const exportObj = {
    'overUnderGauge_Over Under Gauge': rawGaugeData,
    "Over Under Bar": barChartCatRawData
  }


  return (
    <Wrapper>
      <ErrorBoundary>
        <div id="over_under_gauge" className="grid grid_cols_4 grid_margin_bottom section_card">
          <div className="section_card_header">
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={gaugeMetricDrodowns}
                className="form_dropdown section_dropdown mr_20"
                value={gaugeMetricSelectedValue}
                selectedOptions={gaugeMetricSelectedValue}
                setStatedropdown={onGaugeMetricDropdownClickHandler}
              />
              <ExportDropdown
                sectionId={"over_under_gauge"}
                sectionName={`Stock Gauge and Bar`}
                sectionData={exportObj}
              />
            </div>
          </div>
          <div className="section_card_body under_over_margin">
            <div className="grid grid_cols_4 grid_margin_bottom">

              <div className='grid cols_span_1 flex column align_center'>
                <div className="section_card_chart_wrapper flex column align_center" style={{ borderRight: "1px solid #bacad6", height: '100%' }}>
                  <h2 className="section_card_title ">
                    Under Stock
                    <SectionDefinationsBadge
                      title={''}
                      module={''}
                      platform={''}
                      section={''}
                    />
                  </h2>
                  {underStockGaugeSection}
                </div>
              </div>
              <div className='grid cols_span_3 flex column align_center'>
                <h2 className="section_card_title pt_20 w_100 text_start">
                  Under Stock Categories
                  <SectionDefinationsBadge
                    title={''}
                    module={''}
                    platform={''}
                    section={''}
                  />
                </h2>
                <div className='grid grid_cols_3'>
                  <div className='grid cols_span_1 flex column align_center'>

                    <div className='section_card_chart_wrapper flex column align_center p_0'>
                      <h2 className="section_card_subtitle">
                        Master Category
                      </h2>
                      {underStockMasterCatChartSection}
                    </div>
                  </div>
                  <div className='grid cols_span_1 flex column align_center'>

                    <div className='section_card_chart_wrapper flex column align_center p_0'>
                      <h2 className="section_card_subtitle">
                        Category
                      </h2>
                      {underStockCatChartSection}
                    </div>
                  </div>
                  <div className='grid cols_span_1 flex column align_center'>
                    <div className='section_card_chart_wrapper flex column align_center p_0 pr_20'>
                      <h2 className="section_card_subtitle">
                        Sub Category
                      </h2>
                      {underStockSubCatChartSection}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="section_card_body">
            <div className="grid grid_cols_4 grid_margin_bottom">

              <div className='grid cols_span_1 flex column align_center'>
                <div className="section_card_chart_wrapper flex column align_center" style={{ borderRight: "1px solid #bacad6", height: '100%' }}>
                  <h2 className="section_card_title ">
                    Over Stock
                    <SectionDefinationsBadge
                      title={''}
                      module={''}
                      platform={''}
                      section={''}
                    />
                  </h2>
                  {orderStockGaugeSection}
                </div>

              </div>
              <div className='grid cols_span_3 flex column align_center'>
                <h2 className="section_card_title pt_20 w_100 text_start">
                  Over Stock Categories
                  <SectionDefinationsBadge
                    title={''}
                    module={''}
                    platform={''}
                    section={''}
                  />
                </h2>
                <div className='grid grid_cols_3'>
                  <div className='grid cols_span_1 flex column align_center'>

                    <div className='section_card_chart_wrapper flex column align_center p_0'>
                      <h2 className="section_card_subtitle">
                        Master Category
                      </h2>
                      {overStockMasterCatChartSection}
                    </div>
                  </div>
                  <div className='grid cols_span_1 flex column align_center'>

                    <div className='section_card_chart_wrapper flex column align_center p_0'>
                      <h2 className="section_card_subtitle">
                        Category
                      </h2>
                      {overStockCatChartSection}
                    </div>
                  </div>
                  <div className='grid cols_span_1 flex column align_center'>
                    <div className='section_card_chart_wrapper flex column align_center p_0 pr_20'>
                      <h2 className="section_card_subtitle">
                        Sub Category
                      </h2>
                      {overStockSubCatChartSection}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </Wrapper>
  )
}
