import { useSelector } from "react-redux";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import LoginPage from "../../pages/LoginPage";
import RegistrationPage from "../../pages/RegistrationPage";

import Wrapper from "./Wrapper";

import AuthContext from "../../store/authContext";
import AskIcogz from "../../pages/aryabot/askIcogz";
import Dashboard from "../../pages/meta-lama/DefaultDashboard";



export default function RouteUrls() {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  // PERSISTS STATES
  let store = useSelector((state) => state);

  if (
    store.campaignAdmin === undefined ||
    store.campaignModuleFilter === undefined ||
    store.date === undefined ||
    store.globalSelectedBrandIds === undefined ||
    store.riSalesBrandsCategory === undefined ||
    store.sidebar === undefined ||
    store.tvBrandsCamapaigns === undefined ||
    store.userData === undefined
  ) {
    authCtx.isLoggedIn = false
  }

  return (
    <Wrapper>
      <Routes>
        <Route path={"/login"} element={authCtx.isLoggedIn !== true ? <LoginPage /> : <Navigate to="/" />} />

        <Route path={"/register"} element={authCtx.isLoggedIn !== true ? (<RegistrationPage />) : (<Navigate to="/" />)} />

        <Route path={"/forgot-password"} element={authCtx.isLoggedIn !== true ? (<ForgotPasswordPage />) : (<Navigate to="/" />)} />

        <Route path={"/"} element={authCtx.isLoggedIn === true ? (<Dashboard />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        <Route path={"/ask-icogz"} element={authCtx.isLoggedIn === true ? (<AskIcogz />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        {/* <Route path={"/test"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)} /> */}

        <Route path="*" element={<Navigate to="/" replace state={{ from: location }} />} />

      </Routes>
    </Wrapper >
  );
}
