import PropTypes from "prop-types";
import { Swiper } from "swiper/react";
import { Mousewheel, Keyboard, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

import Wrapper from "../helpers/Wrapper";
import { useEffect, useRef, useState } from "react";
import SliderArrowLeft from "../../assets/icons/SliderLeftArrowIcon";
import SliderArrowRight from "../../assets/icons/SliderRightArrowIcon";
import SectionDefinationsBadge from "./SectionDefinationsBadge";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Slider = ({
  sliderWrapperClass,
  sliderTitle,
  showSliderHeader,
  children,
  slidestoshow,
  breakpoints,
  showNavigation,
  isSkeleton,
  sliderModule,
  sliderPlatform,
  sliderSection,
  cardSpace
}) => {
  let swiperParams = {};

  {
    slidestoshow && breakpoints
      ? (swiperParams = {
        slidesPerView: slidestoshow,
        breakpoints: breakpoints,
      })
      : (swiperParams = {
        slidesPerView: "auto",
      });
  }

  if (showSliderHeader === undefined) {
    showSliderHeader = true;
  }

  if (showNavigation === undefined) {
    showNavigation = true
  }
  let sliderModuleBadge = "";
  if (sliderModule != undefined) {
    sliderModuleBadge = sliderModule
  }
  let sliderPlatformBadge = "";
  if (sliderPlatform != undefined) {
    sliderPlatformBadge = sliderPlatform
  }
  let sliderSectionBadge = "";
  if (sliderSection != undefined) {
    sliderSectionBadge = sliderSection
  }

  const swiperPrevRef = useRef(null);
  const swiperNextRef = useRef(null);

  const [prevButtonDisbled, setPrevButtonDisbled] = useState(true);
  const [nextButtonDisbled, setNextButtonDisbled] = useState(false);

  const [reachedEnd, setReachedEnd] = useState(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // TO HANDLE PREVIOUS BUTTON VISIBILTY ON CLICK
  const prevButtonOnClickHandler = () => {
    if (swiperPrevRef.current.classList.contains("swiper-button-disabled")) {
      {
        showNavigation && setPrevButtonDisbled(true);
      }
    } else {
      {
        {
          showNavigation && setPrevButtonDisbled(false);
        }
      }
    }
  };

  // TO HANDLE NEXT BUTTON VISIBILTY ON CLICK
  const nextButtonOnClickHandler = () => {
    if (swiperNextRef.current.classList.contains("swiper-button-disabled")) {
      {
        showNavigation && setNextButtonDisbled(true);
      }
    } else {
      {
        showNavigation && setNextButtonDisbled(false);
      }
    }
  };

  // TO HANDLE BUTTONS VISIBILTY ON SLIDE
  const slideChangeHandler = (swiper) => {
    if (swiper.activeIndex === 0) {
      {
        showNavigation && setPrevButtonDisbled(true);
        setNextButtonDisbled(false);
      }
    } else if (
      reachedEnd === true &&
      swiperNextRef.current.classList.contains("swiper-button-disabled")
    ) {
      {
        showNavigation && setPrevButtonDisbled(false);
        setNextButtonDisbled(true);
      }
    } else {
      {
        showNavigation && setPrevButtonDisbled(false);
        setNextButtonDisbled(false);
      }
    }
  };

  // TO CHECK IF SLIDES REACHED TO END (START AND END) OR NOT
  const endSlideEventHandler = () => {
    setReachedEnd(true);
  };

  useEffect(() => {
    {
      showNavigation &&
        swiperPrevRef.current.classList.contains("swiper-button-disabled") &&
        setPrevButtonDisbled(true);
    }
    {
      showNavigation &&
        swiperNextRef.current.classList.contains("swiper-button-disabled") &&
        setNextButtonDisbled(true);
    }
  }, [windowSize]);

  let sliderClasses = "grid col_span_4 slider_wrapper_section " + sliderWrapperClass;

  return (
    <Wrapper>
      <div className="grid grid_cols_4 ">

        {showSliderHeader && (
          <div className={`grid col_span_4 section_header slider_header  ${isSkeleton && "skeleton_header"}`}>

            <div className="section_title">
              <div className="section_info">
                <h2 className="section_card_title">
                  {sliderTitle}
                </h2>
                {sliderModuleBadge.length !== 0 && sliderPlatformBadge.length !== 0 && sliderSectionBadge.length !== 0 &&

                  <SectionDefinationsBadge
                    title={sliderTitle}
                    module={sliderModuleBadge}
                    platform={sliderPlatformBadge}
                    section={sliderSectionBadge}
                  />
                }
              </div>

            </div>
            {showNavigation &&
              <div className="filters_wrapper flex justify_end">
                <button
                  onClick={() => prevButtonOnClickHandler()}
                  disabled={prevButtonDisbled}
                  className={
                    prevButtonDisbled === true
                      ? "navigation prev disabled"
                      : "navigation prev"
                  }
                  ref={swiperPrevRef}
                >
                  <SliderArrowLeft className={"slider_arrow"} />
                </button>
                <button
                  onClick={() => nextButtonOnClickHandler()}
                  disabled={nextButtonDisbled}
                  className={
                    nextButtonDisbled === true
                      ? "navigation next disabled"
                      : "navigation next"
                  }
                  ref={swiperNextRef}
                >
                  <SliderArrowRight className={"slider_arrow"} />
                </button>
              </div>
            }
          </div>
        )}
        <div className={`${sliderClasses}  ${isSkeleton && "skeleton_wrapper"}`}>
          <div className="card_wrapper">
            <Swiper
              {...swiperParams}
              className="slider_wrapper"
              spaceBetween={cardSpace === undefined ? 20 : cardSpace}
              // slidesPerView={"auto"}
              grabCursor={true}
              keyboard={{
                enabled: true,
              }}
              // navigation={true}
              // navigation={{
              //   prevEl: swiperPrevRef.current,
              //   nextEl: swiperNextRef.current,
              // }}
              mousewheel={false}
              modules={[Mousewheel, Keyboard, Navigation]}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = swiperPrevRef.current;
                swiper.params.navigation.nextEl = swiperNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              onSlideChange={(swiper) => slideChangeHandler(swiper)}
              onReachEnd={() => endSlideEventHandler()}
            >
              {children}
            </Swiper>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

Slider.propTypes = {
  sliderWrapperClass: PropTypes.string,
  sliderTitle: PropTypes.string,
  children: PropTypes.any,
  showSliderHeader: PropTypes.bool,
  slidestoshow: PropTypes.number,
  breakpoints: PropTypes.object,
  showNavigation: PropTypes.bool,
  isSkeleton: PropTypes.bool,
  sliderModule: PropTypes.string,
  sliderPlatform: PropTypes.string,
  sliderSection: PropTypes.string,
};

export default Slider;
