import React from 'react'
import Dropdown from '../../components/ui/dropdown/Dropdown'
import ExportDropdown from '../../components/ui/dropdown/ExportDropdown'
import { SimpleTreeMapDataSample } from '../../data/chartsDataSamples'
import SimpleTreeMap from '../../components/ui/charts/SimpleTreeMap'
import PageHeader from '../../components/layout/pageHeader'
import { useState } from 'react'
import { useEffect } from 'react'
import useApi from '../../hooks/useApi'
import { useSelector } from 'react-redux'
import Wrapper from '../../components/helpers/Wrapper'
import Loader from '../../components/ui/loaders/Loader'
import NoDataAvailableChecker from '../../utils/NoDataAvailableChecker'
import NoDataAvailableLoader from '../../components/ui/loaders/NoDataAvailable'
import { areAllValuesZero } from '../../utils/Utils'
import ErrorBoundary from '../../utils/ErrorBoundary'
import SectionDefinationsBadge from '../../components/ui/SectionDefinationsBadge'

export const CategoryDeepdives = ({ setExportData }) => {
  const categoryWiseDeepdiveDataAPI = useApi(); // CATEGORY WISE DEEP DIVE DATA API
  const categoryWiseDeepdivePreviousDataAPI = useApi(); // CATEGORY WISE DEEP DIVE DATA API

  const selectedMasterCategories = useSelector((state) => state.metaFilterDropdown.selectedProductMasterCategories)
  const selectedProductNames = useSelector((state) => state.metaFilterDropdown.selectedProductNames)


  // startDate / endDate / prevStartDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const BASE_URL = process.env.REACT_APP_META_LAMA_API_URL;


  const categoryWiseDeepdiveDropdonwOptions = [
    { value: "understock", label: "Understock" },
    { value: "overstock", label: "Overstock" },
  ]


  const [rawCategoryWiseDeepDiveData, setRawCategoryWiseDeepDiveData] = useState([])
  const [CategoryWiseDeepDiveData, setCategoryWiseDeepDiveData] = useState([])



  const [selectedCategoryWiseDeepdiveDropdownValue, setSelectedCategoryWiseDeepdiveDropdownValue] = useState(categoryWiseDeepdiveDropdonwOptions[0]);

  const onCategoryWiseDeepdiveDropdownClickHandler = (e) => {
    if (e === null) {
      setSelectedCategoryWiseDeepdiveDropdownValue("");
    } else {
      setSelectedCategoryWiseDeepdiveDropdownValue(e);
      setTreemapBreadcrumbs([])
      setTreemapParams({ level: 1 })
    }
  }

  const [treemapParams, setTreemapParams] = useState({
    level: 1
  })

  const [treemapBreadcrumbs, setTreemapBreadcrumbs] = useState([])


  const categoryDeepDiveLevelChange = (data) => {

    if (treemapParams.level === 1) {
      setTreemapParams({
        level: 2,
        level_1: data.id
      })
      setTreemapBreadcrumbs([{ ...data, level: 2, level_name: 'level_1' }])
    } else if (treemapParams.level === 2) {
      setTreemapParams(prev => ({
        ...prev,
        level: 3,
        level_2: data.id
      }))
      setTreemapBreadcrumbs(prev => [...prev, { ...data, level: 3, level_name: 'level_2' }])

    } else if (treemapParams.level === 3) {
      setTreemapParams(prev => ({
        ...prev,
        level: 4,
        level_3: data.id
      }))
      setTreemapBreadcrumbs(prev => [...prev, { ...data, level: 4, level_name: 'level_3' }])
    } else {
      alert('No further drilldown available!')
    }
  }



  useEffect(() => {

    let categoryWiseDeepdiveParams = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      dropdown: selectedCategoryWiseDeepdiveDropdownValue.label,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
      ...treemapParams
    }
    categoryWiseDeepdiveDataAPI.fetchData(BASE_URL, "llama/category-wise-deepdive/", categoryWiseDeepdiveParams)

  }, [
    selectedDates,
    selectedCategoryWiseDeepdiveDropdownValue,
    treemapParams,
    selectedMasterCategories, selectedProductNames
  ])

  useEffect(() => {

    let categoryWiseDeepdiveParams = {
      start_date: selectedDates.prevStartDate,
      end_date: selectedDates.prevEndDate,
      dropdown: selectedCategoryWiseDeepdiveDropdownValue.label,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
      ...treemapParams

    }
    categoryWiseDeepdivePreviousDataAPI.fetchData(BASE_URL, "llama/category-wise-deepdive/", categoryWiseDeepdiveParams)

  }, [
    selectedDates,
    selectedCategoryWiseDeepdiveDropdownValue,
    treemapParams,
    selectedMasterCategories, selectedProductNames
  ])






  useEffect(() => {

    if (
      categoryWiseDeepdiveDataAPI.apiStatus.isLoaded && !categoryWiseDeepdiveDataAPI.apiStatus.isLoading &&
      categoryWiseDeepdivePreviousDataAPI.apiStatus.isLoaded && !categoryWiseDeepdivePreviousDataAPI.apiStatus.isLoading

    ) {

      setRawCategoryWiseDeepDiveData(categoryWiseDeepdiveDataAPI?.data?.data ?? [])

    }
  }, [categoryWiseDeepdiveDataAPI.apiStatus, categoryWiseDeepdiveDataAPI.data, categoryWiseDeepdivePreviousDataAPI.apiStatus]);

  useEffect(() => {

    const formattedData = [];


    const idKeys = ['category1', 'category2', 'category3']

    // if (!categoryWiseDeepdivePreviousDataAPI?.data?.data) {
    rawCategoryWiseDeepDiveData.forEach((item) => {
      let idStr = ''

      const idKeys = ['category1', 'category2', 'category3']
      idKeys.forEach((str) => {
        if (item.hasOwnProperty(str)) {
          idStr = str
        }
      })

      formattedData.push({
        id: item[idStr],
        name: Object.values(item)[2],
        value: item.stock_on_hand,
        // growth_percentage: 100,

      })
    })
    // } else {
    //   rawCategoryWiseDeepDiveData.forEach((item) => {


    //     categoryWiseDeepdivePreviousDataAPI?.data?.data.forEach((prev) => {

    //       let calcGrowthPerc = ((item.stock_on_hand - prev.stock_on_hand) / prev.stock_on_hand) * 100

    //       if (Object.values(prev)[0] === Object.values(item)[0]) {
    //         formattedData.push({
    //           id: item.category1,
    //           name: Object.values(prev)[1],
    //           value: item.stock_on_hand,
    //           growth_percentage: isNaN(calcGrowthPerc) || !isFinite(calcGrowthPerc) ? 0 : calcGrowthPerc,
    //         })
    //       }
    //     })

    //   })

    // }

    setExportData({
      apiStatus: categoryWiseDeepdiveDataAPI.apiStatus,
      data: formattedData

    })
    setCategoryWiseDeepDiveData(formattedData)
  }, [rawCategoryWiseDeepDiveData])


  let categoryDeepDiveSection = <Wrapper><Loader loaderType={'treeChartLoader'} /></Wrapper>


  if (categoryWiseDeepdiveDataAPI.apiStatus.isLoading) {
    categoryDeepDiveSection = <Wrapper><Loader loaderType={'treeChartLoader'} /></Wrapper>
  }

  if (!categoryWiseDeepdiveDataAPI.apiStatus.isLoading && categoryWiseDeepdiveDataAPI.apiStatus.isLoaded) {

    if (!NoDataAvailableChecker(rawCategoryWiseDeepDiveData)) {

      categoryDeepDiveSection = <SimpleTreeMap
        chartId="simpleTreemaptest"
        chartClass="section_card_chart"
        chartData={CategoryWiseDeepDiveData}
        levelHandler={categoryDeepDiveLevelChange}
        // colorChangeParameter={'growth_percentage'}
        chartLabels={[
          {
            name: "name",
            value: "value",
            toolTipvalue1: "value",
            toolTipvalue1Label: "Stock Count",
          },
        ]}
      />
    } else if (NoDataAvailableChecker(rawCategoryWiseDeepDiveData) && NoDataAvailableChecker(categoryWiseDeepdiveDataAPI.data.data)) {
      categoryDeepDiveSection = <Wrapper><NoDataAvailableLoader chartType={'treeChartType'} /></Wrapper>
    }

  }



  return (

    <Wrapper>
      <ErrorBoundary>
        <div id="category_wise_deepdive" className="grid grid_cols_4 grid_margin_bottom section_card">
          <div className="section_card_header">
            <div className="flex column align_start">
              <div className="section_info">
                <h2 className="section_card_title">
                  Category wise deepdive
                  <SectionDefinationsBadge
                    title={''}
                    module={''}
                    platform={''}
                    section={''}
                  />
                </h2>
              </div>
              <div className="treemap_breadcrumb">
                <span
                  onClick={() => {
                    setTreemapParams({ level: 1 });
                    setTreemapBreadcrumbs([])
                  }}
                >
                  Home
                </span>

                {treemapBreadcrumbs && treemapBreadcrumbs.map((item, index) => {
                  return <span
                    onClick={() => {
                      if (treemapBreadcrumbs.length - 1 !== index) {

                        const updateArr = [...treemapBreadcrumbs].slice(0, (item.level - 1))
                        const newObj = {};

                        updateArr.forEach((item) => {
                          newObj[item.level_name] = item.id
                        })

                        setTreemapParams({
                          level: item.level,
                          ...newObj
                        })
                        setTreemapBreadcrumbs(updateArr)

                      }
                    }}

                  > / {item.name}</span>
                })}
              </div>
            </div>
            <div data-html2canvas-ignore={true} style={{ gap: "20px" }}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={categoryWiseDeepdiveDropdonwOptions}
                className="form_dropdown section_dropdown"
                value={selectedCategoryWiseDeepdiveDropdownValue}
                selectedOptions={selectedCategoryWiseDeepdiveDropdownValue}
                setStatedropdown={onCategoryWiseDeepdiveDropdownClickHandler}
              />
              <ExportDropdown
                sectionId={"category_wise_deepdive"}
                sectionName={`removeId_Category Deepdive`}
                sectionData={CategoryWiseDeepDiveData}
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {categoryDeepDiveSection}
              </div>
            </div>

          </div>
        </div>


      </ErrorBoundary>
    </Wrapper>

  )
}
