import { useLocation, useNavigate } from "react-router-dom";
import IconGrowth from "../../../assets/icons/GrowthIcon";
import Wrapper from "../../helpers/Wrapper";
import FeedbackSection from "../feedback/feedbackSection";
import TextTrimmer from "../../../utils/textTrimmer";

import "./insightCards.scss";
import { SwiperSlide } from "swiper/react";
import Slider from "../Slider";
import { useEffect, useRef, useState } from "react";
import ForecasteIcon from "../../../assets/icons/ForecasteIcon";
import CorrelationIcon from "../../../assets/icons/CorrelationIcon";
import ComparisonIcon from "../../../assets/icons/ComparisonIcon";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import ParetoAnalysisIcon from "../../../assets/icons/ParetoAnalysisIcon";
import SummarizationIcon from "../../../assets/icons/SummarizationIcon";
import SeasonalityAndTrendAnalysisIcon from "../../../assets/icons/SeasonalityAndTrendAnalysisIcon";
import OutlierAnalysisIcon from "../../../assets/icons/OutlierAnalysisIcon";
import PredictiveAnalysis from "../../../assets/icons/PredictiveAnalysis";
import SentimentAnalysisIcon from "../../../assets/icons/SentimentAnalysisIcon";
import { removeTags } from "../../../utils/Utils";

const InsightCards = ({ data }) => {
    const location = useLocation();
    const navigate = useNavigate();

    let cardsData = []

    if (data === undefined) {
        cardsData = null
    } else if (data === null) {
        cardsData = null
    } else if (data.length === 0) {
        cardsData = null
    } else {
        cardsData = data
    }

    const onCardClickHandler = (sectionQuery, sectionData) => {
        navigate("/ask-icogz", { state: { from: location.pathname, query: sectionQuery, data: sectionData } });
    };

    return (
        <Wrapper>
            <div className="insight-cards-wrapper">
                <div className="cards-wrapper">
                    <ErrorBoundary>
                        <Slider sliderTitle={"Top Performing Insights"}>
                            {cardsData && !cardsData.code && cardsData.map((card, index) => (
                                <SwiperSlide key={index}>
                                    <div className="insight-card" onClick={() => Object.keys(card.response?.model_data?.chart_data ?? {}).length !== 0 && onCardClickHandler(card.analysis_type, card.response)}>
                                        <div className="header">
                                            <div className="left">
                                                <div className="icon">
                                                    <CardIcons type={card.analysis_type} />
                                                </div>
                                                <div className="title">
                                                    <CardTitle title={card.analysis_type} />
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="feedback">
                                                    <FeedbackSection />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="body">
                                            {/* <div className="title">
                                                <div dangerouslySetInnerHTML={{ __html: card.short_summary }}></div>
                                            </div> */}
                                            <div className="subtitle">{removeTags(card.short_summary)}</div>
                                            <div className="content">
                                                {/* {typeof card.short_description === 'string' && <div dangerouslySetInnerHTML={{ __html: card.short_description }}></div>} */}
                                                <TextTrimmer
                                                    title={card.analysis_type}
                                                    text={card.short_description ?? ''}
                                                    onViewMoreClickHandler={() => onCardClickHandler(card.analysis_type, card.response)} // Use an anonymous function
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Slider>
                    </ErrorBoundary>
                </div>
            </div>
        </Wrapper>
    );
};
export default InsightCards;


export const InsightCardsSekeltonLoader = () => {
    const elementRef = useRef(null);
    const cardWidth = 420; // Assuming this is a number and not a string
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (elementRef.current) {
            setWidth(elementRef.current.offsetWidth);
        }

        // Add an event listener to handle window resize
        const handleResize = () => {
            if (elementRef.current) {
                setWidth(elementRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const noOfCards = Math.floor(width / cardWidth);

    // Create an array of the required length
    const skeletonCards = Array.from({ length: noOfCards }).map((_, index) => (
        <div className="skeleton-insights-card" key={index}>
            <div className="skeleton-card-title"></div>
            <div className="skeleton-card-content"></div>
        </div>
    ));

    return (
        <div className="insight-cards-wrapper skeleton-loader" ref={elementRef}>
            <div className="skeleton-section-title skeleton-loader"></div>
            <div className="skeleton-insights-card-wrapper">
                {skeletonCards}
            </div>
        </div>
    );
};

export const InsightCardsErrorLoader = () => {
    return (
        <Wrapper>
            <div class="insight-cards-wrapper skeleton-loader">
                Something went wrong
            </div>
        </Wrapper>
    )
}


const CardIcons = ({ type }) => {

    let icon = <SummarizationIcon />

    if (type === "Summarization" || type === 'Summary Digest') {
        icon = <SummarizationIcon />
    }
    else if (type === "Growth Analysis" || type === 'Growth-DeGrowth Alert') {
        icon = <IconGrowth />
    }
    else if (type === "Outlier Analysis") {
        icon = <OutlierAnalysisIcon />
    }
    else if (type === "Correlation Analysis" || type === 'Association') {
        icon = <CorrelationIcon />
    }
    else if (type === "Seasonality and Trend analysis") {
        icon = <SeasonalityAndTrendAnalysisIcon />
    }
    else if (type === "Forecasting") {
        icon = <ForecasteIcon />
    }
    else if (type === "Predictive Analysis") {
        icon = <PredictiveAnalysis />
    }
    else if (type === "Sentiment Analysis") {
        icon = <SentimentAnalysisIcon />
    }
    else if (type === "Pareto Analysis") {
        icon = <ParetoAnalysisIcon />
    }
    else if (type === "Comparison Analysis") {
        icon = <ComparisonIcon />
    } else {
        icon = <PredictiveAnalysis />
    }

    return (
        <Wrapper>
            {icon}
        </Wrapper>
    )
}

const CardTitle = ({ title }) => {
    if (title === undefined || title.length === 0) {
        return ""
    }

    let insightTypesLabels = [{
        label: "Summary Digest",
        value: "Summarization"
    },
    {
        label: "Growth - DeGrowth Alert",
        value: "Growth Analysis"
    },
    {
        label: "Outlier Detection",
        value: "Outlier Analysis"
    },
    {
        label: "Association",
        value: "Correlation Analysis"
    },
    {
        label: "Pattern Recognition",
        value: "Seasonality and Trend analysis"
    },
    {
        label: "Foresight Analysis",
        value: "Forecasting"
    },
    {
        label: "Foresight Analysis",
        value: "Predictive Analysis"
    },
    {
        label: "Sentiment Evaluation",
        value: "Sentiment Analysis"
    },
    {
        label: "Pareto Analysis",
        value: "Pareto Analysis"
    },
    {
        label: "Benchmark Analysis",
        value: "Comparison Analysis"
    }]

    const result = insightTypesLabels.find(data => data.value === title);

    return result ? result.label : title;

}