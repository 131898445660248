import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import { formatLabels } from "../../utils/Utils";
import Wrapper from "../helpers/Wrapper"
import Loader from "../ui/loaders/Loader";
import NoDataAvailableLoader from "../ui/loaders/NoDataAvailable";
import Datatable from "../ui/tables/Datatable";

const InsightsTable = ({ tableData, chartType }) => {

    let table_data = tableData?.results ?? tableData ?? []
    if (table_data === undefined) {
        table_data = []
    } else if (table_data === null) {
        table_data = []
    } else if (table_data.length === 0) {
        table_data = []
    }

    const formatTableData = (inputData, chart_type) => {

        let data = [];

        if (chart_type === "scatter-chart") {

            let raw_table_data = inputData[0]

            // Extract metric names
            const metric1 = raw_table_data["Metric 1"];
            const metric2 = raw_table_data["Metric 2"];

            // Extract metric values
            const metric1Values = raw_table_data["Metric 1 Values"];
            const metric2Values = raw_table_data["Metric 2 Values"];

            // Format the data
            const formattedData = metric1Values.map((value, index) => {
                return {
                    [metric1]: value,
                    [metric2]: metric2Values[index]
                };
            });
            data = formattedData;
        } else {
            data = inputData;
        }

        const keys = Object.keys(data[0] ?? []);

        let headers = keys.map(key => ({
            name: formatLabels(key),
            field: key
        }));

        const sortPriority = ["country", "state", "state code", "day", "date", "month", "state name", "city"];

        let sortedHeaders = headers.sort((a, b) => {
            // Find the index of each item's name in the sortPriority array
            let indexA = sortPriority.findIndex(priority => a.name.toLowerCase().includes(priority));
            let indexB = sortPriority.findIndex(priority => b.name.toLowerCase().includes(priority));

            // Handle cases where findIndex returns -1 (item not found in sortPriority)
            if (indexA === -1) indexA = sortPriority.length;
            if (indexB === -1) indexB = sortPriority.length;

            // Sort by the index in sortPriority array
            return indexA - indexB;
        });

        return {
            headers: sortedHeaders,
            data: data
        };
    }

    let formattedTableData = formatTableData(table_data, chartType)

    let tablePlotting = <Wrapper><Loader loaderType={'smallTableLoader'} />
        {/* <Datatable
            tableHeader={formattedTableData.headers}
            tableData={formattedTableData.data}
            tableLength={5}
            tableID="state_wise_table"
        /> */}
    </Wrapper>


    if (NoDataAvailableChecker(table_data)) {
        tablePlotting = <Wrapper><NoDataAvailableLoader chartType={'tableChartType'} /></Wrapper>
    }


    console.log(table_data)

    if (!NoDataAvailableChecker(table_data) && !NoDataAvailableChecker(formattedTableData.data) && !NoDataAvailableChecker(formattedTableData.headers)) {
        tablePlotting = <Datatable
            tableHeader={formattedTableData.headers}
            tableData={formattedTableData.data}
            tableLength={5}
            tableID="state_wise_table"
        />
    }


    return (
        <Wrapper>
            <div className="tableWrapper">
                {tablePlotting}
            </div>
        </Wrapper>
    )
}
export default InsightsTable;