import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../../store/authContext";
import axios from "../../../components/helpers/axios";
import Wrapper from "../../helpers/Wrapper";
import Button from "../Button";
import TextFormatter from "../../../utils/TextFormatter";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import Loader from "../loaders/Loader";
import ServerErrorsLoader from "../loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import FilterIcon from "../../../assets/icons/FilterIcon";
import Tab from "../Tab";
import { setSelectedProductMasterCategories, setSelectedProductNames } from '../../../store/metaGlobalFilterSlice'
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import useApi from "../../../hooks/useApi";


const MetaDropdown = () => {
  const BASE_URL = process.env.REACT_APP_META_LAMA_API_URL;

  const productMasterAPI = useApi(); // PRODUCT MASTER CATEGORY DROPDOWN
  const productNameAPI = useApi(); // PRODUCT MASTER CATEGORY DROPDOWN


  const dispatch = useDispatch();

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);


  // TO SHOW AND HIDE THE DROPDOWN ON CLICK
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const buttonClickHandler = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const tabData = ["Product Master Category", "Product Name"];

  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
  };

  useEffect(() => {

    let params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
    }

    productMasterAPI.fetchData(BASE_URL, "llama/master-category/", params)
    productNameAPI.fetchData(BASE_URL, "llama/product-name/", params)
  }, [selectedDates])




  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // PRODUCT MASTER CATEGORY
  const [productMasterCatRawData, setProductMasterCatRawData] = useState([])
  const [selectedProductMasterCat, setSelectedProductMasterCat] = useState([])
  const [masterCategorySearchTerm, setMasterCategorySearchTerm] = useState("");

  useEffect(() => {

    if (productMasterAPI.apiStatus.isLoaded && !productMasterAPI.apiStatus.isLoading) {
      if (!NoDataAvailableChecker(productMasterAPI?.data?.data ?? [])) {
        setProductMasterCatRawData(productMasterAPI?.data?.data)
      }
    }

  }, [productMasterAPI])


  const filteredMasterCategoryList = useMemo(() => {
    if (masterCategorySearchTerm === "") return productMasterCatRawData;
    if (productMasterCatRawData === "") return productMasterCatRawData;

    const filteredList = productMasterCatRawData.filter((category) => {
      return (
        category.label
          .toLowerCase()
          .includes(masterCategorySearchTerm.toLowerCase())
      );
    });
    return filteredList;
  }, [masterCategorySearchTerm, productMasterCatRawData]);



  const masterProductOnClickHandler = (category) => {
    if (selectedProductMasterCat.some((a) => a === category)) {
      selectedProductMasterCat.splice(selectedProductMasterCat.findIndex((b) => b === category), 1);
      setSelectedProductMasterCat([...selectedProductMasterCat]);
    } else {
      selectedProductMasterCat.push(category);
      setSelectedProductMasterCat([...selectedProductMasterCat]);
    }
  }

  const cancelMasterProductBtn = () => {
    setDropdownVisible(false);
  };

  const clearMasterProductBtn = () => {
    setDropdownVisible(false);
    setSelectedProductMasterCat([]);
    dispatch(setSelectedProductMasterCategories([]));
  }

  const applyMasterProductBtn = () => {
    setDropdownVisible(false);
    dispatch(setSelectedProductMasterCategories([
      ...new Set(selectedProductMasterCat.map((item) => item.value)),
    ]));
  }


  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let masterProductSection = (
    <Wrapper>
      <Loader />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (productMasterAPI.apiStatus.isLoaded && !productMasterAPI.apiStatus.isLoading) {
    if (NoDataAvailableChecker(filteredMasterCategoryList)) {
      masterProductSection =
        <Wrapper>
          <NoDataAvailableLoader />
        </Wrapper>
    } else {
      masterProductSection = filteredMasterCategoryList.map(
        (category) =>
          <div
            key={category.value}
            onClick={() => masterProductOnClickHandler(category)}
            className={
              selectedProductMasterCat.some((a) => a.value === category.value) === true
                ? "tab active"
                : "tab"
            }
          >
            <ErrorBoundary>
              <TextFormatter string={category.label} />
            </ErrorBoundary>
          </div>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (productMasterAPI.apiStatus.error) {
    masterProductSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="cardChartType"
          error={productMasterAPI.apiStatus.error}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (productMasterAPI.apiStatus.isLoading) {
    masterProductSection = (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }





  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  // PRODUCT NAME
  const [productNameRawData, setProductNameRawData] = useState([])
  const [selectedProductName, setSelectedProductName] = useState([])
  const [productNameSearchTerm, setProductNameSearchTerm] = useState("");

  useEffect(() => {

    if (productNameAPI.apiStatus.isLoaded && !productNameAPI.apiStatus.isLoading) {
      if (!NoDataAvailableChecker(productNameAPI?.data?.data ?? [])) {
        setProductNameRawData(productNameAPI?.data?.data)
      }
    }

  }, [productNameAPI])


  const filteredProductNameList = useMemo(() => {
    if (productNameSearchTerm === "") return productNameRawData;
    if (productNameRawData === "") return productNameRawData;

    const filteredList = productNameRawData.filter((category) => {
      return (
        category.label
          .toLowerCase()
          .includes(productNameSearchTerm.toLowerCase())
      );
    });
    return filteredList;
  }, [productNameSearchTerm, productNameRawData]);



  const productNameOnClickHandler = (name) => {
    if (selectedProductName.some((a) => a === name)) {
      selectedProductName.splice(selectedProductName.findIndex((b) => b === name), 1);
      setSelectedProductNames([...selectedProductName]);
    } else {
      selectedProductName.push(name);
      setSelectedProductNames([...selectedProductName]);
    }
  }

  const cancelProductNameBtn = () => {
    setDropdownVisible(false);
  };

  const clearProductNameBtn = () => {
    setDropdownVisible(false);
    setSelectedProductName([]);
    dispatch(setSelectedProductNames([]));
  }

  const applyProductNameBtn = () => {
    setDropdownVisible(false);
    dispatch(setSelectedProductNames([
      ...new Set(selectedProductName.map((item) => item.value)),
    ]));
  }


  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let productNameSection = (
    <Wrapper>
      <Loader />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (productNameAPI.apiStatus.isLoaded && !productNameAPI.apiStatus.isLoading) {
    if (NoDataAvailableChecker(filteredProductNameList)) {
      productNameSection =
        <Wrapper>
          <NoDataAvailableLoader />
        </Wrapper>
    } else {
      productNameSection = filteredProductNameList.map(
        (name) =>
          <div
            key={name.value}
            onClick={() => productNameOnClickHandler(name)}
            className={
              selectedProductName.some((a) => a.value === name.value) === true
                ? "tab active"
                : "tab"
            }
          >
            <ErrorBoundary>
              <TextFormatter string={name.label} />
            </ErrorBoundary>
          </div>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (productNameAPI.apiStatus.error) {
    productNameSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="cardChartType"
          error={productNameAPI.apiStatus.error}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (productNameAPI.apiStatus.isLoading) {
    productNameSection = (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }














  return (
    <Wrapper>
      <div className="multistep_dropdown_container">
        <div className="dropdown_wrapper_btn">
          <Button
            icon={<FilterIcon className={"btn_icon"} />}
            className={
              isDropdownVisible === true
                ? "secondary_btn active"
                : "secondary_btn"
            }
            type="button"
            handleClick={() => buttonClickHandler()}
          ></Button>
        </div>
        {isDropdownVisible && (
          <div className="dropdown_wrapper">
            <div className="tabs_wrapper">
              <Tab
                varient={"primary"}
                tabData={tabData}
                activeTab={selectedTab}
                handleCallback={(event) => handleCallback(event)}
              />
            </div>
            {selectedTab === tabData[0] && (
              <Wrapper>
               
                <div className="grid grid_cols_1 brands_dropdown_section">
                  <div className="campaigns_container" style={{ width: "100%" }}>
                    <div className="body">
                      <div className="filter">
                        <input
                          className="form_control input"
                          type="text"
                          autoComplete="off"
                          placeholder="Search Product Master Category"
                          onChange={(event) => {
                            setMasterCategorySearchTerm(event.target.value);
                          }}
                        />
                      </div>
                      <div className="tabs_container">
                        {/* <NoDataAvailableLoader /> */}
                        {masterProductSection}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="brands_dropdown_footer">
                  <div className="">
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => cancelMasterProductBtn()}
                        className="secondary_danger_btn"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => clearMasterProductBtn()}
                        className="secondary_btn"
                      >
                        Clear
                      </Button>
                    </div>
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => applyMasterProductBtn()}
                        className="primary_btn"
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </Wrapper>
            )}

            {selectedTab === tabData[1] &&
              (
                <Wrapper>
                  <div className="grid grid_cols_1 brands_dropdown_section">
                    <div className="campaigns_container" style={{ width: "100%" }}>
                      <div className="body">
                        <div className="filter">
                          <input
                            className="form_control input"
                            type="text"
                            autoComplete="off"
                            placeholder="Search Product Name"
                            onChange={(event) => {
                              setProductNameSearchTerm(event.target.value);
                            }}
                          />
                        </div>
                        <div className="tabs_container">
                          {productNameSection}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="brands_dropdown_footer">
                    <div className="">
                      <div className="btn_wrapper">
                        <Button
                          handleClick={() => cancelProductNameBtn()}
                          className="secondary_danger_btn"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="btn_wrapper">
                        <Button
                          handleClick={() => clearProductNameBtn()}
                          className="secondary_btn"
                        >
                          Clear
                        </Button>
                      </div>
                      <div className="btn_wrapper">
                        <Button
                          handleClick={() => applyProductNameBtn()}
                          className="primary_btn"
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                </Wrapper>
              )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};
export default MetaDropdown;
