const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  selectedProductMasterCategories: [],
  selectedProductNames: [],
};

const metaGlobalFilterSlice = createSlice({
  name: "metaGlobalFilters",
  initialState,
  reducers: {
    setSelectedProductMasterCategories(state, action) {
      state.selectedProductMasterCategories = action.payload;
    },
    setSelectedProductNames(state, action) {
      state.selectedProductNames = action.payload;
    },
    resetMetaGlobalFilters: () => initialState
  },
});

export const { setSelectedProductMasterCategories, setSelectedProductNames, resetMetaGlobalFilters } = metaGlobalFilterSlice.actions;

export default metaGlobalFilterSlice.reducer;
