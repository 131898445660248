import { format } from "date-fns";

const { createSlice } = require("@reduxjs/toolkit");

function formatDateDisplay(date, defaultText) {
  if (!date) return defaultText;
  return format(date, "yyyy-MM-dd");
}

// FOR SETTING THE END DATE OF CURRENT
// let current_end_date = new Date();
// current_end_date.setDate(current_end_date.getDate() - 45);

// // FOR SETTING THE START DATE OF PREVIOUS
// let previous_start_date = new Date();
// previous_start_date.setDate(previous_start_date.getDate() - 90);

// // FOR SETTING THE END DATE OF PREVIOUS
// let previous_end_date = new Date();
// previous_end_date.setDate(previous_end_date.getDate() - 45);

const initialState = {
  startDate: formatDateDisplay(new Date('2018-04-01')),
  endDate: formatDateDisplay(new Date('2018-06-30')),
  prevStartDate: formatDateDisplay(new Date('2018-01-01')),
  prevEndDate: formatDateDisplay(new Date('2018-03-31')),
};

const dateSlice = createSlice({
  name: "dates",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setPrevStartDate: (state, action) => {
      state.prevStartDate = action.payload;
    },
    setPrevEndDate: (state, action) => {
      state.prevEndDate = action.payload;
    },
    resetDates: () => initialState
  },
});

export const { setStartDate, setEndDate, setPrevStartDate, setPrevEndDate, resetDates } =
  dateSlice.actions;

export default dateSlice.reducer;
