import { useContext, useState } from "react";
import Wrapper from "../helpers/Wrapper";
import DatePicker from "../ui/Datepicker";
import ErrorBoundary from "../../utils/ErrorBoundary";
import ExportDropdown from "../ui/dropdown/ExportDropdown";
import IcogzLogo from "../../assets/brand/icogz-logo.svg";
import MetaDropdown from "../ui/dropdown/MetaDropdown";
import AuthContext from "../../store/authContext";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import Button from "../ui/Button";

const Header = ({ identity, name, data, headers }) => {
  const authCtx = useContext(AuthContext);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleCallback2 = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const logoutHandler = () => {
    localStorage.setItem("isManualLogout", true);
    authCtx.logout();
  };

  return (
    <Wrapper>
      <div className="dashboard_navbar">
        <a href="/" className="icogz_logo_link">
          {/* ICOGZ LOGO */}
          <img src={IcogzLogo} alt="icogz logo" className="icogz_logo" />
        </a>
        <div className="mobile_navbar_filter">
        </div>
        <div className="flex justify_between" id="top_nav_bar">
          <div className="left">
          </div>
          <div className="right">
            <div className="flex justify_end align_center">
              <div className="flex center top_nav_options">
                <ErrorBoundary>
                  <DatePicker
                    open={isDatePickerOpen}
                    setOpen={handleCallback2}
                  />
                </ErrorBoundary>
              </div>
              <div style={{ marginRight: "10px" }}>
                <div data-html2canvas-ignore={true}>
                  <ExportDropdown sectionId={identity} sectionName={name} sectionData={data} sectionDataHeaders={headers} />
                </div>
              </div>
              <div style={{ marginRight: "20px" }}>
                <Button className={"primary_btn"} handleClick={logoutHandler}>Logout</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
