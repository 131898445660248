import React from 'react'
import Dropdown from '../../components/ui/dropdown/Dropdown'
import ExportDropdown from '../../components/ui/dropdown/ExportDropdown'
import { SimpleTreeMapDataSample } from '../../data/chartsDataSamples'
import SimpleTreeMap from '../../components/ui/charts/SimpleTreeMap'
import PageHeader from '../../components/layout/pageHeader'
import { useState } from 'react'
import { useEffect } from 'react'
import useApi from '../../hooks/useApi'
import { useSelector } from 'react-redux'
import Wrapper from '../../components/helpers/Wrapper'
import Loader from '../../components/ui/loaders/Loader'
import NoDataAvailableChecker from '../../utils/NoDataAvailableChecker'
import NoDataAvailableLoader from '../../components/ui/loaders/NoDataAvailable'
import ErrorBoundary from '../../utils/ErrorBoundary'
import SectionDefinationsBadge from '../../components/ui/SectionDefinationsBadge'

export const SellThroughDeepdive = ({ sellExportData }) => {
  const sellThroughDeepdiveDataAPI = useApi(); // CATEGORY WISE DEEP DIVE DATA API
  const sellThroughDeepdiveDataPreviousAPI = useApi(); // CATEGORY WISE DEEP DIVE DATA API
  // startDate / endDate / prevStartDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const BASE_URL = process.env.REACT_APP_META_LAMA_API_URL;

  const selectedMasterCategories = useSelector((state) => state.metaFilterDropdown.selectedProductMasterCategories)
  const selectedProductNames = useSelector((state) => state.metaFilterDropdown.selectedProductNames)


  const sellThroughDeepdiveDropdonwOptions = [
    { value: "understock", label: "Understock" },
    { value: "overstock", label: "Overstock" },
    { value: "optimal_stock", label: "Optimal Stock" },
  ]


  const [rawSellThroughDeepDiveData, setRawSellThroughDeepDiveData] = useState([])
  const [sellThroughDeepDiveData, setSellThroughDeepDiveData] = useState([])



  const [selectedSellThroughDeepdiveDropdownValue, setSelectedSellThroughDeepdiveDropdownValue] = useState(sellThroughDeepdiveDropdonwOptions[0]);

  // const onSellThroughDeepdiveDropdownClickHandler = (e) => {
  //   if (e === null) {
  //     setSelectedSellThroughDeepdiveDropdownValue("");
  //   } else {
  //     setSelectedSellThroughDeepdiveDropdownValue(e);
  //   }
  // }

  const [treemapParams, setTreemapParams] = useState({
    level: 1
  })

  const [treemapBreadcrumbs, setTreemapBreadcrumbs] = useState([])


  const categoryDeepDiveLevelChange = (data) => {

    if (treemapParams.level === 1) {
      setTreemapParams({
        level: 2,
        level_1: data.id
      })
      setTreemapBreadcrumbs([{ ...data, level: 2, level_name: 'level_1' }])
    } else if (treemapParams.level === 2) {
      setTreemapParams(prev => ({
        ...prev,
        level: 3,
        level_2: data.id
      }))
      setTreemapBreadcrumbs(prev => [...prev, { ...data, level: 3, level_name: 'level_2' }])

    } else if (treemapParams.level === 3) {
      setTreemapParams(prev => ({
        ...prev,
        level: 4,
        level_3: data.id
      }))
      setTreemapBreadcrumbs(prev => [...prev, { ...data, level: 4, level_name: 'level_3' }])
    } else {
      alert('No further drilldown available!')
    }
  }



  useEffect(() => {

    let sellThroughDeepdiveParams = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
      ...treemapParams
      // master_category_id: null,
      // product_id: null,
      // dropdown: selectedSellThroughDeepdiveDropdownValue.value
    }
    sellThroughDeepdiveDataAPI.fetchData(BASE_URL, "llama/sell-through-deep-dive/", sellThroughDeepdiveParams)

  }, [
    selectedDates,
    selectedSellThroughDeepdiveDropdownValue,
    treemapParams,
    selectedMasterCategories, selectedProductNames
  ])
  useEffect(() => {

    let sellThroughDeepdiveParams = {
      start_date: selectedDates.prevStartDate,
      end_date: selectedDates.prevEndDate,
      ...treemapParams
      // master_category_id: null,
      // product_id: null,
      // dropdown: selectedSellThroughDeepdiveDropdownValue.value
    }
    sellThroughDeepdiveDataPreviousAPI.fetchData(BASE_URL, "llama/sell-through-deep-dive/", sellThroughDeepdiveParams)

  }, [
    selectedDates,
    selectedSellThroughDeepdiveDropdownValue,
    treemapParams
  ])

  useEffect(() => {

    if (sellThroughDeepdiveDataAPI.apiStatus.isLoaded && !sellThroughDeepdiveDataAPI.apiStatus.isLoading) {

      // if (!NoDataAvailableChecker(sellThroughDeepdiveDataAPI?.data?.data ?? [])) {
      setRawSellThroughDeepDiveData(sellThroughDeepdiveDataAPI?.data?.data ?? [])
      // }
    }
  }, [sellThroughDeepdiveDataAPI.data, sellThroughDeepdiveDataAPI.apiStatus]);

  useEffect(() => {


    const formattedData = []



    // if (!sellThroughDeepdiveDataPreviousAPI.data?.data) {

    rawSellThroughDeepDiveData.forEach((item) => {
      const keyArr = ['master_category', 'category', 'sub_category', 'products']
      const idArr = ['category1', 'category2', 'category3', 'itemid'];
      let nameStr = '';
      let idStr = '';

      keyArr.forEach((str) => {
        if (item.hasOwnProperty(str)) {
          nameStr = str
        }
      })

      idArr.forEach((str) => {
        if (item.hasOwnProperty(str)) {
          idStr = str
        }
      })

      formattedData.push({
        id: item[idStr],
        name: item[nameStr],
        value: item.sellthrough,
        // growth_percentage: 100,
      })


    })


    // } else {
    //   rawSellThroughDeepDiveData.forEach((item) => {
    //     const keyArr = ['master_category', 'category', 'sub_category', 'products']
    //     const idArr = ['category1', 'category2', 'category3', 'itemid'];
    //     let nameStr = '';
    //     let idStr = '';

    //     keyArr.forEach((str) => {
    //       if (item.hasOwnProperty(str)) {
    //         nameStr = str
    //       }
    //     })

    //     idArr.forEach((str) => {
    //       if (item.hasOwnProperty(str)) {
    //         idStr = str
    //       }
    //     })


    //     sellThroughDeepdiveDataPreviousAPI.data?.data?.forEach((prev) => {

    //       let prevIdStr = ''
    //       idArr.forEach((str) => {
    //         if (prev.hasOwnProperty(str)) {
    //           prevIdStr = str
    //         }
    //       })

    //       let calcGrowthPerc = ((item.sellthrough - prev.sellthrough) / prev.sellthrough) * 100

    //       if (item[idStr] === prev[prevIdStr]) {

    //         formattedData.push({
    //           id: item[idStr],
    //           name: item[nameStr],
    //           value: item.sellthrough,
    //           growth_percentage: isNaN(calcGrowthPerc) || !isFinite(calcGrowthPerc) ? 0 : calcGrowthPerc,
    //         })
    //       }

    //     })
    //   })
    // }




    sellExportData({
      apiStatus: sellThroughDeepdiveDataAPI.apiStatus,
      data: formattedData
    })
    setSellThroughDeepDiveData(formattedData)

  }, [rawSellThroughDeepDiveData, sellThroughDeepdiveDataPreviousAPI.data])


  let categoryDeepDiveSection = <Wrapper><Loader loaderType={'treeChartLoader'} /></Wrapper>


  if (sellThroughDeepdiveDataAPI.apiStatus.isLoading) {
    categoryDeepDiveSection = <Wrapper><Loader loaderType={'treeChartLoader'} /></Wrapper>
  }

  if (!sellThroughDeepdiveDataAPI.apiStatus.isLoading && sellThroughDeepdiveDataAPI.apiStatus.isLoaded) {

    if (!NoDataAvailableChecker(rawSellThroughDeepDiveData)) {

      categoryDeepDiveSection = <SimpleTreeMap
        chartId="simpleTreemaptestasdasdasd"
        chartClass="section_card_chart"
        chartData={sellThroughDeepDiveData}
        levelHandler={categoryDeepDiveLevelChange}
        // colorChangeParameter={'growth_percentage'}
        isPercentValue={true}
        chartLabels={[
          {
            name: "name",
            value: "value",
            toolTipvalue1: "value",
            toolTipvalue1Label: "Sell Through %",

          },
        ]}
      />
    } else if (NoDataAvailableChecker(rawSellThroughDeepDiveData) && NoDataAvailableChecker(sellThroughDeepdiveDataAPI.data.data)) {
      categoryDeepDiveSection = <Wrapper><NoDataAvailableLoader chartType={'treeChartType'} /></Wrapper>
    }

  }



  return (
    <Wrapper>
      <ErrorBoundary>
        <div id="sellthough_deepdive_section" className="grid grid_cols_4 grid_margin_bottom section_card">
          <div className="section_card_header">
            <div className="flex column align_start">
              <div className="section_info">
                <h2 className="section_card_title">
                  Sell Through Deepdive
                  <SectionDefinationsBadge
                    title={''}
                    module={''}
                    platform={''}
                    section={''}
                  />
                </h2>
              </div>
              <div className="treemap_breadcrumb">
                <span
                  onClick={() => {
                    setTreemapParams({ level: 1 });
                    setTreemapBreadcrumbs([])
                  }}
                >
                  Home
                </span>

                {treemapBreadcrumbs && treemapBreadcrumbs.map((item, index) => {
                  return <span
                    onClick={() => {
                      if (treemapBreadcrumbs.length - 1 !== index) {

                        const updateArr = [...treemapBreadcrumbs].slice(0, (item.level - 1))
                        const newObj = {};

                        updateArr.forEach((item) => {
                          newObj[item.level_name] = item.id
                        })

                        setTreemapParams({
                          level: item.level,
                          ...newObj
                        })
                        setTreemapBreadcrumbs(updateArr)

                      }
                    }}

                  > / {item.name}</span>
                })}
              </div>
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"sellthough_deepdive_section"}
                sectionName={`removeId_Sell Through Deepdive`}
                sectionData={sellThroughDeepDiveData}
              />
            </div>
          </div >
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {categoryDeepDiveSection}
              </div>
            </div>

          </div>
        </div >
      </ErrorBoundary>
    </Wrapper >
  )
}
