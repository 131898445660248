import { useSelector } from "react-redux";
import Wrapper from "../../components/helpers/Wrapper";

const SelectedFilter = () => {
    const { selectedProductMasterCategories, selectedProductNames } = useSelector(
        (state) => state.metaFilterDropdown
    );

    const getContent = () => {
        if (selectedProductMasterCategories.length) {
            return `${selectedProductMasterCategories.length} master ${selectedProductMasterCategories.length > 1 ? "categories" : "category"} selected`;
        }
        if (selectedProductNames.length) {
            return `${selectedProductNames.length} ${selectedProductNames.length > 1 ? "products" : "product"} selected`;
        }
        return null;
    };

    const content = getContent();

    return (
        <Wrapper>
            {content && (
                <div className="header_badges_wrapper" style={{ padding: "0" }}>
                    <div className="badges_list_wrapper">
                        <div className="badge_wrapper">
                            <div className="button_badge">{content}</div>
                        </div>
                    </div>
                </div>
            )}
        </Wrapper>
    );
};

export default SelectedFilter;
