import { useContext, useEffect, useState } from "react";
import Wrapper from "../../components/helpers/Wrapper"
import PageHeader from "../../components/layout/pageHeader"
import useApi from "../../hooks/useApi";
import { useSelector } from "react-redux";
import Loader from "../../components/ui/loaders/Loader";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../utils/ErrorBoundary";
import { SwiperSlide } from "swiper/react";
import Card from "../../components/ui/Card";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";
import Slider from "../../components/ui/Slider";
import SimpleTreeMap from "../../components/ui/charts/SimpleTreeMap";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../components/ui/dropdown/ExportDropdown";
import Datatable from "../../components/ui/tables/Datatable";
import LineChart from "../../components/ui/charts/LineChart";
import PartitionedLineChart from "../../components/ui/charts/PartitionedLineChart";
import Modal from "../../components/ui/Modal";
import ExpandIcon from "../../assets/icons/ExpandIcon";
import { formatLabels } from "../../utils/Utils";
import { CategoryDeepdives } from "./CategoryDeepdive";
import { SellThroughDeepdive } from "./SellThroughDeepDive.js";
import MetaDropdown from "../../components/ui/dropdown/MetaDropdown.js";
import MultiAxisLineChart from "../../components/ui/charts/multiAxisLineChart.js";
import { GaugeMetric } from "./GaugeMetric.js";
import SelectedFilter from "./SelectedFilter.js"
import FullPageExportContext from "../../store/fullPageExportContext.js";
import { insightCard } from "../../data/cardDataSamples.js";
import SectionDefinationsBadge from "../../components/ui/SectionDefinationsBadge.js";

const Dashboard = () => {
  const BASE_URL = process.env.REACT_APP_META_LAMA_API_URL;
  const { setFullPageExport, setIsExportDisabled, setExportPageName, insightCardExportData } = useContext(FullPageExportContext);


  // startDate / endDate / prevStartDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const selectedMasterCategories = useSelector((state) => state.metaFilterDropdown.selectedProductMasterCategories)
  const selectedProductNames = useSelector((state) => state.metaFilterDropdown.selectedProductNames)

  // API CALLS
  const metricCardsCurrentDataAPI = useApi(); // METRIC CARD CURRENT PERIOD DATA API
  const metricCardsPreviousDataAPI = useApi(); // METRIC CARD PREVIOUS PERIOD DATA API
  // const categoryWiseDeepdiveDataAPI = useApi(); // CATEGORY WISE DEEP DIVE DATA API

  const actionTableDataAPI = useApi(); // ACTION TABLE DATA API
  const trendForecastAnalysisDataAPI = useApi();
  const sellThroughAnalysisDataAPI = useApi();

  const sellThroughDeepDiveSummaryTableDataAPI = useApi();

  const csatTrendAnalysisDataAPI = useApi();

  useEffect(() => {

    // METRIC CARD CURRENT DATA PARAMS
    let currentParams = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
    }

    // METRIC CARD PREVIOUS DATA PARAMS
    let previousParams = {
      start_date: selectedDates.prevStartDate,
      end_date: selectedDates.prevEndDate,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
    }

    metricCardsCurrentDataAPI.fetchData(BASE_URL, "llama/metric-card/", currentParams); // TO GET THE CURRENT PERIOD CARDS DATA
    metricCardsPreviousDataAPI.fetchData(BASE_URL, "llama/metric-card/", previousParams); // TO GET THE PREVIOUS PERIOD CARDS DATA
  }, [selectedDates, selectedMasterCategories, selectedProductNames])



  const [formattedeMetricCardData, setFormattedeMetricCardData] = useState([]);


  useEffect(() => {

    if (!NoDataAvailableChecker(metricCardsCurrentDataAPI?.data?.data ?? []) && !NoDataAvailableChecker(metricCardsPreviousDataAPI?.data?.data ?? [])) {
      const currentPeriodData = metricCardsCurrentDataAPI.data.data[0];
      const previousPeriodData = metricCardsPreviousDataAPI.data.data[0];
      const cardsData = [];

      cardsData.push({
        "label": "Total Sales",
        "currentValue": currentPeriodData?.total_sales || 0,
        "previousValue": previousPeriodData?.total_sales || 0
      })

      cardsData.push({
        "label": "Total Stock",
        "currentValue": currentPeriodData?.total_stocks || 0,
        "previousValue": previousPeriodData?.total_stocks || 0
      })

      cardsData.push({
        "label": "Total Orders",
        "currentValue": currentPeriodData?.total_orders || 0,
        "previousValue": previousPeriodData?.total_orders || 0
      })

      cardsData.push({
        "label": "Avg Daily Demand",
        "currentValue": currentPeriodData?.avg_daily_demand || 0,
        "previousValue": previousPeriodData?.avg_daily_demand || 0
      })

      cardsData.push({
        "label": "Avg Order Size",
        "currentValue": currentPeriodData?.avg_order_size || 0,
        "previousValue": previousPeriodData?.avg_order_size || 0
      })

      cardsData.push({
        "label": "Avg Reorder Point",
        "currentValue": currentPeriodData?.avg_reorder_point || 0,
        "previousValue": previousPeriodData?.avg_reorder_point || 0
      })

      cardsData.push({
        "label": "No of days coverage",
        "currentValue": currentPeriodData?.no_of_days_coverage || 0,
        "previousValue": previousPeriodData?.no_of_days_coverage || 0
      })

      cardsData.push({
        "label": "CSAT",
        "currentValue": currentPeriodData?.csat || 0,
        "previousValue": previousPeriodData?.csat || 0
      })


      setFormattedeMetricCardData(cardsData)
    }

  }, [metricCardsCurrentDataAPI.data, metricCardsPreviousDataAPI.data])



  let metricCardsSection = <Wrapper>
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  </Wrapper>



  if (metricCardsPreviousDataAPI.apiStatus.error !== null) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardsPreviousDataAPI.apiStatus.error}
          />
        </div>
      </Wrapper>
    );
  } else {
    if (
      !metricCardsCurrentDataAPI.apiStatus.isLoading && metricCardsCurrentDataAPI.apiStatus.isLoaded &&
      !metricCardsPreviousDataAPI.apiStatus.isLoading && metricCardsPreviousDataAPI.apiStatus.isLoaded
    ) {
      if (!NoDataAvailableChecker(metricCardsCurrentDataAPI.data.data) && !NoDataAvailableChecker(metricCardsPreviousDataAPI.data.data)) {
        metricCardsSection = (
          <Wrapper>
            <ErrorBoundary chartType="cardChartType">
              <Slider
                showSliderHeader={false}
                showNavigation={false}>
                {formattedeMetricCardData.map((data, index) => (
                  <SwiperSlide key={index}>
                    <Card
                      module=""
                      platform=""
                      section=""
                      cardTitle={data.label}
                      currentValue={data.currentValue}
                      previousValue={data.previousValue}
                      rowOneTitle={"Previous"}
                      numberVariant={"number"}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        metricCardsSection = (
          <Wrapper>
            <div className="grid grid_cols_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType={"cardChartType"} />
            </div>
          </Wrapper>
        );
      }
    }
  }

  useEffect(() => {

    // METRIC CARD CURRENT DATA PARAMS
    let params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
      // master_category_id: 
      // product_id:
    }

    actionTableDataAPI.fetchData(BASE_URL, "llama/action-table/", params)
  }, [selectedDates, selectedMasterCategories, selectedProductNames])

  let actionTableHeaders = [
    { name: "Product Name", field: "products" },
    { name: "Master Category", field: "master_category" },
    { name: "Category", field: "category" },
    { name: "Sub Category", field: "sub_category" },
    { name: "Product Bucket", field: "product_bucket" },
    { name: "Alert Raised", field: "alert_raised" },
    { name: "Recommendation", field: "recommendation" },
    { name: "Time Range", field: "time_range" },
    { name: "Demand", field: "demand" },
    // { name: "Stock", field: "total_stocks" },
    // { name: "Sell Through %", field: "sell_through_rate" }
  ]

  let actionTableSection =
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Action Table
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>

  if (actionTableDataAPI.apiStatus.error) {
    actionTableSection = <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Action Table
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <ServerErrorsLoader chartType="tableChartType" error={actionTableDataAPI.apiStatus.error} />
          </div>
        </div>
      </div>
    </Wrapper>
  } else {
    if (!actionTableDataAPI.apiStatus.isLoading && actionTableDataAPI.apiStatus.isLoaded) {
      actionTableSection = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="Action Table"
              tablePageSubModule=""
              tablePagePlatform=""
              tablePagePrimaryTab=""
              tableHeader={actionTableHeaders}
              tableData={actionTableDataAPI?.data?.data ?? []}
              tableLength={10}
              searchPlaceHolder={"Search"}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={'action_table_id'}
                  sectionName={'Action Table'}
                  sectionData={actionTableDataAPI?.data?.data ?? []}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  const [trendAnalysisData, setTrendAnalysisData] = useState([])
  const [trendAnalysisLabels, setTrendAnalysisLabels] = useState([]);
  const [isTrendForecastAnalysisOpen, setIsTrendForecastAnalysisOpen] = useState(false)

  const trendForecastAnalysisDropdownOptions = [
    { value: "total_sales", label: "Total Sales" },
    { value: "total_stock", label: "Total Stock" }
  ]

  const [trendForecastAnalysisDropdownSelectedValue, setTrendForecastAnalysisDropdownSelectedValue] = useState(trendForecastAnalysisDropdownOptions[0]);


  // DROPDOWN OPTIONS SELECTION HANDLER
  const onTrendForcasteAnalysisDropdonClickHandler = (e) => {
    if (e === null) {
      setTrendForecastAnalysisDropdownSelectedValue("");
    } else {
      setTrendForecastAnalysisDropdownSelectedValue(e);
    }

  };

  let defaultTrendForecastAnalysisDisabledvalues = [];

  const [pageReachDisabledvalues, setTrendForecastAnalysisDisabledvalues] = useState(defaultTrendForecastAnalysisDisabledvalues)

  // TO FETCH PAGE Reach DATA FROM API

  useEffect(() => {

    let params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      // master_category_id:
      // product_id:
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
      dropdown: trendForecastAnalysisDropdownSelectedValue.value
    }

    trendForecastAnalysisDataAPI.fetchData(BASE_URL, "llama/trend-forecast/", params)
  }, [
    selectedDates,
    trendForecastAnalysisDropdownSelectedValue,
    selectedMasterCategories, selectedProductNames
  ])

  useEffect(() => {
    if (trendForecastAnalysisDataAPI.apiStatus.isLoaded && !trendForecastAnalysisDataAPI.apiStatus.isLoading) {

      let rawData = trendForecastAnalysisDataAPI?.data?.data || []
      let data = [];
      let label = [];

      if (rawData.length > 0) {
        // Convert rawData to data array
        data = rawData.map(item => {
          let transformedItem = { label: item.date }; // Set the date as label
          Object.keys(item).forEach(key => {
            if (key !== "date") { // Exclude the date field
              transformedItem[key] = item[key]; // Dynamically add other fields
            }
          });
          return transformedItem;
        });

        // Create label array by extracting keys from one of the objects
        label = Object.keys(rawData && rawData[0])
          .filter(key => key !== "date")
          .map(key => ({
            label: formatLabels(key),
            value: key
          }));

        setTrendAnalysisData(data);
        setTrendAnalysisLabels(label);
      } else {
        setTrendAnalysisData(data);
        setTrendAnalysisLabels(label);
      }
    }
  }, [trendForecastAnalysisDataAPI.apiStatus]);

  let trendForecastAnalysisSection = <Wrapper> <Loader loaderType="multiLineChartLoader" /></Wrapper>
  let trendForecastAnalysisDeclusterSection = <Wrapper> <Loader loaderType="multiLineChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (trendForecastAnalysisDataAPI.apiStatus.isLoaded && !trendForecastAnalysisDataAPI.apiStatus.isLoading) {
    if (
      NoDataAvailableChecker(trendAnalysisData) ||
      NoDataAvailableChecker(trendAnalysisLabels)
    ) {
      trendForecastAnalysisSection = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      trendForecastAnalysisDeclusterSection = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>

    } else {

      trendForecastAnalysisSection = (
        <ErrorBoundary chartType="lineChartType">
          <MultiAxisLineChart
            chartId="trend_forecaste_analysis"
            chartClass="section_card_chart"
            chartData={trendAnalysisData}
            chartLabels={trendAnalysisLabels}
            showLegend={true}
            showLabels={true}
            disabledLegendArray={pageReachDisabledvalues}
            setDisabledValues={setTrendForecastAnalysisDisabledvalues}
          />
        </ErrorBoundary>
      );

      trendForecastAnalysisDeclusterSection = (
        <ErrorBoundary chartType="lineChartType">
          <PartitionedLineChart
            chartId="trend_forecaste_analysis_decluster"
            chartClass="section_card_chart"
            chartData={trendAnalysisData}
            chartLabels={trendAnalysisLabels}
            showLegend={true}
            showLabels={true}
            disabledLegendArray={pageReachDisabledvalues}
            setDisabledValues={setTrendForecastAnalysisDisabledvalues}
          />
        </ErrorBoundary>
      );

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trendForecastAnalysisDataAPI.apiStatus.error) {
    trendForecastAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={trendForecastAnalysisDataAPI.apiStatus.error} />
      </Wrapper>
    );

    trendForecastAnalysisDeclusterSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={trendForecastAnalysisDataAPI.apiStatus.error} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (trendForecastAnalysisDataAPI.apiStatus.isLoading) {
    trendForecastAnalysisSection = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>
    trendForecastAnalysisDeclusterSection = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>
  }

  const [sellThroughAnalysisData, setSellThroughAnalysisData] = useState([])
  const [sellThroughAnalysisLabels, setSellThroughAnalysisLabels] = useState([]);
  const [isSellThroughAnalysisIsOpen, setIsSellThroughAnalysisOpen] = useState(false);

  const [sellThroughAnalysisDisabledvalues, setSellThroughAnalysisDisabledvalues] = useState([])

  useEffect(() => {
    let params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
    }

    sellThroughAnalysisDataAPI.fetchData(BASE_URL, "llama/sell-through-analysis/", params)
  }, [selectedDates, selectedMasterCategories, selectedProductNames])

  useEffect(() => {
    if (sellThroughAnalysisDataAPI.apiStatus.isLoaded && !sellThroughAnalysisDataAPI.apiStatus.isLoading) {

      let rawData = sellThroughAnalysisDataAPI?.data?.data || []
      let data = [];
      let label = [];

      if (rawData.length > 0) {

        const formattedData = rawData.reduce((acc, { date, master_category, sellthrough }) => {
          const existingEntry = acc.find(item => item.label === date);
          if (existingEntry) {
            existingEntry[master_category] = sellthrough;
          } else {
            acc.push({
              label: date,
              [master_category]: sellthrough
            });
          }
          return acc;
        }, []);

        // Convert rawData to data array
        data = formattedData.map(item => {
          let transformedItem = { label: item.label }; // Set the date as label
          Object.keys(item).forEach(key => {
            if (key !== "label") { // Exclude the date field
              transformedItem[key] = item[key]; // Dynamically add other fields
            }
          });
          return transformedItem;
        });

        // Create label array by extracting keys from one of the objects
        label = Object.keys(formattedData && formattedData[0])
          .filter(key => key !== "label")
          .map(key => ({
            label: formatLabels(key),
            value: key
          }));

        const sortedlabelData = label.sort((a, b) => {
          const numA = a.value.match(/\d+/)[0]; // Extract the number from the string
          const numB = b.value.match(/\d+/)[0];
          return numA - numB; // Sort numerically
        });

        setSellThroughAnalysisData(data);
        setSellThroughAnalysisLabels(sortedlabelData);
      } else {
        setSellThroughAnalysisData(data);
        setSellThroughAnalysisLabels(label);
      }


    }
  }, [sellThroughAnalysisDataAPI.apiStatus]);


  let sellThroughAnalysisSection = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>
  let sellThroughAnalysisDeclusterSection = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>

  // IF DATA IS AVAILABLE
  if (sellThroughAnalysisDataAPI.apiStatus.isLoaded && !sellThroughAnalysisDataAPI.apiStatus.isLoading) {
    if (
      NoDataAvailableChecker(sellThroughAnalysisData) ||
      NoDataAvailableChecker(sellThroughAnalysisLabels)
    ) {
      sellThroughAnalysisSection = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /> </Wrapper>
      sellThroughAnalysisDeclusterSection = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /> </Wrapper>

    } else {


      sellThroughAnalysisSection = (
        <ErrorBoundary chartType="lineChartType" >
          <LineChart
            chartId="sell_through_analysis"
            chartClass="section_card_chart"
            chartData={sellThroughAnalysisData}
            chartLabels={sellThroughAnalysisLabels}
            showLegend={true}
            showLabels={true}
            disabledLegendArray={sellThroughAnalysisDisabledvalues}
            setDisabledValues={setSellThroughAnalysisDisabledvalues}
          />
        </ErrorBoundary>
      );

      sellThroughAnalysisDeclusterSection = (
        <ErrorBoundary chartType="lineChartType" >
          <PartitionedLineChart
            chartId="sell_through_analysis_decluster"
            chartClass="section_card_chart"
            chartData={sellThroughAnalysisData}
            chartLabels={sellThroughAnalysisLabels}
            showLegend={true}
            showLabels={true}
            disabledLegendArray={sellThroughAnalysisDisabledvalues}
            setDisabledValues={setSellThroughAnalysisDisabledvalues}
          />
        </ErrorBoundary>
      );

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (sellThroughAnalysisDataAPI.apiStatus.error) {
    sellThroughAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={sellThroughAnalysisDataAPI.apiStatus.error} />
      </Wrapper>
    );

    sellThroughAnalysisDeclusterSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={sellThroughAnalysisDataAPI.apiStatus.error} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (sellThroughAnalysisDataAPI.apiStatus.isLoading) {
    sellThroughAnalysisSection = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>
    sellThroughAnalysisDeclusterSection = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>
  }

  useEffect(() => {

    let params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
      // master_category_id:
      // product_id:
    }

    sellThroughDeepDiveSummaryTableDataAPI.fetchData(BASE_URL, "llama/summary-table/", params)
  }, [
    selectedDates,
    selectedMasterCategories, selectedProductNames
  ])

  let sellThroughDeepDiveTableHeaders = [
    { name: "Product Name", field: "products" },
    { name: "Master Category", field: "master_category" },
    { name: "Category", field: "category" },
    { name: "Sub Category", field: "sub_category" },
    { name: "Total Stock", field: "stock_on_hand" },
    { name: "Avg Daily Demand", field: "avg_daily_demand" },
    { name: "Variation in RRP %", field: "variation_in_rrp" },
    { name: "Avg sales price", field: "average_sales_price" },
    { name: "Avg Order Size", field: "average_order_size" },
    { name: "Sell Through %", field: "sellthrough" },
    { name: "Avg Reorder point", field: "avg_reorder_point" }
  ]

  let sellThroughDeepDiveTableSection =
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary Table on Sell Through Deep Dive
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>

  if (sellThroughDeepDiveSummaryTableDataAPI.apiStatus.error) {
    sellThroughDeepDiveTableSection = <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary Table on Sell Through Deep Dive
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <ServerErrorsLoader chartType="tableChartType" error={sellThroughDeepDiveSummaryTableDataAPI.apiStatus.error} />
          </div>
        </div>
      </div>
    </Wrapper>
  } else {
    if (!sellThroughDeepDiveSummaryTableDataAPI.apiStatus.isLoading &&
      sellThroughDeepDiveSummaryTableDataAPI.apiStatus.isLoaded) {
      sellThroughDeepDiveTableSection = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="Summary Table on Sell Through Deep Dive"
              tablePageSubModule=""
              tablePagePlatform=""
              tablePagePrimaryTab=""
              tableHeader={sellThroughDeepDiveTableHeaders}
              tableData={sellThroughDeepDiveSummaryTableDataAPI?.data?.data ?? []}
              tableLength={10}
              searchPlaceHolder={"Search"}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={'sellthrough_table'}
                  sectionName={'sellthroughTable_Sell Through Table'}
                  sectionData={sellThroughDeepDiveSummaryTableDataAPI?.data?.data ?? []}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  useEffect(() => {

    let params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      ...(selectedMasterCategories.length !== 0 && { master_category_id: selectedMasterCategories.join(',') }),
      ...(selectedProductNames.length !== 0 && { product_id: selectedProductNames.join(',') }),
      // master_category_id:
      // product_id:
    }

    csatTrendAnalysisDataAPI.fetchData(BASE_URL, "llama/daily-trend-csat/", params)
  }, [
    selectedDates,
    selectedMasterCategories, selectedProductNames
  ])

  const [csatTrendAnalysisData, setCSATTrendAnalysisData] = useState([])
  const [csatTrendAnalysisLabels, setCSATTrendAnalysisLabels] = useState([]);

  useEffect(() => {
    if (csatTrendAnalysisDataAPI.apiStatus.isLoaded && !csatTrendAnalysisDataAPI.apiStatus.isLoading) {

      let rawData = csatTrendAnalysisDataAPI?.data?.data || []
      let data = [];
      let label = [];

      if (rawData.length > 0) {
        data = rawData.map(item => {
          let transformedItem = { label: item.data_date };
          Object.keys(item).forEach(key => {
            if (key !== "data_date") {
              transformedItem[key] = item[key];
            }
          });
          return transformedItem;
        });

        // Create label array by extracting keys from one of the objects
        label = Object.keys(rawData[0])
          .filter(key => key !== "data_date")
          .map(key => ({
            label: formatLabels(key),
            value: key
          }));

        setCSATTrendAnalysisData(data);
        setCSATTrendAnalysisLabels(label);
      } else {
        setCSATTrendAnalysisData(data);
        setCSATTrendAnalysisLabels(label);
      }
    }
  }, [csatTrendAnalysisDataAPI.apiStatus]);

  let csatTrendAnalysisSection = <Wrapper><Loader loaderType="singleLineChartLoader" /> </Wrapper>

  if (csatTrendAnalysisDataAPI.apiStatus.error !== null) {
    csatTrendAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={csatTrendAnalysisDataAPI.apiStatus.error} />
      </Wrapper>
    );
  } else {
    if (!csatTrendAnalysisDataAPI.apiStatus.isLoading && csatTrendAnalysisDataAPI.apiStatus.isLoaded) {
      if (NoDataAvailableChecker(csatTrendAnalysisData) && NoDataAvailableChecker(csatTrendAnalysisLabels)) {
        csatTrendAnalysisSection = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /> </Wrapper>
      } else {
        csatTrendAnalysisSection = (
          <ErrorBoundary chartType="lineChartType" >
            <LineChart
              chartId="CSAT_trend_analysis"
              chartClass="section_card_chart"
              chartData={csatTrendAnalysisData}
              chartLabels={csatTrendAnalysisLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        );
      }
    }
  }


  const [categoryDeepDiveData, setCategoryDeepDiveData] = useState({
    data: [],
    apiStatus: {
      isLoading: false,
      isLoaded: false
    },
    error: null
  })
  const [gaugeChartData, setGaugeChartData] = useState({
    data: [],
    apiStatus: {
      isLoading: false,
      isLoaded: false
    },
    error: null
  })

  const [barChartData, setBarChartData] = useState({
    data: [],
    apiStatus: {
      isLoading: false,
      isLoaded: false
    },
    error: null
  })

  const [sellThroughData, setSellThroughData] = useState({
    data: [],
    apiStatus: {
      isLoading: false,
      isLoaded: false
    },
    error: null
  })




  useEffect(() => {

    const allData = {
      ...insightCardExportData,
      'Overall Cards': formattedeMetricCardData,
      'removeId_Category Deep Dive': categoryDeepDiveData?.data ?? [],
      'overUnderGauge_Over Under Gauge': gaugeChartData?.data?.data ?? [],
      'Over Under Bar': barChartData?.data?.data ?? [],
      'Action Table': actionTableDataAPI.data?.data ?? [],
      'Trend Forecast Analysis': trendAnalysisData,
      'Sell Through Analysis': sellThroughAnalysisData,
      'removeId_Sell Through Deepdive': sellThroughData?.data ?? [],
      'sellthroughTable_Sell Through Table': sellThroughDeepDiveSummaryTableDataAPI?.data?.data ?? [],
      'CSAT Trend Analysis': csatTrendAnalysisData
    }

    setExportPageName('Meta Llama')
    setFullPageExport(allData)

  }, [
    selectedDates,
    insightCardExportData,
    formattedeMetricCardData,
    actionTableDataAPI.data,
    trendAnalysisData,
    sellThroughAnalysisData,
    sellThroughDeepDiveSummaryTableDataAPI.data,
    csatTrendAnalysisData,
    categoryDeepDiveData.data,
    gaugeChartData.data,
    barChartData.data,
    sellThroughData.data
  ])



  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <div className="grid grid_cols_2">
            <div className="grid cols_span_1" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <PageHeader pageTitle={"Overall Summary"} />
              <SelectedFilter />
            </div>
            <div className="grid cols_span_1 meta_dropdown_wrapper">
              <MetaDropdown />
            </div>
          </div>
          <div className="page_content">
            <div className="dashboard_home">
              {metricCardsSection}
              <GaugeMetric setGaugeExport={setGaugeChartData} setBarExport={setBarChartData} />

              <CategoryDeepdives setExportData={setCategoryDeepDiveData} />

              <div className="grid grid_cols_1 grid_margin_bottom" id="action_table_id">
                {actionTableSection}
              </div>

              <div className="grid grid_cols_4 grid_margin_bottom">
                <div id="page_Reach_section" className="grid col_span_4 section_card">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        Trend Forecast Analysis
                        <SectionDefinationsBadge
                          title={''}
                          module={''}
                          platform={''}
                          section={''}
                        />
                      </h2>
                    </div>
                    <Modal
                      open={isTrendForecastAnalysisOpen}
                      setOpen={setIsTrendForecastAnalysisOpen}
                      exportDropdown={
                        <ExportDropdown
                          sectionId={"social_facebook_page_reach_2"}
                          sectionName={`Trend Forecast Analysis`}
                          sectionData={trendAnalysisData}
                        />}
                      dropdown={
                        <Dropdown
                          ismulti={false}
                          placeholder={"Select"}
                          options={trendForecastAnalysisDropdownOptions}
                          className="form_dropdown section_dropdown"
                          value={trendForecastAnalysisDropdownSelectedValue}
                          selectedOptions={trendForecastAnalysisDropdownSelectedValue}
                          setStatedropdown={onTrendForcasteAnalysisDropdonClickHandler}
                        />
                      }
                      parentId={"page_Reach_section"}
                      title={`Trend Forecast Analysis`}
                      chart={trendForecastAnalysisDeclusterSection}
                    />
                    <div data-html2canvas-ignore={true} style={{ gap: "20px" }}>
                      <button onClick={() => setIsTrendForecastAnalysisOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                        <ExpandIcon />
                      </button>
                      <Dropdown
                        ismulti={false}
                        placeholder={"Select"}
                        options={trendForecastAnalysisDropdownOptions}
                        className="form_dropdown section_dropdown"
                        value={trendForecastAnalysisDropdownSelectedValue}
                        selectedOptions={trendForecastAnalysisDropdownSelectedValue}
                        setStatedropdown={onTrendForcasteAnalysisDropdonClickHandler}
                      />
                      <ExportDropdown
                        sectionId={"page_Reach_section"}
                        sectionName={`_Trend Forecast Analysis _${trendForecastAnalysisDropdownSelectedValue.value}`}
                        sectionData={trendAnalysisData}
                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {trendForecastAnalysisSection}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid_cols_4 grid_margin_bottom">
                <div id="sell_through_analysis_section" className="grid col_span_4 section_card">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">Sell Through Analysis
                        <SectionDefinationsBadge
                          title={''}
                          module={''}
                          platform={''}
                          section={''}
                        />
                      </h2>
                    </div>
                    <Modal
                      open={isSellThroughAnalysisIsOpen}
                      setOpen={setIsSellThroughAnalysisOpen}
                      exportDropdown={
                        <ExportDropdown
                          sectionId={"sell_through_analysis_section"}
                          sectionName={`Sell Through Analysis`}
                          sectionData={sellThroughAnalysisData}
                        />}
                      parentId={"sell_through_analysis_section"}
                      title={`Sell Through Analysis`}
                      chart={sellThroughAnalysisDeclusterSection}
                    />
                    <div data-html2canvas-ignore={true} style={{ gap: "20px" }}>
                      <button onClick={() => setIsSellThroughAnalysisOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                        <ExpandIcon />
                      </button>
                      <ExportDropdown
                        sectionId={"sell_through_analysis_section"}
                        sectionName={`Sell Through Analysis`}
                        sectionData={sellThroughAnalysisData}
                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {sellThroughAnalysisSection}
                    </div>
                  </div>
                </div>
              </div>

              <SellThroughDeepdive sellExportData={setSellThroughData} />

              <div className="grid grid_cols_1 grid_margin_bottom" id="sellthrough_table">
                {sellThroughDeepDiveTableSection}
              </div>

              <div className="grid grid_cols_4 grid_margin_bottom">
                <div id="csat_trend_analysis" className="grid col_span_4 section_card">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        CSAT Trend Analysis
                        <SectionDefinationsBadge
                          title={''}
                          module={''}
                          platform={''}
                          section={''}
                        />
                      </h2>
                    </div>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        sectionId={"csat_trend_analysis"}
                        sectionName={`CSAT Trend Analysis`}
                        sectionData={sellThroughAnalysisData}
                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {csatTrendAnalysisSection}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Wrapper >
  )
}
export default Dashboard